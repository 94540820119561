import React from "react";
// Customizable Area Start
import { Box, Button, Typography, Grid, styled, Select, MenuItem, Backdrop,TableContainer, Table, TableHead, TableRow, TableCell, TableBody, AppBar, Tabs, Tab, Paper , withStyles, Modal, IconButton,Fade} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { KeyboardArrowDown } from "@material-ui/icons";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import Tooltip from "@material-ui/core/Tooltip";
import {
  PendigTask,
  configJSON
} from "./DashboardController.web";
import PhoneInTalkOutlinedIcon from "@material-ui/icons/PhoneInTalkOutlined";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import * as IMG_CONST from "./assets";
import CloseIcon from "@material-ui/icons/Close";
// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderUpdateModal = () => {
    return (
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={this.state.updateModalOpen}
        onClose={()=>this.handleOpenClose()}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableScrollLock
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={this.state.updateModalOpen}>
          <InnerModalBox>

            <Box
              className="modalTextBox"
            >
              <IconButton
                onClick={() => this.handleOpenClose()}
                className="iconCloseBtn"
                data-test-id="modalTestId"
              >
                <CloseIcon
                  aria-label="close"
                />
              </IconButton>

              <Typography
                className="modalItemTxt"
                dangerouslySetInnerHTML={{ __html: this.state.updateValue }}
              />
            </Box>

          </InnerModalBox>
        </Fade>
      </Modal>
    );
  };

  renderUpperTable = () => {
    return (
      <UpperTable container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <Box className="cards">
            <Box className="cardImageContainer">
              <img src={IMG_CONST.TotalApplicationIcon} width="100%" height="100%" />
            </Box>
            <Box className="cardContentContainer">
              <Typography style={webStyles.cardTitle}>
                {configJSON.totalApplicationsText}
              </Typography>
              <Typography style={webStyles.cardContent}>
                {this.state.statusCountData?.total_applications}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box className="cards">
            <Box className="cardImageContainer">
              <img src={IMG_CONST.PaidApplicationIcon} width="100%" height="100%" />
            </Box>
            <Box className="cardContentContainer">
              <Typography style={webStyles.cardTitle}>
                {configJSON.paidApplicationText}
              </Typography>
              <Typography style={webStyles.cardContent}>
                {this.state.statusCountData?.paid_applications}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box className="cards">
            <Box className="cardImageContainer">
              <img src={IMG_CONST.RejectedIcon} width="100%" height="100%" />
            </Box>
            <Box className="cardContentContainer">
              <Typography style={webStyles.cardTitle}>
                {configJSON.rejectedText}
              </Typography>
              <Typography style={webStyles.cardContent}>
                {this.state.statusCountData?.rejected_applications}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Box className="cards">
            <Box className="cardImageContainer">
              <img src={IMG_CONST.enrolledImg} width="100%" height="100%" />
            </Box>
            <Box className="cardContentContainer">
              <Typography style={webStyles.cardTitle}>
                {configJSON.enrolledText}
              </Typography>
              <Typography style={webStyles.cardContent}>
                {this.state.statusCountData?.enrolled}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </UpperTable>
    );
  };

  renderAnyUpdateBox = () => {
    const { recentUpdate } = this.state;
    const recentValue = recentUpdate && recentUpdate[this.state.recentPaginationNumber];
    return (
      <Box className="updateBox">
        <Box className="recentUpdateBox" >
          <HeadingTxt data-test-id="recentUpdateBoxID">
            {configJSON.recentUpdatesTxt}
          </HeadingTxt>
          <BtnBox >
            <Button
              disabled={this.state.recentPaginationNumber === 0}
              data-test-id="prevUpdateTestId"
              className="nextPrevBtn"
              onClick={this.onPrevUpdate}>
              <NavigateBeforeIcon fontSize="medium" />
            </Button>
            <Button
              disabled={this.onOfferNextDisable()}
              className="nextPrevBtn"
              data-test-id="nextUpdateTestId"
              onClick={this.onNextUpdate}>
              <NavigateNextIcon fontSize="medium" />
            </Button>
          </BtnBox>
        </Box>
        <AnyUpdateBox>
          <Grid container>
            <Grid item sm={12} md={4} lg={3} className="imgGrid" >
              <Box
                width="147.48px" height="145.19px" padding="10px 0px">
                <img src={recentValue?.attributes?.image} width="100%" height="100%" />
              </Box>
            </Grid>
            <Grid item sm={12} md={8} lg={9} xs={12}>
              <Box className="innerUpdateMainBox">
                <Box className="upperTxtBox">
                <Button className="readMoreBtn" onClick={()=>this.handleOpenClose(recentValue)}> {configJSON.readmoreTxt}</Button>
                <Typography className="announcementType" >{recentValue?.attributes?.announcement_type}</Typography>
                <Typography className="hideText">{recentValue?.attributes?.announcement_type}</Typography>
                </Box>
                <Box className="countryList">
                <Typography className="announcementType" >Applicable countries:</Typography>
                  {recentValue?.attributes?.country?.map((country: string, index: number) => (
                    <Typography key={index} className="countryText">
                      {country}
                      {index < recentValue?.attributes?.country?.length - 1 && ','}
                    </Typography>
                  ))}
                </Box>
                <Typography style={webStyles.defaultTxtBold} className="recentUpdateHeading">
                  {recentValue?.attributes.title}
                </Typography>
                <Box className="listPadding">
                  <Typography
                    className="listTxt"
                    dangerouslySetInnerHTML={{
                      __html: recentValue?.attributes?.content
                    }}
                  >
                  </Typography>
                </Box>
                <Box className="belowBox">
                  <Typography style={webStyles.italicStyle}>
                    {configJSON.postedOnDate}{recentValue?.attributes?.published_at}
                  </Typography>
                  <ColorButton data-test-id="ViewMoreUpDates" onClick={this.DashbordViewMore} endIcon={<ArrowForwardIcon />}>
                    <ViewMoreTxt>
                      {configJSON.viewMoreText}
                    </ViewMoreTxt>
                  </ColorButton>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </AnyUpdateBox>
      </Box>
    )
  };

  renderPendingTask = () => {
    return (
      <PendingTaskBox>
        <Typography
          className="headingTxt">
          {configJSON.pendingTasksTitle}
        </Typography>
        <TableContainer component={Paper} >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={webStyles.paddingAll}>
                <TableCell style={{ ...webStyles.defaultTxtBold }}>{configJSON.tableHeaders.appId}</TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">{configJSON.tableHeaders.title}</TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">{configJSON.tableHeaders.student}</TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">{configJSON.tableHeaders.university}</TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">{configJSON.tableHeaders.dueDate}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody >
              {this.state.pendigTaskData.map((item: PendigTask) => (
                <TableRow key={item.id}>
                  <TableCell className="idText">{item.id}</TableCell>
                  <TableCell align="center" className="tabTxt">{item.title}</TableCell>
                  <TableCell align="center" className="tabTxt">{item.student}</TableCell>
                  <TableCell align="center" className="tabTxt">{item.university}</TableCell>
                  <TableCell align="center" className="tabTxt">{item.dueDate} &nbsp;<span className="dayTxt">{item.daysLeft}</span></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PendingTaskBox>
    );
  };

  teamPerformanceTable = () => {
    const { teamPerformanceTitle, tableHeadersTeam, noData } = configJSON;
    const { teamPerformanceTable } = this.state;
    return (
      <TeamBox>
        <Typography className="headingTxt">
          {teamPerformanceTitle}
        </Typography>
        <TableContainer className="tableMain">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow style={webStyles.paddingAll}>
                <TableCell style={{ ...webStyles.defaultTxtBold }}>
                  {tableHeadersTeam.teamMembers}
                </TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">
                  {tableHeadersTeam.assignedStudents}
                </TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">
                  {tableHeadersTeam.activeApplications}
                </TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">
                  {tableHeadersTeam.tuitionFeePaid}
                </TableCell>
                <TableCell style={{ ...webStyles.defaultTxtBold }} align="center">
                  {tableHeadersTeam.conversion}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyles.paddingAll}>
              {teamPerformanceTable && teamPerformanceTable.length > 0 ? (
                teamPerformanceTable.map((item) => (
                  <TableRow style={webStyles.paddingAll}>
                    <TableCell className="tabTxt">
                      {item?.attributes?.name}
                    </TableCell>
                    <TableCell className="tabTxt" align="center">
                      {item?.attributes?.assigned_student}
                    </TableCell>
                    <TableCell className="tabTxt" align="center">
                      {item?.attributes?.active_applications}
                    </TableCell>
                    <TableCell className="tabTxt" align="center">
                      {item?.attributes?.tuition_fees_paid}
                    </TableCell>
                    <TableCell className="tabTxt" align="center">
                      {item?.attributes?.conversion}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center" className="noDataTxt">
                    {noData}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </TeamBox>
    );
  };

  renderBalanceBox = () => {
    const { countryValue } = this.state;
    const { balanceTitle, balanceAmount, selectPlaceholder } = configJSON;
    return (
      <BalanceBox>
        <Box>
          <Typography style={webStyles.fontBig}>
            {balanceTitle}
          </Typography>
          <Typography style={webStyles.cardContent}>
            {balanceAmount}
          </Typography>
        </Box>
        <SelectInput
          displayEmpty
          MenuProps={{
            disableScrollLock: true,
            anchorOrigin: {
              horizontal: "left",
              vertical: "bottom"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null,
          }}
          value={countryValue}
          IconComponent={KeyboardArrowDown}
          onChange={this.countryChange}
          style={{
            color: countryValue === "none" ? "#999" : "#000"
          }}
        >
          {countryValue === "none" && (
            <MenuItem disabled value="none">
              {selectPlaceholder}
            </MenuItem>
          )}
          {this.countryData.map((item) => (
            <MenuItem key={item.name} value={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </SelectInput>
      </BalanceBox>
    );
  };

  renderAgentBox = () => {
    const { agentRecords } = this.state;
    const agent = agentRecords && agentRecords[0]?.attributes;

    return (
      <AgentBox>
        {agent ? (
          <>
            <Box className="agentImageBox">
              {agent.image === null ?
                <img src={configJSON.imgIcons} height="100%" width={"100%"} />
                :
                <img src={agent.image} height="100%" width={"100%"} />
              }
            </Box>
            <Box className="detailsTxt">
              <Typography style={webStyles.defaultTxtBold}>
                {`${agent.first_name} ${agent.last_name}`}
              </Typography>
              <Typography className="agentTitle">
                {configJSON.agentTitle}
              </Typography>
              <Box style={webStyles.flexCenter}>
                <PhoneInTalkOutlinedIcon />
                <Typography className="agentPhone">
                  {agent.phone_number}
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Box className="detailsTxt">
            <Typography style={webStyles.defaultTxtBold}>
              {configJSON.noAgentTxt}
            </Typography>
          </Box>
        )}
      </AgentBox>
    );
  };

  renderOffers = () => {
    const { offersData } = this.state;
    const offer = offersData && offersData[this.state.offerPaginationNumber];

    return (
      <OfferMainBox>
        <Box className="firstBox">
          <Typography className="headingTxt">
            {configJSON.offerHeaderText}
          </Typography>
          <Box className="btnBox">
            <Button
              disabled={this.state.offerPaginationNumber === 0}
              data-test-id="prevBtnTestId"
              className="nextPrevBtn"
              onClick={this.onPrevOffer}>
              <NavigateBeforeIcon fontSize="medium" />
            </Button>
            <Button
              disabled={this.onRecentNextDisable()}
              className="nextPrevBtn"
              data-test-id="nextBtnTestId"
              onClick={this.onNextOffer}>
              <NavigateNextIcon fontSize="medium" />
            </Button>
          </Box>
        </Box>

        {offer ? (
          <Box className="innerMainBox">
            <Box>
              <Box className="tooltipBox">
                <Typography
                  className="universityText"
                  data-test-id="universityTestId"
                >
                  {offer?.attributes?.title}
                </Typography>
                
                {this.state.openTooltipId === offer.id && (
                  <TooltipStyle
                    data-test-id="toolTipCloseId"
                    onClose={this.handleTooltipClose}
                    open
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={offer.attributes.terms_and_conditions}
                    arrow
                  >
                    <img
                      src={IMG_CONST.infoIcon}
                      className="infoIcon"
                      data-test-id="imageTestId"
                      onClick={() => this.handleTooltipClose()}
                    />
                  </TooltipStyle>
                )}
                {this.state.openTooltipId !== offer.id && (
                  <img
                    data-test-id="toolTipOpenId"
                    src={IMG_CONST.infoIcon}
                    className="infoIcon"
                    onClick={() => this.handleTooltipOpen(offer.id)}
                  />
                )}
              </Box>
            </Box>
            <Box className="middleBox">
              <Typography className="universityDescription">
                {offer?.attributes?.description}
              </Typography>
            </Box>
            <Box className="txtBox">
                <Typography className="leftSideTxt">
                  {configJSON.offeredBy}
                </Typography>
                <Typography className="rightSideTxt universityTxt">{offer.attributes.university}</Typography>
              </Box>
              <Box className="txtBox">
                <Typography className="leftSideTxt">
                  {configJSON.promoCodeText}
                </Typography>
                <Typography className="rightSideTxt">{offer.attributes.promo_code}</Typography>
              </Box>
              <Box className="txtBox">
                <Typography className="leftSideTxt">
                  {configJSON.discountValueText}
                </Typography>
                <Typography className="rightSideTxt">{`${offer.attributes.discount_value}%`}</Typography>
              </Box>
              <Box className="txtBox">
                <Typography className="leftSideTxt">
                  {configJSON.promotionEndsOnText}
                </Typography>
                <Typography className="rightSideTxt">{offer.attributes.validity_period}</Typography>
              </Box> 
            <Box className="viewBtnBox">
              <ColorButton
                data-test-id="offerPageTestId"
                endIcon={<ArrowForwardIcon />}
                className="viewBtn"
                onClick={this.navigationToOfferPage}>
                <ViewMoreTxt>
                  {configJSON.viewMoreText}
                </ViewMoreTxt>
              </ColorButton>
            </Box>
          </Box>
        ) : (
          <Box className="innerMainBox">
          <Typography className="noOfferTxt">{configJSON.noOffersText}</Typography>
          </Box>
        )}
      </OfferMainBox>
    );
  };

  renderOffersForCounselleor = () => {
    const { offersData, currentPage, offersPerPage } = this.state;
    const startIndex = currentPage * offersPerPage;
    const selectedOffers = offersData.slice(startIndex, startIndex + offersPerPage);

    return (
      <>
        {
          selectedOffers ? (
            <>
              <OfferMainBox>
                <Box className="firstBoxOffer">
                  <Box className="firstBoxCounseller">
                    <Typography className="headingTxt">
                      {configJSON.offerHeaderText}
                    </Typography>
                    <Box className="btnBox">
                      <Button
                        className="nextPrevBtn"
                        onClick={this.onPrevOfferCounsellor}
                        disabled={this.state.currentPage === 0}
                        data-test-id="prevBtnTestIdCounsellor"
                      >
                        <NavigateBeforeIcon fontSize="medium" />
                      </Button>
                      <Button
                        data-test-id="nextBtnTestIdCounsellor"
                        onClick={this.onNextOfferCounsellor}
                        disabled={this.onRecentNextDisableCounsellor()}
                        className="nextPrevBtn">
                        <NavigateNextIcon fontSize="medium" />
                      </Button>
                    </Box>
                  </Box>
                  <Box className="viewBtnBox">
                    <ColorButton
                      className="viewBtnOffer"
                      onClick={this.navigationToOfferPage}
                      data-test-id="offerPageTestId"
                      endIcon={<ArrowForwardIcon />}>
                      <ViewMoreTxt>{configJSON.viewMoreText}</ViewMoreTxt>
                    </ColorButton>
                  </Box>
                </Box>
              </OfferMainBox>
              <OfferWrapper container spacing={2}>
                {selectedOffers.map((offer, index) => (
                  <Grid className="offerGrid" item xs={12} sm={6} md={4} key={index}>
                    <Box className="innerMainBox">
                      <Box className="tooltipBox">
                        <Typography className="universityText" data-test-id="universityTestId">
                          {offer?.attributes?.title}
                        </Typography>
                        {this.state.openTooltipId === offer.id && (
                          <TooltipStyle
                            disableHoverListener
                            disableTouchListener
                            title={offer.attributes.terms_and_conditions}
                            arrow
                            data-test-id="toolTipCloseId"
                            onClose={this.handleTooltipClose}
                            open
                            disableFocusListener
                          >
                            <img
                              className="infoIcon"
                              data-test-id="imageTestId"
                              src={IMG_CONST.infoIcon}
                              onClick={() => this.handleTooltipClose()}
                            />
                          </TooltipStyle>
                        )}
                        {this.state.openTooltipId !== offer.id && (
                          <img
                            src={IMG_CONST.infoIcon}
                            className="infoIcon"
                            data-test-id="toolTipOpenId"
                            onClick={() => this.handleTooltipOpen(offer.id)}
                          />
                        )}
                      </Box>
                      <Box className="middleBox">
                        <Typography className="universityDescription">
                          {offer?.attributes?.description}
                        </Typography>
                      </Box>
                      <Box className="txtBox">
                        <Typography className="leftSideTxt">{configJSON.offeredBy}</Typography>
                        <Typography className="rightSideTxt universityTxt">
                          {offer.attributes.university}
                        </Typography>
                      </Box>
                      <Box className="txtBox">
                        <Typography className="leftSideTxt">{configJSON.promoCodeText}</Typography>
                        <Typography className="rightSideTxt">{offer.attributes.promo_code}</Typography>
                      </Box>
                      <Box className="txtBox">
                        <Typography className="leftSideTxt">{configJSON.discountValueText}</Typography>
                        <Typography className="rightSideTxt">{`${offer.attributes.discount_value}%`}</Typography>
                      </Box>
                      <Box className="txtBox">
                        <Typography className="leftSideTxt">{configJSON.promotionEndsOnText}</Typography>
                        <Typography className="rightSideTxt">{offer.attributes.validity_period}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </OfferWrapper>
            </>
          ) : (
            <OfferMainBox>
            <Box className="innerMainBox">
              <Typography className="noOfferTxt">{configJSON.noOffersText}</Typography>
            </Box>
            </OfferMainBox>
          )
        }
      </>
    );
  };

  renderMainBox = () => {
    return (
      <InnnerMainBox>
        {this.renderLeftColumn()}
        {this.renderRightColumn()}
      </InnnerMainBox>
    );
  };

  renderServices = () => {
    return (
      <Box className="serviceBox">
        <Box className="headBox">
          <Typography className="headingTxt">
            {configJSON.servicesTitle}
          </Typography>
          <Box className="comingSoonTxt">
            <Typography variant="body2">
              {configJSON.comingSoonText}</Typography>
          </Box>
        </Box>
        <Box>
          {this.state.otherService.map((item) => (
            <ul>
              <li className="otherServiceListTxt"> {item.attributes.title}</li>
            </ul>
          ))}
        </Box>
        <Box className="viewBtnBox">
          <ColorButton endIcon={<ArrowForwardIcon />} 
            data-test-id="otherServiceTestId"
            className="viewBtn"
          onClick={this.navigateToOtherService}>
            <ViewMoreTxt>
              {configJSON.viewMoreText}
            </ViewMoreTxt>
          </ColorButton>
        </Box>
      </Box>
    );
  };

  renderIntake = () => {
    const { countryIntakeValue } = this.state;
    const { selectPlaceholder, inTakesTxt, popularText } = configJSON;
    return (
      <IntakeContainer>
        <Box className="topIntakeTxtBox" >
          <Box>
            <Typography className="inTakesTxt">{inTakesTxt}</Typography>
            <Typography className="popularText">{popularText}</Typography>
          </Box>
          <SelectInput
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              disableScrollLock: true,
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            IconComponent={KeyboardArrowDown}
            value={countryIntakeValue}
            onChange={this.countryIntakeChange}
            data-test-id="countryDataTestId"
            style={{
              color: countryIntakeValue === "none" ? "#999" : "#000"
            }}
          >
            {countryIntakeValue === "none" && (
              <MenuItem disabled value="none">
                {selectPlaceholder}
              </MenuItem>
            )}
            {this.state.countryIntakeData.map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </SelectInput>
        </Box>
        {this.renderTab()}
      </IntakeContainer>
    )
  };

  renderInTakeList = () => {
    return (
      this.state.intakeList[this.state.value]?.universities?.length === 0
      ? (
        <Box>
          <NoRecordText>{configJSON.noIntake}</NoRecordText>
        </Box>
      )
      : (
        <>
          {this.state.intakeList[this.state.value]?.universities?.map((item, indexValue) => (
            <IntakeListBox key={indexValue}>
              <Box className="imageBox">
                <img src={item?.logo_url} width="100%" height="100%" alt="Month Group" />
              </Box>
              <Typography className="titleTxt">{item?.name}</Typography>
            </IntakeListBox>
          ))}
          <ViewBtnBox>
            <ColorButton endIcon={<ArrowForwardIcon />} className="viewBtn">
              <ViewMoreTxt>{configJSON.viewMoreText}</ViewMoreTxt>
            </ColorButton>
          </ViewBtnBox>
        </>
      )
    );
  };
  
  renderTab = () => {
    return (
      <MainTabBox>
        <Box>
          <Box className="appBarBox">
            <AppBar position="static">
              <Tabs
                value={this.state.value}
                onChange={this.handleChange}
                data-test-id="tabChangeTestId"
                className="tabSection"
              >
                <Tab className="tabLabel" label={this.state.intakeList[0]?.month_group} value={0} />
                <Tab className="tabLabel" label={this.state.intakeList[1]?.month_group} value={1} />
                <Tab className="tabLabel" label={this.state.intakeList[2]?.month_group} value={2} />

              </Tabs >
            </AppBar >
          </Box>
          {this.tabPanel(this.renderInTakeList(), 0, 0)}
          {this.tabPanel(this.renderInTakeList(), 1, 0)}
          {this.tabPanel(this.renderInTakeList(), 2, 0)}
        </Box>
      </MainTabBox>
    )
  };

  tabPanel(children: {}, value: number, indexValue: number) {
    return (
      <div
        role="tabpanel"
        hidden={value !== indexValue}
        id={`simple-tabpanel-${indexValue}`}
        aria-labelledby={`simple-tab-${indexValue}`}
      >
        {value === indexValue && (
          <TabBox>
            <Typography>{children}</Typography>
          </TabBox>
        )}
      </div>
    );
  };

  renderLeftColumn = () => {
    return (
      <LeftRightBox >
        {this.renderUpperTable()}
        {this.renderAnyUpdateBox()}
        {this.renderPendingTask()}
        {this.state.userDetails?.attributes?.role === "agent" && this.teamPerformanceTable()}
        {this.state.userDetails?.attributes?.role === "counsellor" && this.renderOffersForCounselleor()}
      </LeftRightBox>
    );
  };

  renderRightColumn = () => {
    return (
      <RightBox>
        {this.renderStudentCard()}
        {this.renderStartNewApplicationButton()}
        {this.renderBalanceBox()}
        {this.renderServices()}
        {this.state.userDetails?.attributes?.role === "agent" &&  this.renderOffers()}
        {this.renderIntake()}
        {this.renderAgentBox()}
      </RightBox>
    );
  };

  renderStudentCard = () => {
    return (
      <StudentCardBox>
        <Box className="innerBox">
          <Box width="40px" height="40px" marginRight="10px">
            <img src={IMG_CONST.studentImg} width="100%" height="100%" alt="Paid Application Icon" />
          </Box>
          <Box className="studentBtnBox">
            <Typography style={webStyles.cardTitle}>
              {configJSON.studentCardTitle}
            </Typography>
            <Typography style={webStyles.cardContent}>{this.state.statusCountData?.students}</Typography>
          </Box>
        </Box>
        <Button className="addStudentBtn"
          data-test-id="addStudentTestId"
          onClick={this.navigateToNewStudentPage}
        >
          {configJSON.addStudentButtonText}
        </Button>
      </StudentCardBox>
    );
  };

  renderStartNewApplicationButton = () => {
    return (
      <AddButton
        data-test-id="addApplicationTestId"
        onClick={this.navigateToAddApplicationPage}
      >
        {configJSON.startNewApplication}
      </AddButton>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <Box>
        <AppHeader isLogin={false}
          data-test-id="appHeaderTestId"
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={true}
          userName={this.state.userDetails?.attributes?.first_name}
          role={this.state.userDetails?.attributes?.role}
          profileImg={this.state.userDetails?.attributes?.image}
        />
        <DashboardBox>
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="DashBoard" />
          <Box style={{ ...webStyles.backgroundColor }} className="contentBox">
            {this.renderMainBox()}
          </Box>
        </DashboardBox>
        {this.renderUpdateModal()}
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const NoRecordText =styled(Typography)({
  textAlign: "center",
   fontFamily: "Plus Jakarta Sans"
});

const InnerModalBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  "& .iconCloseBtn":{
    position: "absolute",
     top: 0, 
     right: 0 
  },
  "& .modalTextBox":{
    backgroundColor: "#FFFF",
    position: "relative",
    borderRadius: "5px",
    padding: "30px 60px",
    cursor: "pointer",
    "@media (max-width: 600px)": { 
      padding: "20px 30px",
    },
  },
  "& .modalItemTxt": {  
    maxHeight: "500px",
    overflowY: "scroll",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "5px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f1f1f1",
    }
  }
});

const PendingTaskBox = styled(Box)({
  "& .MuiTableCell-head": {
    color: "#000",
    fontWeight: "600",
    fontSize: "14px"
  },
  "& .MuiTableContainer-root": {
    margin: "30px 0px"
  },
  "& .MuiPaper-elevation1": {
    boxShadow: "none",
    borderRadius: "8px"
  },
  "& .headingTxt": {
    marginBottom: "20px",
    fontWeight: 700,
    fontSize: "22px",
    fontFamily: "Plus Jakarta Sans"
  },
  "& .dayTxt": {
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "14px",
    backgroundColor: "#F1F5F9",
    padding: "5px 18px",
    borderRadius: "4px",
    "@media (max-width: 1000px)": {
      padding: "3px"
    }
  },
  "& .idText": {
    fontWeight: 700,
    fontSize: "14px",
    fontFamily: "Plus Jakarta Sans"
  },
  "& .tabTxt": {
    fontSize: "14px",
    fontFamily: "Plus Jakarta Sans"
  }
});


const IntakeContainer = styled(Box)({
  borderRadius: "8px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  backgroundColor: "#FFFFFF",
  padding: "20px 0px",
  boxSizing: "border-box",
  width: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  "& .topIntakeTxtBox": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 10px",
    boxSizing: "border-box"
  },
  "& .inTakesTxt": {
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    fontWeight: 700,
    fontSize: "16px"
  },
  "& .popularText": {
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: "11px"
  }
});

const TeamBox = styled(Box)({
  "& .headingTxt": {
    marginBottom: "20px",
    fontWeight: 700,
    fontSize: "22px",
    fontFamily: "Plus Jakarta Sans"
  },
  "& .tableMain": {
    padding: "10px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px"
  }
});

const BalanceBox = styled(Box)({
  padding: "20px",
  display: "flex",
  alignItems: "center",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  justifyContent: "space-between"
});

const InnnerMainBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  gap: "20px",
  width: "100%",
  "@media (max-width: 1280px)": {
    flexDirection: "column"
  }
});

const LeftRightBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  width: "100%",
  "& .updateBox": {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  "& .recentUpdateBox": {
    display: "flex",
    gap: "20px"
  }
});

const RightBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  width: "380px",
  "@media (max-width: 1280px)": {
    width: "100%"
  },
  "& .serviceBox": {
    display: "flex",
    flexDirection: "column",
    padding: "20px",
    backgroundColor: "#FFFFFF",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    borderRadius: "8px"
  },
  "& .headBox": {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-body1": {
      color: "#2A0066",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "26px",
      fontFamily: "Plus Jakarta Sans"
    }
  },
  "& .comingSoonTxt": {
    padding: "3px 10px",
    backgroundColor: "#FDEBED",
    borderRadius: "8px",
    "& .MuiTypography-body2": {
      color: "#B8383C",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "28px",
      fontFamily: "Plus Jakarta Sans",
      "@media (max-width: 1000px)": {
        fontSize: "10px",
      textAlign: "center"
      }
    }
  },
  "& .otherServiceListTxt": {
    fontFamily: "Plus Jakarta Sans",
    maxWidth: "300px",
    fontWeight: 400,
    fontSize: "13px"
  },
  "& .viewBtnBox": {
    alignItems: "center",
    display: "flex",
    padding: "10px",
    justifyContent: "center"
  }

});

const AddButton = styled(Button)({
  color: "#FFFFFF",
  border: "1px solid #23CF93",
  borderRadius: "8px",
  textTransform: "unset",
  fontSize: "14px",
  fontFamily: "Plus Jakarta Sans",
  backgroundColor: "#34D399",
  width: "auto",
  maxWidth: "100%",
  "&:hover": {
    backgroundColor: "#34D399",
    color: "#FFFFFF"
  },
  "@media (max-width: 768px)": {
    fontSize: "12px"
  }
});

const StudentCardBox = styled(Box)({
  alignItems: "center",
  display: "flex",
  height: "90px",
  borderRadius: "8px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  padding: "20px",
  boxSizing: "border-box",
  backgroundColor: "#FFFFFF",
  justifyContent: "space-between",
  "& .addStudentBtn": {
    color: "#BB393D",
    border: "1px solid #BB393D",
    height: "32px",
    borderRadius: "5px",
    textTransform: "unset",
    fontSize: "12px",
    fontFamily: "Plus Jakarta Sans",
    width: "auto",
    maxWidth: "100%",
    padding: "3px 8px",
    fontWeight: "bold",
    "@media (min-width: 1280px) and (max-width: 1350px)": {
      fontSize: "10px"
    }
  },
  "& .innerBox": {
    display: "flex", flexDirection: "row" 
  },
  "& .studentBtnBox": {
    display: "flex",
    flexDirection: "column"
  },
  "@media (max-width: 500px)": {
    flexDirection: "column",
    height: "auto",
    gap: "5px"
  }
});

const ViewBtnBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20px",
  "& .viewBtn":
  {
    width: "130px",
    align: "center"
  }
});

const TooltipStyle = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#0F172A",
    padding: "15px",
    color: "#FFFFFF",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "13px",
    marginTop: "10px"
  },
  arrow: {
    color: "#0F172A",
  },
}))(Tooltip);

const OfferMainBox = styled(Box)({
  display: "flex",
  gap: "20px",
  flexDirection: "column",
  "& .tooltipBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  "& .infoIcon": {
    height: "min-height",
    marginTop: "5px"
  },
  "& .iconStyle": {
    position: "relative",
    color: "#FFFF",
    border: "0px solid #0F172A",
    borderRadius: "50%",
    backgroundColor: "#0F172A",
    cursor: "pointer"
  },
  "& .firstBox": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .firstBoxOffer": {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      justifyContent: "center",
      gap:"12px"
    },
    "@media (min-width: 601px) and (max-width: 960px)": {
      flexDirection: "row",
      justifyContent: "space-around",
      gap:"12px"
    },
    "@media (min-width: 961px)": {
      flexDirection: "row",
      justifyContent: "space-between",
      gap:"12px"
    },
  },
  "& .firstBoxCounseller":{
    display:"flex",
    justifyContent: "flex-start",
    gap:"8px"
  },
  "& .btnBox": {
    display: "flex", gap: "10px"
  },
  "& .viewBtnBox": { display: "flex", alignItems: "center", justifyContent: "center" },
  "& .viewBtn": { width: "130px", align: "center" },
  "& .viewBtnOffer": { width: "130px", align: "center", "@media (max-width: 600px)": {
    width:"100%"
  },
  "@media (min-width: 601px) and (max-width: 960px)": {
    width:"100%"
  },
  "@media (min-width: 961px)": {
    width:"100%"
  },},
  "& .nextPrevBtn": { border: "1px solid #CBD5E1", borderRadius: "8px", padding: "5px", minWidth: "55px" },
  "& .headingTxt": {
    fontWeight: 700,
    fontSize: "22px",
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A"
  },
  "& .noOfferTxt":{
    fontFamily: "Plus Jakarta Sans", 
    color: "#0F172A",
    textAlign: "center"
  },
  "& .leftSideTxt": {
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B"
},
"& .rightSideTxt": {
    fontWeight: 700,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    width:"100px",
    textAlign:"end"
},
"& .universityTxt":{
  width: "200px",
  height:"40px",
  "@media(max-width:500px)": {
      width: "180px",
      height:"auto"
  }
},
"& .txtBox": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #E2E8F0",
    padding:"0px 0px 10px 0px"
},
  "& .innerMainBox": {
    padding: "20px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    justifyContent: "space-between",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  }, "& .universityText": {
    fontWeight: 700,
    fontSize: "16px",
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    paddingBottom: "10px"
  }, "& .subHeadingTxt": {
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B",
    textAlign: "center",

  },
  "& .dateTxt": {
    fontWeight: 700,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B",

  },
  "& .middleBox": { display: "flex", gap: "5px", flexDirection: "column", },
  "& .universityDescription": {
    fontWeight: 500,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#212121",
    maxHeight: "80px",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    scrollbarWidth: "thin", 
    "&::-webkit-scrollbar": {
        width: "5px", 
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1"
    },
},
  "& .borderBox": {
    borderBottom: "1px solid #D6DBE3",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "10px",
    padding: "10px 0px"
  },
  "& .appTxt": {
    fontWeight: 400,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B"
  },
  "& .percentTxt": {
    fontWeight: 700,
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    maxWidth: "110px"
  }
});

const DashboardBox = styled(Box)({
  background: "#F7F7F7",
  display: "flex",
  "& .mainContainer": {
    padding: "30px"
  },
  "& .contentBox": {
    marginLeft: "auto",
    width: "calc(100% - 64px)",
    padding: "50px",
    boxSizing: "border-box",
    maxWidth: "100%",
    "@media (max-width: 500px)": {
      padding: "20px"
    }
  },
  "& .mainItem": {
    paddingRight: "20px",
    boxSizing: "border-box"
  },
  "& .cardContainer": {
    boxSizing: "border-box"
  },
  "& .cards": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "90px",
    borderRadius: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    paddingLeft: "20px",
    bgcolor: "white",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF"
  }
});

const SelectInput = styled(Select)({
  width: "103px",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  background: "white",
  height: "40px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "white"
  },
 
});

const ColorButton = styled(Button)({
  color: "#23CF93",
  border: "1px solid #23CF93",
  height: "28px",
  padding: "6px 10px",
  borderRadius: "8px"
})

const HeadingTxt = styled(Typography)({
  marginBottom: "20px",
  fontWeight: 700,
  fontSize: "22px",
  fontFamily: "Plus Jakarta Sans"
});

const BtnBox = styled(Typography)({
  display: "flex",
  gap: "10px",
  height: "36px",
  alignItems: "center",
  "& .viewBtnBox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .viewBtn": {
    width: "130px",
    align: "center"
  },
  "& .nextPrevBtn": {
    borderRadius: "8px",
    padding: "5px",
    minWidth: "55px",
    border: "1px solid #CBD5E1"
  }
})

const AnyUpdateBox = styled(Box)({
  width: "100%",
  borderRadius: "8px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  backgroundColor: "white",
  padding: "20px",
  boxSizing: "border-box",
  "& .imgGrid": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  "& .innerUpdateMainBox": {
    maxWidth: "1000px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "start",
    "@media (max-width: 599px)": {
      maxWidth: "395px"
    }
  },
  "& .listPadding": {
    padding: "10px 0px",
    fontSize: "14px",
    color: "#212121",
    lineHeight: "17.64px",
    opacity: "80%",
    width: "100%"
  },
  "& .listTxt": {
    fontFamily: "Plus Jakarta Sans",
    maxHeight: "100px",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    scrollbarWidth: "thin",
    width: "100%",
    "&::-webkit-scrollbar": {
        width: "5px" 
    },
    "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "10px"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1"
    }
  },
  "& .belowBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    "@media screen and (max-width:500px)": {
      flexDirection: "column",
      gap: "10px"
    }
  },
  "& .recentUpdateHeading":{
    color: "#2A0066",
    fontFamily: "Plus Jakarta Sans",
    fontWeight:700
  },
  "& .upperTxtBox":{
    display:"flex", justifyContent: "space-between", width:"100%",
    "@media screen and (max-width:500px)": {
      flexDirection: "column",
      gap: "10px"
    }
  },
  "& .countryList": {
    padding:"20px 0",
    display:"flex",
    gap:"2px",
    flexWrap:"wrap",
    width: "100%"
  },
  "& .readMoreBtn": {
    fontFamily: "Plus Jakarta Sans",
    color: "#1976d2",
    textTransform: "unset",
    padding: "0px !important",
    fontSize: "16px",
    fontWeight: "bold"
  },
  "& .announcementType": {
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
    textAlign: "center",
    padding: "0px !important",
    fontSize: "16px",
    fontWeight: "bold",
    marginRight:"10px"
  },
  "& .hideText": {
    visibility: "hidden"
  },
  "& .countryText": {
    fontSize: "16px",
    fontWeight: "normal",
    color: "#000000",
    fontFamily: "Plus Jakarta Sans",
  }
})

const ViewMoreTxt = styled(Typography)({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 700,
  textTransform: "none"
});

const webStyles = {
  container: {
    display: "grid",
    gridTemplateColumns: "3fr 7fr",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
    width: "32rem",
    padding: "0.5rem 1rem",
    minHeight: "12rem",
    gap: "2rem"
  },
  defaultTxt: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px"
  },
  defaultTxtBold: {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700
  },
  totalCandidates: {
    display: "grid",
    gridTemplateRows: "3fr 7fr",
    alignItems: "start",
    justifyContent: "center",
    justifyItems: "center",
    color: "black",
    margin: "1rem 0rem"
  },
  italicStyle: {
    fontWeight: 500,
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "italic",
    color: "#212121",
    opacity: "40%"
  },
  candidatesFontSize: {
    fontSize: "1rem"
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  columnCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  spaceAround: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  },
  paddingRight: {
    paddingRight: "40px"
  },
  cardContent: {
    fontWeight: 700,
    fontSize: "22px",
    lineHeight: "1",
    "&. MuiTypography-body1": {
      lineHeight: "1"
    }
  },
  cardTitle: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#64748B"
  },
  fontBig: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#64748B"
  },
  secondCardHeader: {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    fontSize: "20px",
    color: "#212121"
  },
  btn: {
    backgroundColor: "blue",
    color: "white",
    borderRadius: "0",
    marginTop: "0.5rem",
    padding: "0.125rem"
  },
  flexEnd: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  fontSizeForType: { fontSize: "1 rem" },
  mediumFontSizeForType: { fontSize: "3rem" },
  itemStyle: { margin: "1rem 0rem" },
  type: { fontSize: "0.8rem", textTransform: "capitalize" },
  titletext: {
    color: "grey",
    fontWeight: "lighter",
    fontSize: "3rem"
  },
  typeText: { fontSize: "0.8rem", textTransform: "capitalize" },
  regularFont: { fontSize: "14px", color: "#212121", lineHeight: "17.64px", opacity: "80%" },
  listPadding: { padding: "0 11px" },
  backgroundColor: {
    fontFamily: "Plus Jakarta Sans",
    background: "#F7F7F7"
  },
  paddingAll: {
    padding: "20px"
  }
};

const UpperTable = styled(Grid)({

  "& .cards": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "90px",
    borderRadius: "8px",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    paddingLeft: "20px",
    bgcolor: "white",
    boxSizing: "border-box",
    backgroundColor: "#FFFFFF"
  },
  "& .cardImageContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    width: "40px",
    height: "40px"
  },
  "& .cardContentContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
    padding: "0px 16px"
  }
});

const AgentBox = styled(Box)({
  flexDirection: "column",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "white",
  padding: "18px 0px",
  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
  borderRadius: "5px",
  "& .detailsTxt": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px"
  },
  "& .agentTitle": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#64748B"
  },
  "& .agentPhone": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#0F172A"
  },
  "& .agentImageBox": {
    height: "90px",
    width: "90px",
    display: "flex",
    borderRadius: "50%",
    overflow: "hidden"
  }
});

const TabBox = styled(Box)({
  padding: "24px 0px 0px 0px"
});

const MainTabBox = styled(Box)({
  backgroundColor: "#FFFFFF",
  marginTop: "20px",
  width: "100%",
  "& .MuiAppBar-colorPrimary": {
    backgroundColor: "#fff"
  },
  "& .MuiTab-wrapper": {
    color: "#000"
  },
  "& .MuiTabs-indicator ": {
    height: "0px"
  },
  "& .appBarBox": {
    padding: "0px 5px"
  },
  "& .MuiPaper-elevation4": {
    boxShadow: "none",
    border: "2px solid #BB393D",
    backgroundColor: "#F1D7D8",
    borderRadius: "50px",
    height: "40px"
  },
  "& .tabLabel": {
    textTransform: "unset",
    fontSize: "13px"
  },
  "& .tabSection": {
    "& .MuiTabs-scroller": {
      "& .MuiTabs-flexContainer": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& .MuiTab-root": {
          minWidth: "0px",
          borderRadius: "50px",
          margin: "2px",
          padding: "0px",
          width: "32%",
          opacity: 1,
          height: "32px",
          minHeight: "0px",
          boxSizing: "border-box",
          "& .MuiTab-wrapper": {
            color: "#000000",
            fontFamily: "Plus Jakarta Sans",
            fontWeight: 700,
            fontSize: "16px"
          },
          "@media screen and (max-width:1590px)": {
            "& .MuiTab-wrapper": {
              fontSize: "12px"
            }
          }
        },
        "& .Mui-selected": {
          backgroundColor: "#BB393D",
          "& .MuiTab-wrapper": {
            color: "#FFFFFF !important"
          }
        }
      }
    }
  }
});

const IntakeListBox = styled(Box)({
  display: "flex",
  borderBottom: "1px solid #E2E8F0",
  alignItems: "center",
  "& .imageBox": {
    padding: "15px",
    height: "30px",
    width: "30px",
  },
  "& .titleTxt": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "14px",
    color: "#0F172A"
  }
});

const OfferWrapper = styled(Grid)({
  marginBottom: "30px",
  "@media(max-width:500px)": {
    margin: "0px 0px",
  },
  "& .offerGrid": {
    minHeight:"200px",
    display:"flex",
    "@media(max-width:500px)": {
      marginTop:"30px"
    }
  },
  display: "flex",
  "& .tooltipBox": {
    justifyContent: "space-between",
    display: "flex",
    alignItems: "flex-start",
  },
  "& .infoIcon": {
    marginTop: "5px",
    height: "min-height"
  },
  "& .iconStyle": {
    border: "0px solid #0F172A",
    borderRadius: "50%",
    position: "relative",
    backgroundColor: "#0F172A",
    color: "#FFFF",
    cursor: "pointer"
  },
  "& .firstBox": {
    justifyContent: "space-between",
    display: "flex"
  },
  "& .firstBoxCounseller":{
    justifyContent: "flex-start",
    display:"flex",
    gap:"8px"
  },
  "& .btnBox": {
    display: "flex", gap: "10px"
  },
  "& .viewBtnBox": { alignItems: "center", display: "flex", justifyContent: "center" },
  "& .viewBtn": { width: "130px", align: "center" },
  "& .nextPrevBtn": { borderRadius: "8px", border: "1px solid #CBD5E1", padding: "5px", minWidth: "55px" },
  "& .headingTxt": {
    fontSize: "22px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#0F172A"
  },
  "& .noOfferTxt":{
    textAlign: "center",
    fontFamily: "Plus Jakarta Sans", 
    color: "#0F172A"
  },
  "& .leftSideTxt": {
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    color: "#64748B"
},
"& .rightSideTxt": {
    fontFamily: "Plus Jakarta Sans",
    color: "#0F172A",
    fontWeight: 700,
    width:"100px",
    fontSize: "13px",
    textAlign:"end"
},
"& .universityTxt":{
  height:"40px",
  width: "200px",
  "@media(max-width:500px)": {
    height: "auto",
    width: "180px"
  }
},
"& .txtBox": {
  justifyContent: "space-between",
  borderBottom: "1px solid #E2E8F0",
  display: "flex",
  padding: "0px 0px 10px 0px"
},
  "& .innerMainBox": {
    flexDirection: "column",
    gap: "20px",
    flex:1,
    justifyContent: "space-between",
    padding: "20px",
    display: "flex",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    backgroundColor: "#FFFFFF",
    borderRadius: "8px",
  }, "& .universityText": {
    fontSize: "16px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#0F172A",
    paddingBottom: "10px"
  }, "& .subHeadingTxt": {
    fontFamily: "Plus Jakarta Sans",
    color: "#64748B",
    fontWeight: 400,
    fontSize: "13px",
    textAlign: "center"
  },
  "& .dateTxt": {
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#64748B"
  },
  "& .middleBox": { display: "flex", gap: "5px", flexDirection: "column", },
  "& .universityDescription": {
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    color: "#212121",
    overflow: "auto",
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    fontWeight: 500,
    maxHeight: "40px",
    scrollbarWidth: "thin", 
    "&::-webkit-scrollbar": {
        width: "5px", 
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "#888"
    },
    "&::-webkit-scrollbar-track": {
        backgroundColor: "#f1f1f1"
    },
},
  "& .borderBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid #D6DBE3",
    gap: "10px",
    padding: "10px 0px"
  },
  "& .appTxt": {
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    color: "#64748B"
  },
  "& .percentTxt": {
    fontSize: "13px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 700,
    color: "#0F172A",
    maxWidth: "110px"
  }
});
// Customizable Area End
