// Customizable Area Start
import { Color } from "@material-ui/lab/Alert";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    // Customizable Area End
}

// Customizable Area Start
interface ValidResponseType {
    message: object;
    data: object;
}

interface InvalidResponseType {
    errors: [
        {
            message: "Password and Confirm password should be same"
        }
    ]
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}
// Customizable Area End

interface S {
    // Customizable Area Start
    confrimPswrdValue: string;
    pswrdValue: string;
    pswrdError: boolean;
    pswrdErrorTxt: string;
    confrimPswrdError: boolean;
    confrimPswrdErrorTxt: string;
    isAlert: boolean;
    isSuccess: boolean;
    alertMsg: string;
    alertType: Color;
    token: string;
    toggleDrawer: boolean;
    roleName: string
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class NewPasswordController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    newPswrdAPICallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.state = {
            confrimPswrdValue: "",
            pswrdValue: "",
            pswrdError: false,
            pswrdErrorTxt: "",
            confrimPswrdError: false,
            confrimPswrdErrorTxt: "",
            isAlert: false,
            isSuccess: false,
            alertMsg: "",
            alertType: "success",
            token: "",
            toggleDrawer: false,
            roleName: ""
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        const Url = window.location.href
        // Create a URL object
        const parsedUrl = new URL(Url);

        // Use URLSearchParams to get the query parameters
        const params = new URLSearchParams(parsedUrl.search);

        // Get token and role_name
        const token = params.get('token');
        const roleName = params.get('role_name');
        this.setState({ token: token as string, roleName:roleName as string });
    }

    handlePswrdChange = (event: { target: { value: string } }, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
        const pswrdValue = event.target.value;
        SetFieldValue("Password",pswrdValue)
        this.setState({ pswrdValue: pswrdValue }, () => {
            if (pswrdValue === "") {
                this.setState({
                    pswrdError: true,
                    pswrdErrorTxt: "Password is Required.",
                });
            }
            else if (!passwordRegex.test(pswrdValue)) {
                this.setState({
                    pswrdError: true,
                    pswrdErrorTxt:
                        "Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.",
                });
            } else {
                this.setState({ pswrdError: false, pswrdErrorTxt: "" });
            }
        });
    };

    handleConfirmPswrdChange = (event: { target: { value: string } }, SetFieldValue: (field: string, value: string, shouldValidate?: boolean) => void) => {
        SetFieldValue("ConfirmPassword", event.target.value)
        this.setState({ confrimPswrdValue: event.target.value }, () => {
            if (this.state.confrimPswrdValue === "") {
                this.setState({
                    confrimPswrdError: true,
                    confrimPswrdErrorTxt: "Confirm Password is Required.",
                });
            } else if (this.state.pswrdValue != this.state.confrimPswrdValue) {
                this.setState({
                    confrimPswrdError: true,
                    confrimPswrdErrorTxt: "Password and Confirm Password doesn't match.",
                });
            } else {
                this.setState({ confrimPswrdError: false, confrimPswrdErrorTxt: "" });
            }
        });
    };

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };

    navigateToLoginPage = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSucessCall(apiRequestCallId,responseJson);
            }
            if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson);
            }
        }
    }

    apiCall = async (data: APIPayloadType) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    apiSucessCall = async (apiRequestCallId: string, responseJson: {
        data: {
            id: string;
            type: string;
            attributes: {
                no_of_employees: string | null;
                country: string | null;
                state: string | null;
                first_name: string;
                last_name: string;
                company_name: string | null;
                email: string;
                city: string | null;
                verification_type: string | null;
                approval_status: string;
                is_logged_in: boolean;
                created_at: string;
                updated_at: string;
                role: {
                    id: number;
                    updated_at: string;
                    written_access: boolean;
                    name: string;
                    created_at: string;
                    read_access: boolean;
                    view_access: boolean;
                };
            }
        }
    }) => {
        if (apiRequestCallId === this.newPswrdAPICallId) {
            setStorageData("role",responseJson.data.attributes.role.name)
            this.newPswrdSucessCallBack();
        }
    };

    newPswrdSucessCallBack = () => {
        this.setState({ isSuccess: true })
    }

    apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.newPswrdAPICallId) {
            this.newPswrdFailureCallBack(responseJson);
        }
    };

    newPswrdFailureCallBack = (responseJson: InvalidResponseType) => {
        this.setState({ isAlert: true, alertType: "error", alertMsg: responseJson.errors[0].message })
    }

    postNewpswrd = async () => {
        const body = {
            data: {
                password_confirmation: this.state.confrimPswrdValue,
                password: this.state.pswrdValue,
                token: this.state.token
            }
        }
        this.newPswrdAPICallId = await this.apiCall({
            contentType: configJSON.newPswrdAPIContentType,
            method: configJSON.newPswrdAPIApimethod,
            endPoint: configJSON.newPswrdAPIEndPoint,
            body: body
        });
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    onHomeClick = (pageName: string) => {
        setStorageData("LandingPageActive", pageName)
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
            getName(MessageEnum.NavigationTargetMessage),
            "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    PasswordSetSchema = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}\[\]:;"'<>,.?/\\]).{8,15}$/;
        return Yup.object().shape({
            Password: Yup.string().matches(passwordRegex, 'Password should be 8-15 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.').max(30, 'maximum 30 characters are allowed').required("Password is Required."),
            ConfirmPassword: Yup.string().required("Confirm Password is Required.").oneOf([Yup.ref('Password'), null], "Password and Confirm Password doesn't match."),
        })
    }
    // Customizable Area End
}