Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.catalogueList="bx_block_catalogue/catalogues";
exports.fetchAllCountries = "bx_block_catalogue/catalogues/university_country"
exports.fetchAllProgramLevel = "bx_block_catalogue/catalogues/program_levels"
exports.fetchAllDiscipline = "bx_block_catalogue/catalogues/course_discipline"
exports.selectStudent= "bx_block_catalogue/catalogues/select_student"
exports.uploadStudent= "bx_block_catalogue/catalogues/add_course_to_shortlist"
exports.fetchAllIntake = "bx_block_catalogue/catalogues/course_intake"
exports.fetchAllDurations = "bx_block_catalogue/catalogues/course_duration"
exports.fetchAllFilter = "bx_block_catalogue/catalogues/filter"
exports.apiMethodTypePost = "POST";
exports.getSHortlistData="bx_block_catalogue/catalogues/show_course_shortlist"
exports.deleteSHortlistData="bx_block_catalogue/catalogues/delete_course"
exports.getProfileEndPoint = "account_block/accounts/";
exports.getUniversityDetails = "bx_block_catalogue/catalogues/program_and_institution"
exports.getUniversityCourseList = "bx_block_catalogue/catalogues/index_by_university_name"
exports.getUniversityPrograms = "bx_block_catalogue/catalogues/program_levels_by_university_name"
exports.getUniversityDiscipline = "bx_block_catalogue/catalogues/discipline_by_university_name"
exports.getUniversityIntakes = "bx_block_catalogue/catalogues/intake_by_university_name"
exports.getUniversityFilter = "bx_block_catalogue/catalogues/filter_by_university_name"
exports.getUniversitySearch = "bx_block_catalogue/catalogues/search_by_university_name"
// Customizable Area End