// Customizable Area Start
import { getStorageData,setStorageData } from "../../../../packages/framework/src/Utilities";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
const navigation = require("react-navigation");
import {
  Engineering,
  Law,
  UniversityLogo1,
  UniversityLogo2,
  UniversityLogo3,
  UniversityLogo4,
  UniversityLogo5,
  UniversityLogo6,
  UniversityLogo7,
  ImageOne,
  Science,
  Arts,
  Buisness,
  EnglishLanguage,
  Medicine,
  Economics,
  CanadaFlag,
  CountryFlag,
  USAFlag,
  AustraliaFlag,
  IreLandFlag,
  ChartLogo,
  LaptopLogo,
  ThreeLineLogo,
  TimerLogo,
} from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  id: string;
  navigation: typeof navigation;
  // Customizable Area End
}

// Customizable Area Start

interface StepsData {
  id: number;
  stepTitle: string;
  stepDescription: string;
}
interface UnivercityLogo {
  id: number;
  logoImg: string;
}
interface ExplorePopularFieldOfstudy {
  id: number;
  imageURL: string;
  title: string;
}

interface FlagData {
  id: number;
  logoImg: string;
  title: string;
}
interface ContentDataType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    place: string;
    content_type: string;
    url: { url: string };
    service:{id:number;service:{id:number;service:string;}};
    university_country_id:number;
    country_name:string;
  };
}

interface FormattedContentDataType {
  id: string;
  type: string;
  attributes: {
    title: string;
    description: string;
    place: string;
    content_type: string;
    url: { url: string };
    service:{id:number;service:string;} | null;
    countryId:number;
    countryName:string;
  };
}

interface ServiceDataType {
  id: string;
  type: string;
  attributes: {
    title: string;
    service_type:string;
  };
}

interface CountryDataType {
  id: string;
  type: string;
  attributes: {
    country_name: string;
    id: number;
  };
}

type contentTypeMerge = FormattedContentDataType | FormattedContentDataType[] | undefined;
interface ApplyBoardSection {
  id: number;
  imageURL: string;
  title: string;
  subTitle: string;
}
interface CountryName {
  id: number;
  countryName: string;
}

interface AboutUsContent {
  data: AboutUsData[];
  meta: Meta;
}

interface AboutUsData {
  id: string;
  type: string;
  attributes: Attributes;
}

interface StudentDataContent {
  id: string;
  type: string;
  attributes: Attributes2;
}

interface Attributes2 {
  id: number;
  place: string;
  content_type: string;
  title: string;
  description: string;
  created_at: string;
  updated_at: string;
  university_country_id: number | null;
  country_name: string | null;
  service: ServiceData;
  students_enrolled: string;
  applications_proceeded: number;
  url: UrlData;
}

interface ServiceData {
  id: number;
  service: ServiceDetails | null;
}

interface ServiceDetails {
  id: number;
  service_type: string;
  created_at: string;
  updated_at: string;
}

interface UrlData {
  url: string;
}


interface Attributes {
  banner_title: string;
  banner_description: string;
  page_body: string;
  our_impact: string;
  banner: string;
  our_services: Service[];
}

interface Service {
  title: string;
  description: string;
}

interface Meta {
  message: string;
}

interface InstitutionResponse {
  data: InstitutionData[];
  meta: MetaData;
}

interface InstitutionData {
  id: string;
  type: string;
  attributes: InstitutionAttributes;
}

interface InstitutionAttributes {
  banner_title: string;
  banner_description: string;
  banner: string;
  institution_benefits: InstitutionBenefit[];
}

interface InstitutionBenefit {
  title: string;
  benefits: string;
}

interface MetaData {
  message: string;
}



// Customizable Area End

interface S {
  // Customizable Area Start
  UserRole: string
  showSchool: string;
  selectedSchoolTab:string;
  stepData: StepsData[];
  toggleDrawer:boolean;
  serviceData:contentTypeMerge;
  aboutRightSchoolData:{title:string;icon:string;}[];
  allServices:{id:string;serviceName:string}[];
  explorePopularFieldOfstudy: ExplorePopularFieldOfstudy[];
  univercityLogo: UnivercityLogo[];
  applyBoardSection: ApplyBoardSection[];
  countryName: CountryName[];
  flagData: FlagData[];
  activeId:number | null,
  showAllUniversitylogo: boolean;
  allCountryData: contentTypeMerge;
  allCountryName:CountryDataType[];
  menuOptions:{id:number;title:string;}[]
  contentData: FormattedContentDataType[];
  bannerData: {
    Agent: FormattedContentDataType  | undefined;
    school: FormattedContentDataType | undefined;
  } | null;
  middleCardData: {
    Agent: contentTypeMerge;
    student: contentTypeMerge;
  };
  belowCardData: {
    Agent: contentTypeMerge;
    student: contentTypeMerge;
  };
  selectedTab:string;
  activeTab:string;
  aboutUsData:AboutUsData[];
  studentData:StudentDataContent[];
institutionsData:InstitutionData[]
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LandingPageController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  contentManagementCallApiId: string = "";
  servicesCallApiId: string = "";
  countryCallApiId: string = "";
  getAboutUsDataApiCallId:string = "";
  getStudentDataApiCallId:string="";
  
  getInstitutionsCallId:string = "";
  // Customizable Area End
    constructor(props: Props) {
        super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.formatComponentData = this.formatComponentData.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.PostDetailDataMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      showSchool: "",
      contentData: [],
      serviceData:[],
      allServices:[],
      showAllUniversitylogo: false,
      allCountryName:[],
      toggleDrawer:false,
      activeId:null,
      allCountryData:[],
      selectedTab:"",
      selectedSchoolTab:"students",
      bannerData: null,
      middleCardData: { Agent: [], student: [] },
      belowCardData: {
        Agent: [],
        student: [],
      },
      aboutRightSchoolData:[
        {title:"Find Programs Faster",
        icon:TimerLogo},
        {title:"One Easy Application",
        icon:ThreeLineLogo},
        {title:"Central Platform",
        icon:LaptopLogo},
        {title:"Data Driven Insights",
        icon:ChartLogo}
      ],
      stepData: [
        {
          id: 1,
          stepTitle: "Step 1",
          stepDescription: "check Your Elibility",
        },
        {
          id: 2,
          stepTitle: "Step 2",
          stepDescription: "Submit Your Application",
        },
        {
          id: 3,
          stepTitle: "Step 3",
          stepDescription: "Got offer letter Acceptance",
        },
        {
          id: 4,
          stepTitle: "Step 4",
          stepDescription: "Apply For Your Visa",
        },
      ],

      flagData : [
        {
          id: 1,
          logoImg: CanadaFlag,
          title: "Study in Canada"
        },
        {
          id: 2,
          logoImg: CountryFlag,
          title: "Study in UK"
        },
        {
          id: 3,
          logoImg: USAFlag,
          title: "Study in USA"
        },
        {
          id: 4,
          logoImg: AustraliaFlag,
          title: "Study in Australia"
        },
        {
          id: 5,
          logoImg: IreLandFlag,
          title: "Study in Ireland"
        },
      ],
      explorePopularFieldOfstudy: [
        {
          id: 1,
          imageURL: Engineering,
          title: "Engineering",
        },
        {
          id: 2,
          imageURL: Law,
          title: "Law",
        },
        {
          id: 3,
          imageURL: Science,
          title: "Science",
        },
        {
          id: 4,
          imageURL: Arts,
          title: "Arts",
        },
        {
          id: 5,
          imageURL: Buisness,
          title: "Buisness",
        },
        {
          id: 6,
          imageURL: EnglishLanguage,
          title: "English Language",
        },
        {
          id: 7,
          imageURL: Medicine,
          title: "Medicine",
        },
        {
          id: 8,
          imageURL: Economics,
          title: "Economics",
        },
      ],
      menuOptions: [
        {
          id: 1,
          title: "About us",
        },
      ],
      univercityLogo: [
        {
          id: 1,
          logoImg: UniversityLogo1,
        },
        {
          id: 2,
          logoImg: UniversityLogo2,
        },
        {
          id: 3,
          logoImg: UniversityLogo3,
        },
        {
          id: 4,
          logoImg: UniversityLogo4,
        },
        {
          id: 5,
          logoImg: UniversityLogo5,
        },
        {
          id: 6,
          logoImg: UniversityLogo6,
        },
        {
          id: 7,
          logoImg: UniversityLogo7,
        },
      ],
      applyBoardSection: [
        {
          id: 1,
          imageURL: ImageOne,
          title: "RBC International Student",
          subTitle:
            "Strengthen and streamline your study permit application with a GIC from RBC, Canadas largest bank.",
        },
        {
          id: 2,
          imageURL: ImageOne,
          title: "Students Loans Services",
          subTitle:
            "Strengthen and streamline your study permit application with a GIC from RBC, Canadas largest bank.",
        },
        {
          id: 3,
          imageURL: ImageOne,
          title: "Tuition Payments Made Easy",
          subTitle:
            "Strengthen and streamline your study permit application with a GIC from RBC",
        },
      ],
      countryName: [
        {
          id: 1,
          countryName: "United Kindom",
        },
        {
          id: 2,
          countryName: "Canada",
        },
        {
          id: 3,
          countryName: "United States",
        },
        {
          id: 4,
          countryName: "Australia",
        },
        {
          id: 5,
          countryName: "Ireland",
        },
      ],
      activeTab:"aboutUs",
      aboutUsData:[],
      studentData:[],
      institutionsData:[],
      UserRole: '',
    };
    // Customizable Area End
    }

  // Customizable Area Start
  onPartnerSchoolClick = () => {};
  async componentDidMount() {
    this.getContentManagement();
    this.getServices();
    this.getCountries()
    this.getAboutUsContent();
    this.getStudentContent();
    this.getInstitutionsContent()
    const getLandingActivePage = await getStorageData("LandingPageActive")
      this.renderScreenInLandingPage(getLandingActivePage)
      const UserRole = await getStorageData("role")
      this.setState({
        UserRole: UserRole,
      })
      const activeHeaderTab = await getStorageData("activeHeaderTab")
      this.setState({showSchool:activeHeaderTab})
  }

  filterData = (returnType:string,data:FormattedContentDataType[],place:string,contentType:string) => {
    if(returnType === "object"){
    return data.find(
      (format) =>
        format.attributes.place === place &&
        format.attributes.content_type === contentType
    )}
    if(returnType === "array"){
      return data.filter(
        (format) =>
          format.attributes.place === place &&
          format.attributes.content_type === contentType
      )
    }
  }
  
  filterBelowCardData = (id:number) => {
    if(this.state.activeId === id){
    this.setState({belowCardData:{...this.state.belowCardData,Agent:this.state.allCountryData},activeId:null})
    }else{
      const filteredData = Array.isArray(this.state.allCountryData) 
    ? this.state.allCountryData.filter((below)=>below.attributes.countryId === id)
    : []
    this.setState({belowCardData:{...this.state.belowCardData,Agent:filteredData},activeId:id})
    }
 }

  formatComponentData = (data: ContentDataType[]) => {
    const formatData = data.map((value) => {
      return {
        id: value.id,
        type: value.type,
        attributes: {
          title: value.attributes.title,
          description: value.attributes.description,
          place: value.attributes.place,
          url: value.attributes.url,
          content_type: value.attributes.content_type,
          service:value.attributes.service.service,
          countryId:value.attributes.university_country_id,
          countryName:value.attributes.country_name
        },
      };
    });

    const schoolBannerData = this.filterData("object",formatData,"Banner","student");
    const recruiterBannerData = this.filterData("object",formatData,"Banner","Agent");

    const schoolBelowData = this.filterData("array",formatData,"Below","student");
    const recruiterBelowData = this.filterData("array",formatData,"Below","Agent");

    const schoolCardData = this.filterData("array",formatData,"Middle (Service)","student");
    const recruiterCardData = this.filterData("array",formatData,"Middle (Service)","Agent");


    const studentBannerData = this.filterData("object",formatData,"Banner","Student");

    const studentBelowData = this.filterData("array",formatData,"Below (University)","Student");

    const studentCardData = this.filterData("array",formatData,"Middle (Service)","Student");


    this.setState({
      contentData: formatData,
      middleCardData: {...this.state.middleCardData,
        student: schoolCardData,
      },
      belowCardData:{
        student:schoolBelowData,
        Agent:recruiterBelowData,
      },
      allCountryData:recruiterBelowData,
      serviceData:recruiterCardData
    });
     this.tabSelected(this.state.allServices[0]?.id)
    if(!Array.isArray(schoolBannerData) && !Array.isArray(recruiterBannerData) && !Array.isArray(studentBannerData)){
      this.setState({
        bannerData: {
          school: schoolBannerData,
          Agent: recruiterBannerData,
        },
      })
    }
  };  
  formatServicesData = (data: ServiceDataType[]) => {
    const formatData = data.map((value) => {
      return {
        id: value.id,
        serviceName:value.attributes.service_type,
      };
    });
    this.setState({allServices:formatData})
      if (formatData.length > 0) {
        this.setState({selectedTab:formatData[0].id})
      }
  };

  showUniversityLogo = () => {
    this.setState({showAllUniversitylogo:!this.state.showAllUniversitylogo})
  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      const apiFunctionsMap = {
        [this.contentManagementCallApiId]: this.formatComponentData,
        [this.servicesCallApiId]: this.formatServicesData,
        [this.countryCallApiId]: this.setAllCountries,
        [this.getAboutUsDataApiCallId]:this.setAboutUsContent,
        [this.getStudentDataApiCallId]:this.setStudentData,
        [this.getInstitutionsCallId]:this.setInstitutionsContent,


        
      };
    
      const apiFunction = apiFunctionsMap[apiRequestCallId];
      
      if (apiFunction && responseJson && responseJson.data.length > 0) {
        apiFunction(responseJson.data);
      }
    }
  }

  setAllCountries = (allCountries:CountryDataType[]) => {
   this.setState({allCountryName:allCountries})
  }

  getContentManagement = async () => {
    const header = {
      "Content-Type": configJSON.contentManagementApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.contentManagementCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentManagementApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.contentManagementApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getServices = async () => {
    const header = {
      "Content-Type": configJSON.serviceApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.servicesCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.serviceApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountries = async () => {
    const header = {
      "Content-Type": configJSON.serviceApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.countryCallApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAboutUsContent = async () => {
    const header = {
      "Content-Type": configJSON.serviceApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAboutUsDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.aboutusApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getStudentContent=async()=>{
    const header = {
      "Content-Type": configJSON.serviceApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStudentDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.contentManagementApiStudent
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getInstitutionsContent = async () => {
    const header = {
      "Content-Type": configJSON.serviceApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getInstitutionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getInstitutionsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.serviceApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  navigateToLoginPage = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountLoginBlock"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
    
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  navigateToRegistration = () => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "EmailAccountRegistration"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  onHomeClick = (pageName:string) => {
    this.renderScreenInLandingPage(pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  tabSelected = (selectedTab:string) => {
    this.setState({selectedTab:selectedTab})
    const getFilterData = (this.state.serviceData && Array.isArray(this.state.serviceData)) ? this.state.serviceData.filter((service)=> service.attributes.service?.id 
    && (Number(service.attributes.service?.id) === Number(selectedTab))) : []
    this.setState({middleCardData:{...this.state.middleCardData,Agent:getFilterData}})
  };
  
  schoolPageTabSelected =(selectedTab:string) => {
    this.setState({selectedSchoolTab:selectedTab})
  }
  navigateToAnyPage = (navigateTo:string) => {
    this.props.navigation.navigate(navigateTo)
  };
  renderSchool = () => {
    setStorageData("LandingPageActive","school")  
    this.setState(() => ({
      showSchool: "school",
      toggleDrawer: false
    }));
  };

  renderRecruitmentPartner = () => {
    setStorageData("LandingPageActive","RecruitmentPartner")  
    this.setState(() => ({
      showSchool: "RecruitmentPartner",
      toggleDrawer: false
    }));
  };

  renderButtonClick=(value:string)=>{
    if(value === "contact"){
      this.props.navigation.navigate("Contactus",{showSchool:value})
    }
    this.setState({ showSchool: value });
  }

  renderScreenInLandingPage = (value:string) => {
    if(value){
      this.setState(() => ({
        showSchool: value,
      }));
    }
  }

  setAboutUsContent = (responseJson:AboutUsData[]) =>{
    this.setState({
      aboutUsData:responseJson
    })
  }

  onSignUpClick?: () => void;
  onLoginClick?: () => void;
  clickOnLogin = () => {
    if(this.onLoginClick){
      this.onLoginClick();
      setStorageData("LandingPageActive","notActive")
    }
  };

  clickOnRegister = () => {
    if(this.onSignUpClick){
    this.onSignUpClick();
    setStorageData("LandingPageActive","notActive")
  }
  };
  setStudentData = (responseJson: StudentDataContent[]) => {
    this.setState({
      studentData: responseJson
    });
  }
  

  setInstitutionsContent = (responseJson:InstitutionData[])=>{
    this.setState({institutionsData:responseJson})
  }

  // Customizable Area End
}