import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconReport extends Component<IconPropsType> {

    render() {
        return (

            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 19.5H3V4.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.5 6L12 13.5L9 10.5L3 16.5" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M19.5 9.75V6H15.75" stroke="#292D32" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        )
    }
}



