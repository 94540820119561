// Customizable Area Start
import React from "react";
import {
  Modal,
  Container,
  Box,
  Button,
  Table,
  TableHead,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  Typography,
  Input,
  Grid,
  MenuItem,
  TextField,
  Select,
  Snackbar
} from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import AppHeader from "../../../components/src/AppHeader.web";
import Footer from "../../../components/src/Footer.web";
import ContactusController, { CountryCodeObj, Props, configJSON } from "./ContactusController";
import * as IMG_CONST from "./assets";
import Alert from "@material-ui/lab/Alert";
import PhoneInput from "react-phone-input-2";
import { Formik } from "formik";
// Customizable Area End
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Contactus extends ContactusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderUpperTxt = () => {
    return (
      <UpperBoxMain >
        <UpperInnerBox>
          <ContactTxt data-test-id="contactUsText">
            {configJSON.contactTxt}
          </ContactTxt>
          <TouchTxt >
            {configJSON.touchTxt}
          </TouchTxt>
        </UpperInnerBox>
      </UpperBoxMain>
    );
  };

  errorCounter = (errors:string | undefined, touched:string | boolean | undefined) =>{
    return errors && touched && (
        <RequiredTxt>{errors}</RequiredTxt>
      )
  }

  renderForm = () => {
    return (
      <Formik
        initialValues={{
          firstName: this.state.firstName ||"",
          lastName:  this.state.lastName  ||"",
          emailValue:  this.state.email  ||"",
          countryValue:  this.state.countryValue  ||"",
          cityValue:  this.state.cityValue  ||"",
          messageValue:  this.state.messageValue  ||"",
          contactNumber:  this.state.contactNumber  ||"",
        }}
        validationSchema={this.ContactUsSchema}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={() => {
          this.postFormApi()
        }}
      >{
          ({ handleSubmit, setFieldValue, handleBlur, errors, values, touched }) => {
            return (
              <form data-test-id="ContactUSForm" onSubmit={handleSubmit} autoComplete="off">
                <FormMainBox >
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={4} md={4} sm={6}>
                      <Box>
                        <InputTxt>
                          {configJSON.firstnameTxt}
                        </InputTxt>
                        <InputField
                          placeholder={configJSON.firstnameTxtPlaceholder}
                          onChange={(e)=>{
                            this.handelTextFieldSet(e,setFieldValue)
                          }}
                          value={this.state.firstName}
                          name={'firstName'}
                          data-test-id="firstNameTestId"
                          onBlur={handleBlur}
                        />
                        {this.errorCounter(errors.firstName ,touched.firstName)}
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4} sm={6}>
                      <Box>
                        <InputTxt>
                          {configJSON.lastnameTxt}
                        </InputTxt>
                        <InputField
                        onChange={(e)=>{
                          this.handelTextFieldSet(e,setFieldValue)
                        }}
                          placeholder={configJSON.lastnameTxtPlaceholder}
                          value={this.state.lastName}
                          name="lastName"
                          onBlur={handleBlur}
                          data-test-id="lastNameTestId"
                        />
                        {this.errorCounter(errors.lastName ,touched.lastName)}
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4} sm={6}>
                      <Box>
                        <InputTxt>
                          {configJSON.emailTxt}
                          <StarTxt> {configJSON.starTxt}</StarTxt>
                        </InputTxt>
                        <InputField
                          onChange={(e)=>{
                            this.handelTextFieldSet(e,setFieldValue)
                          }}
                          placeholder={configJSON.emailTxtPlaceholder}
                          value={this.state.emailValue}
                          data-test-id="emailTestId"
                          name="emailValue"
                          onBlur={handleBlur}
                        />
                        {this.errorCounter(errors.emailValue ,touched.emailValue)}
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4} sm={6}>
                      <Box>
                        <InputTxt>
                          {configJSON.contactNumberTxt}
                          <StarTxt> {configJSON.starTxt}</StarTxt>
                        </InputTxt>
                        <ContactInput
                          data-test-id="contactNumberTestId"
                          value={this.state.contactNumber}
                          placeholder={configJSON.contactNumberTxtPlaceholder}
                          onChange={(event, country: CountryCodeObj) => { this.handleContactNumberChange(event, country, setFieldValue) }}
                          inputProps={{
                            required: true
                          }}
                          country={"in"}
                          onBlur={handleBlur}
                          enableSearch={true}
                          disableSearchIcon={true}
                          jumpCursorToEnd={true}
                          countryCodeEditable={false}
                        />
                        {this.errorCounter(errors.contactNumber ,touched.contactNumber)}
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4} sm={6}>
                      <Box>
                        <InputTxt>
                          {configJSON.whichCountryTxt}
                          <StarTxt> {configJSON.starTxt}</StarTxt>
                        </InputTxt>
                        <SelectInput
                          displayEmpty
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            disableScrollLock: true,
                          }}
                          style={{
                            color: this.state.countryValue == "none" ? "#999" : "#000",
                          }}
                          value={this.state.countryValue}
                          data-test-id="countryDataTestId"
                          onChange={(e)=>{
                            this.handleCountryChange(e,setFieldValue)
                          }}
                          onBlur={handleBlur}
                        >
                          {this.state.countryValue == "" && (
                            <MenuItem disabled value="">
                              {configJSON.selectCountryPlaceholderTxt}
                            </MenuItem>
                          )}
                          {this.state.countryData.map((item) => (
                            <MenuItem value={item.isoCode}>{item.name}</MenuItem>
                          ))}
                        </SelectInput>
                        {this.errorCounter(errors.countryValue ,touched.countryValue)}
                      </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} md={4} sm={6}>
                      <Box>
                        <InputTxt>
                          {configJSON.cityText}
                          <StarTxt> {configJSON.starTxt}</StarTxt>
                        </InputTxt>
                        <InputField
                          placeholder={configJSON.cityText}
                          value={this.state.cityValue}
                          data-test-id="cityDataTestId"
                          name="cityValue"
                          onChange={(e)=>{
                            this.handelTextFieldSet(e,setFieldValue)
                          }}
                          onBlur={handleBlur}
                        />
                        {this.errorCounter(errors.cityValue ,touched.cityValue)}
                      </Box>
                    </Grid>
                  </Grid>
                  <MessageBox >
                    <InputTxt>
                      {configJSON.messageText}
                      <StarTxt> {configJSON.starTxt}</StarTxt>
                    </InputTxt>
                    <InputFieldMultiline
                      multiline
                      placeholder={configJSON.messageTextPlaceholder}
                      value={this.state.messageValue}
                      data-test-id="messageTestId"
                      name="messageValue"
                      onChange={(e)=>{
                        this.handelTextFieldSet(e,setFieldValue)
                      }}
                      onBlur={handleBlur}
                    />
                    {this.errorCounter(errors.messageValue ,touched.messageValue)}
                  </MessageBox>
                  <SubmitButton
                    data-test-id="onSubmitTestID"
                    // onClick={this.onSubmit}
                    type="submit"
                  >{configJSON.submitBtnTxt}</SubmitButton>
                </FormMainBox>
              </form>
            )
          }
        }

      </Formik>
    );
  };

  renderMap = () => {
    return (
      <Box>
        <MainMapBox >
          <GlobalTxt>{configJSON.globalTxt}</GlobalTxt>
          <SelectInputMap
            displayEmpty
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              getContentAnchorEl: null,
            }}
            style={{
              color: this.state.countryMapValue == "none" ? "#999" : "#000",
            }}
            value={this.state.countryMapValue}
            data-test-id="countryDataMapTestId"
            onChange={this.handleCountryMapChange}
          >
            {this.state.countryValue == "none" && (
              <MenuItem disabled value="none">
                {configJSON.selectCountryPlaceholderTxt}
              </MenuItem>
            )}
            {this.state.countryMapData.map((item, index: number) => (
              < MenuItem key={index} value={item as never} > {item.name}</MenuItem>
            ))}
          </SelectInputMap>
        </MainMapBox>
      </Box>)
  }

  renderSuccess = () => {
    return (
      <SuccessMainBox >
        <ImageCenter>
          <CenterImg src={IMG_CONST.contactusImg} />
        </ImageCenter>
        <ImageContent>
          <ThanksTxt data-test-id="thanksTxt">{configJSON.ThanksTxt}</ThanksTxt>
        </ImageContent>
        <ImageContent>
          <AppreciateTxt >{configJSON.AppreciateTxt}
          </AppreciateTxt>
        </ImageContent>
      </SuccessMainBox>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <AppHeader isLogin={true}
          data-test-id="appHeaderTestId"
          onLoginClick={this.navigateToLoginPage}
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
          showScool="contact"
          renderButtonClick={this.renderButtonClick}
        />
        <Snackbar
          open={this.state.isAlert}
          autoHideDuration={3000}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.oncloseAlert}
          data-test-id="alertTestId"
        >
          <Alert severity={this.state.alertType} data-test-id="alertsTestId">{this.state.alertMsg}</Alert>
        </Snackbar>
        {
          this.state.isSucces ?
            this.renderSuccess() :
            <>
              {this.renderUpperTxt()}
              {this.renderForm()}
            </>
        }
        <Footer renderButtonClick={this.renderButtonClick} onAnyPageNavigate={this.navigateToAnyPage}/>
      </Box>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

const SubmitButton = styled(Button)({
  backgroundColor: "#34D399", color: "#fff",
  fontSize: "14px", textTransform: "capitalize",
  fontFamily: "Plus Jakarta Sans", padding: "5px 20px",
  alignSelf: "center", margin: "50px 0px 70px 0px",
  "&.MuiButton-root:hover": {
    backgroundColor: "#34D399"
  }
});

const UpperBoxMain = styled(Box)({
  justifyContent: "center", display: "flex", flexDirection: "column",
  alignItems: "center",
  margin: "50px"
});

const UpperInnerBox = styled(Box)({
  justifyContent: "center", display: "flex",
  flexDirection: "column", alignItems: "center",
  maxWidth: "650px", minWidth: "200px"
});


const ContactTxt = styled(Typography)({
  fontSize: "40px", fontWeight: 700,
  fontFamily: "Plus Jakarta Sans", textAlign: "center",
  "@media (max-width: 768px)": {
    fontSize: "30px",

  },
  "@media (max-width: 500px)": {
    fontSize: "25px",
  }
});

const TouchTxt = styled(Typography)({
  fontSize: "16px", textAlign: "center", fontFamily: "Plus Jakarta Sans"
});

const InputTxt = styled(Typography)({
  color: "#334155", fontWeight: 600,
 fontSize: "14px", fontFamily: "Plus Jakarta Sans"
});

const InputField = styled(TextField)({
  width: "100%", border: "1px solid #CBD5E1",
  borderRadius: "8px", height: "50px",
  margin: "5px 0px", display: "flex",
  justifyContent: "center", padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  }
});

const ContactInput = styled(PhoneInput)({
  "&.react-tel-input .form-control": {
    width: "100%",
    border: "1px solid #CBD5E1",
    height: "50px",
    margin: "5px 0px !important",
    display: "flex",
    borderRadius: "8px",
    justifyContent: "center",
  },
  "&.react-tel-input .flag-dropdown": {
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px"
  },
  "& .flag-dropdown.open .selected-flag:hover": {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent"
    }
  }
}
);
const FormMainBox = styled(Box)({
  margin: "0px 100px", display: "flex",
  flexDirection: "column",
  "@media (max-width: 768px)": {
    margin: "0px 70px",
  },
  "@media (max-width: 500px)": {
    margin: "0px 40px"
  }
})

const InputFieldMultiline = styled(TextField)({
  width: "100%", border: "1px solid #CBD5E1",
  borderRadius: "8px", minHeight: "100px", margin: "5px 0px",
  padding: "0px 10px", boxSizing: "border-box",
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  }
});

const MenuItemDisable = styled(MenuItem)({
  display: "none",
  "&.MuiSelect-select": {
    color: "red"
  }
});

const SelectInput = styled(Select)({
  width: "100%", borderRadius: "8px",
  height: "50px", border: "1px solid #CBD5E1",
  margin: "5px 0px", justifyContent: "center",
  display: "flex", padding: "0px 10px", boxSizing: "border-box",
  "& .MuiSelect-icon": {
    right: "5px",
    color: "#0E172A"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::after": {
      content: "none"
    },
    "&::before": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const RequiredTxt = styled("span")({
  color: "red",
  fontFamily: "Plus Jakarta Sans",
  fontSize: "15px"
})

const SelectInputMap = styled(Select)({
  width: "300px", display: "flex",
  border: "1px solid #CBD5E1", borderRadius: "8px",
  margin: "5px 0px", justifyContent: "center",
  padding: "0px 10px", height: "50px",
  boxSizing: "border-box", backgroundColor: "#fff",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&::before": {
      content: "none"
    },
    "&:focus": {
      outline: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const MapBox = styled(Box)({
  "& .gm-style": {
    display: "none"
  },
  "& .place-card-large": {
    display: "none"
  }
});

const MainMapBox = styled(Box)({
  padding: "50px 100px",
  backgroundColor: "rgba(203, 213, 225, 0.3)",
  display: "flex",
  justifyContent: "space-between",
  "@media (max-width: 1200px)": {
    padding: "40px 80px"
  },
  "@media (max-width: 992px)": {
    padding: "30px 60px"
  },
  "@media (max-width: 768px)": {
    padding: "20px 40px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  "@media (max-width: 576px)": {
    padding: "10px 20px"
  }
});

const MapBoxMain = styled(Box)({
  "@media (max-width: 768px)": {
    padding: "50px 50px"
  },
  "@media (max-width: 500px)": {

  }
});

const GlobalTxt = styled(Typography)({
  maxWidth: "200px", minWidth: "100px",
  fontWeight: "bold", fontSize: "30px", fontFamily: "Plus Jakarta Sans"
});

const MessageBox = styled(Box)({
  margin: "15px 0px 0px 0px"
});

const ImageCenter = styled(Box)({
  display: "flex", justifyContent: "center",
  alignContent: "center", alignItems: "center", fontSize: "20px"
});

const CenterImg = styled("img")({
  width: "450px", height: "450px",
  "@media (max-width: 768px)": {
    width: "400px", height: "400px"
  },
  "@media (max-width: 500px)": {
    width: "200px", height: "200px"
  }
});

const ImageContent = styled("div")({
  display: "flex", justifyContent: "center",
  alignContent: "center", alignItems: "center", rowGap: "0"
});

const ThanksTxt = styled(Typography)({
  fontWeight: "bold", fontSize: "40", margin: "20px 0px",
  textAlign: "center", fontFamily: "Plus Jakarta Sans"
});

const AppreciateTxt = styled(Typography)({
  fontSize: "16px", width: "800px",
  textAlign: "center", fontFamily: "Plus Jakarta Sans"
});

const SuccessMainBox = styled(Box)({
  padding: "100px"
});

const StarTxt = styled("span")({
  color: "red", fontFamily: "Plus Jakarta Sans"
});
// Customizable Area End
