import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
  ActivityIndicator,
} from "react-native";
import CheckBox from "../../../components/src/CustomCheckBox";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { FlatList } from "react-native-gesture-handler";
import moment from "moment";
import { Box, Typography, styled } from "@material-ui/core";
import AppHeader from "../../../components/src/AppHeader.web";
import Footer from "../../../components/src/Footer.web";
import { TermsAndConditionData } from "./TermsConditionsController";
// Customizable Area End

import TermsConditionsController, {
  Props,
  configJSON,
  ITermsConds,
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box >
        <AppHeader
        navigation={this.props.navigation}
          isLogin={true}
          data-test-id="appHeaderTestId"
          onLoginClick={this.navigateToLoginPage}
          onSignUpClick={this.navigateToRegistration}
          handleToggle={this.handleToggle}
          toggleData={this.state.toggleDrawer}
          onHomeClick={this.onHomeClick}
          isUserLogin={false}
          userName={undefined}
          role={undefined}
        />
        <InnerBox >
          <HeaderTxt align="center" data-test-id="headerTxtId">{this.state.termsAndConditionWeb?.meta?.message}</HeaderTxt>
          {this.state.termsAndConditionWeb?.data?.map((item: TermsAndConditionData) => {
            return <Typography
              className="contentText"
              dangerouslySetInnerHTML={{
                __html: item?.attributes?.description
              }}
            >
            </Typography>
          })
          }
        </InnerBox>
      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area Start

const HeaderTxt = styled(Typography)(({ theme }) => ({
  fontFamily: "Plus Jakarta Sans",
  fontSize: "48px",
  lineHeight: 1,
  marginBottom: "30px",
  fontWeight: 700,
  [theme.breakpoints.down("md")]: {
    fontSize: "40px"
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "30px"
  }
}));

const InnerBox = styled(Box)({
  padding: "70px",
  "& .contentText": {
    fontFamily: "Plus Jakarta Sans"
  }
});
// Customizable Area End
