// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
const navigation = require("react-navigation")
import { Country, State } from 'country-state-city';
import { Color } from "@material-ui/lab/Alert";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IBlock } from "../../../framework/src/IBlock";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    id: string,
    navigation: typeof navigation;
    // Customizable Area End
}

// Customizable Area Start
interface NoEmpInterfaceData {
    id: string;
    title: string;
}

export interface SelectEventType {
    target: {
        value: string | unknown;
    }
}

interface CountryInterfaceData {
    name: string;
    ICountry: [];
    isoCode: string;
    flag: string;
    phonecode: string;
    currency: string;
    latitude: string;
    longitude: string;
    timezones: {
        zoneName: string;
        gmtOffset: string;
        gmtOffsetName: string;
        abbreviation: string;
        tzName: string;
    };
}

interface StateInterfaceData {
    name: string;
    isoCode: string;
    countryCode: string;
    latitude: string;
    longitude: string;
}

interface CityInterfaceData {
    id: string;
    title: string;
}

interface FilteredData {
    name: string;
}

interface APIPayloadType {
    contentType?: string;
    method: string;
    endPoint: string;
    body?: object;
    token?: string;
    type?: string;
}

interface ValidResponseType {
    message: object;
    data: object;
}

interface InvalidResponseType {
    errors: Array<{ [key: string]: string }>
}
// Customizable Area End

interface S {
    // Customizable Area Start
    companyName: string;
    email: string;
    numberOfEmployees: string | unknown;
    countryValue: string | unknown;
    stateValue: string | unknown;
    cityName: string;
    companyError: boolean;
    companyErrorTxt: string;
    emailError: boolean;
    emailErrorTxt: string;
    countryError: boolean;
    countryErrorTxt: string;
    numberOfEmployeesError: boolean;
    numberOfEmployeesErrorTxt: string;
    stateError: boolean;
    stateErrorTxt: string;
    cityNameError: boolean;
    cityNameErrorTxt: string;
    isChecked: boolean;
    noEmpData: Array<NoEmpInterfaceData>;
    countryData: Array<CountryInterfaceData>;
    stateData: Array<FilteredData>;
    cityData: Array<CityInterfaceData>;
    isPswrd: boolean;
    isAlert: boolean;
    alertMsg: string;
    alertType: Color;
    role: string | unknown;
    isCheckError: boolean;
    isCheckErrorTxt: string;
    toggleDrawer: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    singupAPICallID: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.state = {
            companyName: "",
            email: "",
            numberOfEmployees: "none",
            role: "agent",
            countryValue: "none",
            stateValue: "none",
            cityName: "",
            companyError: false,
            companyErrorTxt: "",
            countryError: false,
            countryErrorTxt: "",
            cityNameError: false,
            stateError: false,
            stateErrorTxt: "",
            cityNameErrorTxt: "",
            emailError: false,
            isChecked: false,
            numberOfEmployeesError: false,
            numberOfEmployeesErrorTxt: "",
            isCheckError: false,
            isCheckErrorTxt: "",
            noEmpData: [
                { id: "1", title: "0-10" },
                { id: "2", title: "10-100" },
                { id: "3", title: "100-500" }
            ],
            countryData: Country.getAllCountries() as never,
            stateData: [],
            cityData: [{ id: "1", title: "Indore" }],
            emailErrorTxt: "",
            isPswrd: false,
            isAlert: false,
            alertMsg: "",
            alertType: "success",
            toggleDrawer: false
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    // Customizable Area Start
    handleRole = (event: SelectEventType) => {
        this.setState({ role: event.target.value });
    };

    onHomeClick = (pageName:string) => {
        setStorageData("LandingPageActive",pageName)  
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "LandingPage"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
      };

    handleCompanyNameChange = (event: { target: { value: string; }; }) => {
        const companyName = event.target.value.trim();
        if (companyName === "") {
            this.setState({
                companyName: "",
                companyError: true,
                companyErrorTxt: "Company is required."
            });
        } else {
            this.setState({
                companyName: companyName,
                companyError: false,
                companyErrorTxt: ""
            });
        }
    };

    handleToggle = () => {
        this.setState({
            toggleDrawer: !this.state.toggleDrawer
        });
    };

    handleEmailChange = (event: { target: { value: string; }; }) => {
        const email = event.target.value;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
        if (email === "") {
            this.setState({
                emailError: true,
                emailErrorTxt: "Email is required.",
                email: email
            });
        } else if (!emailRegex.test(email)) {
            this.setState({
                emailError: true,
                emailErrorTxt: "Please enter a valid email address.",
                email: email
            });
        } else {
            this.setState({
                emailError: false,
                emailErrorTxt: "",
                email: email
            });
        }
    };

    handleNumberOfEmployeesChange = (event: SelectEventType) => {
        this.setState({ numberOfEmployees: event.target.value, numberOfEmployeesError: false });
    };

    handleCountryChange = (event: SelectEventType) => {
        const statedata = State.getAllStates()
        this.setState({ countryValue: event.target.value }, () => {
            const filteredData = statedata.filter((data: { countryCode: string; }) => data.countryCode === event.target.value);
            if (filteredData.length === 0) {
                this.setState({
                    stateData: [{
                        name: "N/A"
                    }],
                    countryErrorTxt: "",
                    countryError: false
                })
            }
            else this.setState({
                stateData: filteredData,
                countryErrorTxt: "",
                countryError: false
            })
        }
        )
    };

    handleStateChange = (event: SelectEventType) => {
        this.setState({ stateValue: event.target.value, stateError: false });
    };

    handleCityChange = (event: { target: { value: string; }; }) => {
        const cityName = event.target.value;
        if (cityName === "") {
            this.setState({
                cityName: "",
                cityNameError: true,
                cityNameErrorTxt: "City is required."
            });
        } else {
            this.setState({
                cityName: cityName,
                cityNameError: false,
                cityNameErrorTxt: ""
            });
        }
    };

    handleChange = () => {
        this.setState({
            isChecked: !this.state.isChecked
        });
    };

    onSignupClick = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,4}$/;
        let isCheck = true
        if (this.state.companyName.length === 0) {
            isCheck = false
            this.setState({ companyError: true, companyErrorTxt: "Company is required." });
        }
        if (this.state.email.length === 0) {
            isCheck = false
            this.setState({ emailError: true, emailErrorTxt: "Email is required." })
        }
        else if (!emailRegex.test(this.state.email)) {
            isCheck = false
            this.setState({ emailError: true, emailErrorTxt: "Please enter a valid email address." })
        }
        if (this.state.numberOfEmployees === "none") {
            isCheck = false
            this.setState({ numberOfEmployeesErrorTxt: "Please select the number of employees.", numberOfEmployeesError: true });
        }
        if (this.state.countryValue === "none") {
            isCheck = false
            this.setState({
                countryErrorTxt: "Please select the country.",
                countryError: true
            })
        }
        if (this.state.stateValue === "none") {
            this.setState({
                stateErrorTxt: "Please select the state.",
                stateError: true
            })
        }
        if (this.state.cityName === "") {
            isCheck = false
            this.setState({
                cityNameErrorTxt: "City is required.",
                cityNameError: true
            })
        }
        if (!this.state.isChecked) {
            isCheck = false
            this.setState(
                {
                    isCheckError: true,
                    isCheckErrorTxt: "Please agree privacy policy and terms and condtions."
                });
        }
        if (this.state.isChecked) {
            this.setState(
                {
                    isCheckError: false,
                    isCheckErrorTxt: ""
                });
        }
        if (isCheck) {
            this.signupApi()
        }
    }

    oncloseAlert = () => {
        this.setState({ isAlert: false });
    };

    navigateToLoginPage = () => {
        const navigation = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
    };

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            let apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            if (this.isValidResponse(responseJson)) {
                this.apiSucessCall(apiRequestCallId)
            }
            if (responseJson && responseJson.errors) {
                this.apiFailureCall(apiRequestCallId, responseJson)
            }
        }
    }

    apiCall = async (data: APIPayloadType) => {
        const { contentType, method, body, endPoint } = data;
        const header = { "Content-Type": contentType, };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    inValidResponse = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };

    isValidResponse = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data);
    };

    apiSucessCall = async (apiRequestCallId: string,) => {
        if (apiRequestCallId === this.singupAPICallID) {
            this.signupSuccesCallBack();
        }
    };

    apiFailureCall = async (apiRequestCallId: string, responseJson: InvalidResponseType) => {
        if (apiRequestCallId === this.singupAPICallID) {
            this.signupFailureCallBack(responseJson);
        }
    };

    signupSuccesCallBack = () => {
        this.setState({ isPswrd: true });
    };

    signupFailureCallBack = (responseJson: InvalidResponseType) => {
        const errorKey: string = Object.keys(responseJson.errors[0])[0];
        this.setState({
            isAlert: true,
            alertMsg: responseJson.errors[0][errorKey],
            alertType: "error"
        })
    };

    signupApi = async () => {
        const body = {
            data: {
                attributes: {
                    email: this.state.email,
                    verification_type: "email_account",
                    role: this.state.role,
                    company_name: this.state.companyName,
                    no_of_employees: this.state.numberOfEmployees,
                    country: this.state.countryValue,
                    state: this.state.stateValue,
                    city: this.state.cityName
                }
            }
        }
        this.singupAPICallID = await this.apiCall({
            contentType: configJSON.signupContentType,
            method: configJSON.signupApiMethod,
            endPoint: configJSON.signupEndPoint,
            body: body
        });
    };

    navigateToTermsConditionPage = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "TermsConditionsWeb"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
      };
      navigateToPrivacyPolicyPage = () => {
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(
          getName(MessageEnum.NavigationTargetMessage),
          "PrivacyPolicyWeb"
        );
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
      };
    // Customizable Area End
}