Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.labelBtnTextSchool="School";
exports.labelbtnRecruitmentPartner="Recruitment Partner";
exports.btnTextLogin="Log In";
exports.btnTextRegistration="Register";
exports.btnExampleTitle = "CLICK ME";
exports.btnLabelLearnMore="Learn More"
exports.textHerosectionTitle = "We Are on a Mission to Educate the World";
exports.textHeroSectionSubTitle ="Increase your global presence and the number of qualified students from a single, easy-to-use platform trusted by more than 1,750 institutions worldwide.";
exports.btnTitle1="Get personalized Study Abroad Options";
exports.btnTitle2="I am a Recruitment Partner";
exports.labelTitleSection2="A Platform That Supports You End-to-End"
exports.btnTitle3="I work at school";
exports.labelPartnerschool="Partner school";
exports.labelBtnStudents="Students";
exports.labelBtnPartnerSchool="Partner school"
exports.labelTextHeadingSection2="Your Successis Our Success – We’re Here to See You Grow";
exports.labelTextSubHeadingSection2="Vision International School is more than a platform. It’s your trusted partner to help you do what you do best: help as many students as possible fulfill their international education dreams.";
exports.btnTextPartnerWithUS="Partner with Us";
exports.textlabelString2="Get Started With Vision International School";
exports.textlabelString4="We Help Students Get Admitted Into the Best International Educational Institutions";
exports.textlabelString3="Explore Popular Universities and Colleges";
exports.commanText="Are you a student looking to study abroad in Canada, the United States, the United Kingdom, Australia, or Ireland? Let our team of experts guide you through your journey.";
exports.textLabelString="We Connect Schools, Students, and Recruitment Partners from every part of  the world";
exports.textValue1="6,00,000+";
exports.textValue2="1,00,000+";
exports.textValue3="10,000+";
exports.textValue4="1,000+";
exports.labeltitleExploreInstitution="We Connect You to the Right School for Your Students";
exports.btnTyitToday= "Try it today";
exports.textLabel1="Everything you need  exclusive in one place";
exports.btnUnitedKindom="United Kindom";
exports.btncanada="Canada";
exports.btnShowMore="Show more";
exports.textLabelStudentHelped="Student Helped";
exports.textLabelProgram="Programs"
exports.labelTextHero="Search in Seconds. Find Your Dream Program in Minutes.";
exports.labelTextHeroSmall="Increase your global presence and the number of qualified students from a single, eas-to-use platform trusted by more than 1,750 institutions worlwide,"
exports.textLabel="Achieve Your Study Abroad Dreams";
exports.btnLabelExplorenow="Explore now";
exports.btnLabelExploreInstitutions="Explore Institutions";
exports.labelExplorePopularfieldofStudy="Explore Popular Fields of Study";
exports.btnLabelLearMore="Learn more";
exports.btnTextFinanceServices="Finance Services";
exports.btnTextVisaServices="Visa Services";
exports.btnTextLanguageTestSercives ="Language Test Services"
exports.textLabelReadyTolaunchStudy="Ready to launch study abroad journy? it can be intimidation but we're gere follow in the steps of 600,000+ other students and trust our exports to guide your way .";
exports.btnLabelGetPersionalizedStudyAbrooadOption="Get personalized Study Abroad Option";
exports.everythingInOnePlace="Everything you need in one place";
exports.textlabel11="Explore exclusive program to help you save time and money.With Vision International Educational Consultants, you're never far from a helping hand."

exports.contentManagementApiContentType = "application/json";
exports.contentManagementApiMethod = "GET";
exports.contentManagementApiEndPoint = "/bx_block_content_management/content_managements";
exports.contentManagementApiStudent= "/bx_block_content_management/content_managements/student_content"

exports.serviceApiContentType = "application/json";
exports.serviceApiMethod = "GET";
exports.serviceApiEndPoint = "/bx_block_content_management/services/";
exports.countryApiEndPoint = "/bx_block_content_management/university_country";
exports.aboutusApiEndPoint = "/bx_block_content_management/about_us";
exports.ourImpact = "Our Impact";
exports.contactUsTxt = "Contact Us";
exports.ourServices = "Our Services";
exports.ourValuesTxt = "Our Values";
exports.ourValuesDesc = "Our consultants include former educators, admissions officers, and industry experts who bring a wealth of knowledge and insights to every client we serve."
exports.getInstitutionsEndPoint = "/bx_block_content_management/institution";
exports.instituteTxt = "Why should Institutions join us?"
// Customizable Area End