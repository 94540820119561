// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { IBlock } from "framework/src/IBlock";
import { runEngine } from "framework/src/RunEngine";
import { Message } from "framework/src/Message";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { createRef } from "react";
// Customizable Area End

export interface Props {
  navigation: any;
  // Customizable Area Start
  isAdmin?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  confirmPassword: string;
  isPasswordShow: boolean;
  isConfirmPasswordShow: boolean;
  loading: boolean;
  token: null | string;
  toggleDrawer: boolean;
  userToken: string,
  CardDetailsData: Array<{
    id: string | number,
    heading: string,
    AnnouncementType: string,
    ContryName: Array<string>
    points: string,
    imageUrl: string,
    PublishDate: string,
  }>,
  page: number,
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: {
        url: string;
      },
    },
  },
  valueKey: string;
  updateModalOpen: boolean,
  updateValue: string,
  startDate: null | Date,
  endDate: null | Date,
  formattedStartDate: string,
  formattedEndDate: string,
  HideFirstCell: boolean,
  ExpendProgress: number,
  FilterModelOpen: boolean,
  ApplicationTableData: Array<{
    Student: string,
    Associatedbranch: string,
    CreatedBy: string,
    University: string,
    Program: string
  }>,
  FilterObject: {
    Student: string,
    Associatedbranch: string,
    CreatedBy: string,
    University: string,
    Program: string
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: string;
  // Customizable Area End
}
// Customizable Area Start

interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}

interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  };
}

interface APIPayloadType {
  body?: object;
  contentType?: string;
  method: string;
  endPoint: string;
  token?: string;
  type?: string;
}

export const configJSON = require("./config");

// Customizable Area End

export default class AllAplicationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProfileAPICallId: string = "";
  getLatestUpdateAPICallId: string = "";
  filterLatestUpdatedByDateApiId: string = ""
  TableRef: React.RefObject<HTMLDivElement>;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.TableRef = createRef<HTMLDivElement>();

    // Customizable Area Start
    this.state = {
      password: "",
      confirmPassword: "",
      isPasswordShow: false,
      isConfirmPasswordShow: false,
      loading: false,
      token: null,
      toggleDrawer: false,
      userToken: '',
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
      CardDetailsData: [],
      page: 1,
      valueKey: '',
      updateModalOpen: false,
      updateValue: '',
      startDate: null,
      endDate: null,
      formattedStartDate: "",
      formattedEndDate: "",
      HideFirstCell: false,
      ExpendProgress: -1,
      FilterModelOpen: false,
      ApplicationTableData: [
        {
          Student: "1",
          Associatedbranch: "1",
          CreatedBy: "1",
          University: "1",
          Program: "1"
        },
        {
          Student: "2",
          Associatedbranch: "2",
          CreatedBy: "2",
          University: "2",
          Program: "2"
        },
        {
          Student: "3",
          Associatedbranch: "3",
          CreatedBy: "3",
          University: "3",
          Program: "3"
        },
      ],
      FilterObject: {
        Student: "",
        Associatedbranch: "",
        CreatedBy: "",
        University: "",
        Program: ""
      }
    };

    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getProfileAPICallId) {
          this.getProfileFormSuccessCallBack(responseJson)
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const token = await getStorageData("token")
    this.setState({ userToken: token }, () => {
      this.getFormApprovalProfile()
    })
  }



  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  ApplicationApiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageApplicationApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageApplicationApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageApplicationApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageApplicationApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageApplicationApiCall.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageApplicationApiCall.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );

    runEngine.sendMessage(requestMessageApplicationApiCall.id, requestMessageApplicationApiCall);

    return requestMessageApplicationApiCall.messageId;
  };


  getProfileFormSuccessCallBack = (responseJson: UserDetailsResponse) => {
    this.setState({ userDetails: responseJson.data });
  };

  getFormApprovalProfile = async () => {
    let userDetails = await getStorageData("userDetails")
    this.getProfileAPICallId = await this.ApplicationApiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.getProfileEndPoint + userDetails
    });
  }

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      pageName
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  StperData = () => {
    let data = ['Application Fees Paid', 'Application', 'Submitted to Institution', 'Conditional Offer', 'Unconditional Offer', 'Tuition Fees Paid', 'Pre-CAS/LOA Stage', 'CAS/LOA Applied', 'CAS/LOA Received', 'Visa Applied', "Visa Received"]
    return data
  }

  handleScroll = () => {
    if (this.TableRef.current) {
      const { scrollLeft } = this.TableRef.current;
      if (scrollLeft > 100) {
        if (!this.state.HideFirstCell) {
          this.setState({
            HideFirstCell: true
          })
        }
      }
      if (scrollLeft < 100) {
        this.setState({
          HideFirstCell: false
        })
      }
    }
  };

  FilterOpen = () => {
    this.setState({
      FilterModelOpen: !this.state.FilterModelOpen
    })
  }
  // Customizable Area End
}