import React from "react";
// Customizable Area Start
import { Box, Button, Grid, Typography, styled, Modal, Select, MenuItem, TableContainer, Paper, TableHead, Table, TableRow, TableCell, TableSortLabel, TableBody } from "@material-ui/core";
import DashboardOtherServiceController, { Props, RowsValueResponse, configJSON } from "./DashboardOtherServiceController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
import * as IMG_CONST from "./assets";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons/Add';
import { KeyboardArrowDown } from "@material-ui/icons";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End
export default class Block extends DashboardOtherServiceController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderOtherServiceBox = () => {
        return (
            <MainBox>
                <Typography className="headignTxt">{configJSON.otherServiceTxt}</Typography>
                <Grid container spacing={2}>
                    {this.state.otherService.map((item,index) => {
                        return (
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                                <Box className="cardMainBox CustamClass">
                                    <Box className="headingBox">
                                        <Box className="cardLogoImg">
                                            {item.attributes.logo != null &&
                                                <img height={"100%"} width={"100%"} className="cardLogoImg" src={item.attributes.logo} />
                                            }
                                        </Box>
                                        <Typography 
                                        className="cardHeadingTxt">{item.attributes.title}</Typography>
                                    </Box>
                                    {
                                        item.attributes.service_provider_name &&
                                        <Typography className="descriptionTxt Servicesprovider"><span>Services provider :</span> 
                                         <span className="ServicesproviderName">{item.attributes.service_provider_name}</span> 
                                        </Typography>
                                    }
                                    <Typography className="descriptionTxt">{item.attributes.description}
                                    </Typography>

                                    <ViewMoreBtn
                                        data-test-id={"openCloseModalTestID"+index}
                                        className="OpenAccountClass"
                                        onClick={() => this.openCloseStudentModal(item.attributes.title, item.attributes.logo , item.id, item.attributes.service_provider_name ?? 'no services provider')}
                                        >

                                        <Typography className="viewMoreTxt">
                                            {configJSON.openBtnTxt}
                                        </Typography>
                                    </ViewMoreBtn>
                                </Box>
                            </Grid>
                        );
                    })}
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <Box className="cardMainBox">
                            <Box className="headingBox">
                                <Box className="cardLogoImg">
                                    <img height={"100%"} width={"100%"} src={IMG_CONST.airlineImg} />
                                </Box>

                                <Typography className="cardHeadingTxt">{configJSON.headingThreeTxt}</Typography>
                            </Box>
                            <Typography className="descriptionTxt">{configJSON.descriptionThreeTxt}
                            </Typography>

                            <ViewMoreBtn className="OpenAccountClass">
                                <Typography className="viewMoreTxt">
                                    {configJSON.bookeAirLineTicket}
                                </Typography>
                            </ViewMoreBtn>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
                        <Box className="cardMainBox">
                            <Box className="headingBox">
                                <Box className="cardLogoImg">
                                    <img height={"100%"} width={"100%"} src={IMG_CONST.voucherImg} />
                                </Box>

                                <Typography className="cardHeadingTxt">{configJSON.headingFourTxt}</Typography>
                            </Box>
                            <Typography className="descriptionTxt">{configJSON.descriptionFourTxt}
                            </Typography>

                            <ViewMoreBtn className="OpenAccountClass">
                                <Typography className="viewMoreTxt">
                                    {configJSON.voucherTxt}
                                </Typography>
                            </ViewMoreBtn>
                        </Box>
                    </Grid>
                </Grid>
            </MainBox>
        )
    };

    renderStudentModal = () => {
        return (
            <ModalBox open={this.state.isStudentModal}
                data-test-id="modalTestID"
                onClose={() => {
                    this.openCloseStudentModal("",null,"","")
                }}>
                <Box className="innerModalBox">
                    <CloseIcon className="closeIcon" onClick={() => this.openCloseStudentModal("",null,"","")} />
                    <Box className="gapBox">
                        <Box>
                            <Typography className="modalTitle" data-test-id="studentTestId">{configJSON.studentModalTitle}</Typography>
                            <Typography className="modalDescription">{configJSON.studentModalDescription}</Typography>
                            <Typography className="modalDescription"><span style={{ fontWeight: "800" }}>Services provider :</span> {this.state.ModelServicesProvider}</Typography>
                        </Box>

                        <Box className="middleBox">
                            <Box className="modalImgBox">
                                <img height="100%" width="100%" src={this.state.modalImg} />
                            </Box>
                            <Typography className="modalHeadingTxt">{this.state.modalTitle}</Typography>
                        </Box>

                        <Box className="lastBox">
                            <Box className="studentBox">
                                <Typography className="modalStudentTxt">{configJSON.lastStudent}</Typography>
                                <Button
                                    startIcon={<AddIcon />}
                                    className="addStudentBtn"
                                    data-test-id="addStudentTestId"
                                    onClick={this.navigateToNewStudentPage}
                                >
                                    {configJSON.addStudentTxt}
                                </Button>
                            </Box>

                            <SelectInput
                                displayEmpty
                                MenuProps={{
                                    disableScrollLock: true,
                                    anchorOrigin: {
                                        horizontal: "left",
                                        vertical: "bottom"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null,
                                }}
                                value={this.state.studentValue}
                                IconComponent={KeyboardArrowDown}
                                onChange={this.studentValueChange}
                                data-test-id="universityTestId"
                                style={{
                                    color: this.state.studentValue === "none" ? "#999" : "#000"
                                }}
                            >
                                {this.state.studentValue === "none" && (
                                    <MenuItem disabled value="none">
                                        {configJSON.selectStudent}
                                    </MenuItem>
                                )}
                                {this.state.studentData.map((item) => (
                                    <MenuItem value={item.student_id}>
                                        <SelectInnerBox >
                                            {item.name}
                                            <Box className="textBox">
                                                <Typography className="studentTxt">{configJSON.studentTxt}<span className="spanTxtSelect">{item.student_id},</span></Typography>
                                                <Typography className="studentTxt">{configJSON.passportTxt}<span className="spanTxtSelect">{item.passport_number}</span></Typography>
                                            </Box>
                                        </SelectInnerBox>
                                    </MenuItem>
                                ))}
                            </SelectInput>
                            <RequiredTxt>{this.state.studentError}</RequiredTxt>
                        </Box>

                        <Box className="btnBox">
                            <Button className="openAccountBtn"
                                data-test-id="successModalTestId"
                                onClick={() => { this.onOpenAccountClick() }}
                            >
                                {configJSON.openAccountBtn}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ModalBox>
        )
    };

    renderTable = () => {
        const { serviceData, orderBy, order, rowsPerPage, page } = this.state;
        const sortedData = this.sortData(serviceData, orderBy, order);
        const paginatedData = this.paginateData(sortedData, page, rowsPerPage);
        return (
            <TableBox>
                <Box className="serivceBox">

                    <Typography className="headingTxtTable">{configJSON.tableHeading}</Typography>
                    <ServiceSelect
                        displayEmpty
                        MenuProps={{
                            disableScrollLock: true,
                            anchorOrigin: {
                                horizontal: "left",
                                vertical: "bottom"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                        }}
                        value={this.state.serviceRequestValue}
                        IconComponent={KeyboardArrowDown}
                        onChange={this.serviceRequestChange}
                        data-test-id="serviceRequestTestID"
                        style={{
                            color: this.state.serviceRequestValue === "none" ? "#999" : "#000"
                        }}
                    >
                        {this.state.serviceRequestValue === "none" && (
                            <MenuItem disabled value="none">
                                {configJSON.serviceRequestPlaceHolder}
                            </MenuItem>
                        )}
                        {
                            this.state.otherService.map((item) => (
                                <MenuItem
                                    value={item.attributes.title}
                                >{item.attributes.title}
                                </MenuItem>
                            ))
                        }
                    </ServiceSelect>
                </Box>
                <TableContainer component={Paper} className="tableContainer">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell className="tabHeaderCell firstHeader">
                                    {configJSON.tableHeaderOne}
                                </TableCell>
                                <TableCell className="tabHeaderCell studentCol">
                                    <TableSortLabel
                                        data-test-id="studentSortTestID"
                                        active={true}
                                        direction={
                                            this.state.orderBy === "student_name" ? this.state.order : "asc"
                                        } onClick={() => this.handleRequestSort("student_name")}
                                    >
                                        {configJSON.tableHeaderTwo}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="tabHeaderCell">
                                    <TableSortLabel
                                        data-test-id="requestSortTestID"
                                        active={true}
                                        direction={
                                            this.state.orderBy === "request_id" ? this.state.order : "asc"
                                        }
                                        onClick={() => this.handleRequestSort("request_id")}
                                    >
                                        {configJSON.tableHeaderThree}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="tabHeaderCell">
                                    <TableSortLabel
                                        data-test-id="statusSortTestID"
                                        active={true}
                                        direction={
                                            this.state.orderBy === "status" ? this.state.order : "asc"
                                        } onClick={() => this.handleRequestSort("status")}
                                    >
                                        {configJSON.tableHeaderFour}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className="tabHeaderCell">
                                    <TableSortLabel
                                        data-test-id="dateSortTestID"
                                        active={true}
                                        direction={
                                            this.state.orderBy === "date" ? this.state.order : "asc"
                                        } onClick={() => this.handleRequestSort("date")}
                                    >
                                        {configJSON.tableHeaderFive}

                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((item: RowsValueResponse) => (
                                <TableRow key={item.attributes.request_id}>

                                    <TableCell className="tableInnerCell firstHeader">
                                        <Box className="firstBox">
                                            <Box className="imageBox">
                                                <img height={"100%"} width={"100%"} src={item.attributes.logo} />
                                            </Box>
                                            <Box>
                                                {item.attributes.service}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="tableInnerCell tabHeaderCell">{item.attributes.student_name}</TableCell>
                                    <TableCell className="tableInnerCell">{item.attributes.request_id}</TableCell>
                                    <TableCell className="tableInnerCell">
                                        <Box className="statusClass">
                                            <Box className={this.statusClass(item)}>
                                                {item.attributes.status}
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell className="tableInnerCell">
                                        <Box>
                                            {item.attributes.date}
                                        </Box>
                                        <Box>
                                            {item.attributes.time}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <PagginationBox >
                    <Box />
                    <Pagination
                        data-test-id="countTestId"
                        count={this.renderCount()}
                        page={this.state.page}
                        onChange={this.handleChangePage}
                    />
                    <Typography className="showingTxt">
                        {this.renderResultText()}
                    </Typography>
                </PagginationBox>
            </TableBox>

        )
    };

    renderSuccessModal = () => {
        return (
            <SuccessModal open={this.state.isSuccess}

                onClose={() => {
                    this.openCloseSuccssModal()
                }}>
                <Box className="innerSuccessModalBox">
                    <CloseIcon className="closeSuccessIcon"
                        onClick={() => this.openCloseSuccssModal()} />
                        
                    <Box className="gapSuccessBox">

                        <Box>
                            <img src={IMG_CONST.successImg} />
                        </Box>
                        <Typography className="requestTxt"
                            data-test-id="requestSuccessModal"
                        >
                            {this.state.successMessage.message}
                        </Typography>
                        <Typography className="idTxt">{configJSON.requestId}
                        <span className="idSpanTxt">{this.requestIdTxt()}.</span> 
                        </Typography>
                    </Box>
                    <Box className="btnBox">
                        <Button className="okBtn" data-test-id="successCloseTestID" onClick={() => this.openCloseSuccssModal()}>{configJSON.okTxt}</Button>
                    </Box>
                </Box>
            </SuccessModal>
        )
    };
    // Customizable Area End
    render() {
        return (
            // Customizable Area Start
            <Box>
                <AppHeader isLogin={false}
                    data-test-id="appHeaderTestId"
                    handleToggle={this.handleToggle}
                    toggleData={this.state.toggleDrawer}
                    onHomeClick={this.onHomeClick}
                    isUserLogin={true}
                    userName={this.state.userDetails?.attributes?.first_name}
                    role={this.state.userDetails?.attributes?.role}
                    profileImg={this.state.userDetails?.attributes?.image}
                />
                <SideMenuBar
                    data-test-id="sidebarNavigationBtn"
                    navigationToAnyPage={this.navigationToAnyPage}
                    activeComponent="OtherServices"
                />
                {this.renderOtherServiceBox()}
                {this.renderStudentModal()}
                {this.renderTable()}
                {this.renderSuccessModal()}
            </Box>
            // Customizable Area End
        );
    }
}
// Customizable Area Start
const SelectInnerBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    "& .textBox": {
        display: "flex",
        flexDirection: "row",
        marginTop: "5px"
    },
    "& .studentTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        color: "#212121",
        marginRight: "5px"

    },
    "& .spanTxtSelect": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 400,
        color: "#0F172A"
    }
})
const MainBox = styled(Box)({
    marginLeft: "auto",
    width: "calc(100% - 64px)",
    padding: "50px",
    boxSizing: "border-box",
    maxWidth: "100%",
    backgroundColor: "rgb(247, 247, 247)",
    "@media (max-width: 500px)": {
        padding: "20px"
    },
    "& .headignTxt": {
        fontWeight: 700,
        fontSize: "22px",
        fontFamily: "Plus Jakarta Sans",
        marginBottom: "30px",
        "@media (max-width: 768px)": {
            fontSize: "18px"
        }
    },
    "& .CustamClass":{
        "& .Servicesprovider":{
            height: "unset !important",
            minHeight: "unset !important",
            maxHeight: "unset !important",
            "& span":{
                fontSize: "16px",
                color: "#2A0066",
                fontWeight: "800"
            },
            "& .ServicesproviderName":{
                fontSize: "14px",
            }
        }
    },
    "& .cardMainBox": {
        padding: "25px",
        backgroundColor: "#FFFFFF",
        borderRadius: "8px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
        gap: "20px",
        display: "flex",
        flexDirection: "column",
        minHeight: "200px",
        maxHeight: "200px",
        position:"relative",
        "@media (max-width: 768px)": {
            padding: "15px"
        },
        "& .OpenAccountClass":{
            position: "absolute",
            bottom: "20px",
        }
    },
    "& .headingBox": {
        display: "flex",
        alignItems: "center",
        gap: "10px"
    },
    "& .cardLogoImg": {
        height: "40px",
        width: "40px",
        borderRadius: "50%",
        backgroundColor: "#F8FAFC",
        objectFit: "cover",
        display: "block"
    },
    "& .cardHeadingTxt": {
        color: "#2A0066",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 700,
        "@media (max-width: 768px)": {
            fontSize: "14px"
        }
    },
    "& .descriptionTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        color: "#000000",
        overflow: "auto",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        height: "50px",
        minHeight: '50px',
        maxHeight: '50px',
        "@media (max-width: 768px)": {
            fontSize: "10px"
        },
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-track": {
            background: "#f1f1f1"
        },
        "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px"
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#555"
        },
        "&::-moz-scrollbar": {
            width: "12px"
        },
        "&::-moz-scrollbar-track": {
            background: "#f1f1f1",
        },
        "&::-moz-scrollbar-thumb": {
            background: "#888",
            borderRadius: "10px",
        },
        "&::-moz-scrollbar-thumb:hover": {
            background: "#555",
        }
    }
});

const ViewMoreBtn = styled(Button)({
    color: "#23CF93",
    border: "1px solid #23CF93",
    height: "28px",
    padding: "6px 10px",
    borderRadius: "8px",
    width: "160px",
    "@media(max-width:800px)": {
        width: "100%"
    },
    "& .viewMoreTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        textTransform: "none",
        "@media (max-width: 768px)": {
            fontSize: "12px"
        }
    }
});

const ModalBox = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .innerModalBox": {
        maxWidth: "680px",
        position: "relative",
        borderRadius: "8px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
        padding: "20px 50px",
        backgroundColor: "#fff",
        marign: "20px !important",
        "@media (max-width: 500px)": {
            padding: "15px"
        },
    },
    "& .closeIcon": {
        position: "absolute",
        right: "10px",
        top: "10px",
        cursor: "pointer",
        color: "#334155",
        '&:hover': {
            backgroundColor: "rgba(255, 255, 255, 0.3)"
        }
    },
    "& .gapBox": {
        display: "flex",
        gap: "20px",
        padding: "20px",
        flexDirection: "column",
        "@media (max-width: 768px)": {
            gap: "15px",
            padding: "10px",
        },
    },
    "& .modalTitle": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px",
        fontWeight: 700,
        color: "#000000",
        "@media (max-width: 768px)": {
            fontSize: "16px",
        },
    },
    "& .modalDescription": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 400,
        color: "#000000",
        "@media (max-width: 768px)": {
            fontSize: "12px",
        },
    },
    "& .middleBox": {
        display: "flex",
        borderRadius: "8px",
        backgroundColor: "#F8FAFC",
        alignItems: "center",
        gap: "10px",
        padding: "15px 20px",
        "@media (max-width: 768px)": {
            padding: "15px",
            gap: "5px",
        },
    },
    "& .modalImgBox": {
        height: "40px",
        width: "40px",
        borderRadius: "50%",
    },
    "& .modalHeadingTxt": {
        color: "#2A0066",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 700,
        "@media (max-width: 768px)": {
            fontSize: "14px",
            textAlign: "center",
        },
    },
    "& .btnBox": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .openAccountBtn": {
        color: "#FFFFFF",
        height: "28px",
        padding: "10px 5px",
        borderRadius: "8px",
        textTransform: "none",
        backgroundColor: "#34D399",
        width: "270px",
        fontSize: "14px",
        "@media (max-width: 768px)": {
            width: "100%",
            fontSize: "12px"
        }
    },
    "& .studentBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 768px)": {
            gap: "10px"
        }
    },
    "& .modalStudentTxt": {
        color: "#334155",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        "@media (max-width: 768px)": {
            fontSize: "12px",
        },
    },
    "& .addStudentBtn": {
        color: "#34D399",
        textTransform: "unset",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        fontWeight: 700,
    }
});

const SuccessModal = styled(Modal)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .innerSuccessModalBox": {
        position: "relative",
        maxWidth: "680px",
        borderRadius: "8px",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.25)",
        padding: "20px 50px",
        marign: "20px !important",
        backgroundColor: "#fff",
        "@media (max-width: 500px)": {
            padding: "15px",
        },
    },
    "& .closeSuccessIcon": {
        position: "absolute",
        top: "10px",
        right: "10px",
        color: "#334155",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "rgba(255, 255, 255, 0.3)",
        }
    },
    "& .gapSuccessBox": {
        gap: "20px",
        display: "flex",
        padding: "20px",
        flexDirection: "column",
        alignItems: "center",
        "@media (max-width: 768px)": {
            gap: "15px",
            padding: "10px",
        },
    },
    "& .requestTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "20px",
        fontWeight: 700,
        color: "#000000",
        "@media (max-width: 768px)": {
            fontSize: "18px",
        },
    },
    "& .idTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 400,
        color: "#000000",
        "@media (max-width: 768px)": {
            fontSize: "16px",
        },
    },
    "& .idSpanTxt": {
        marginLeft: "5px",
        color: "#000000",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 700,
    },
    "& .okBtn": {
        color: "#FFFF",
        textTransform: "unset",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "15px",
        fontWeight: 700,
        backgroundColor: "#34D399",
        width: "150px",
        "&:hover": {
            backgroundColor: "#26B685",
        }

    },
    "& .btnBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
})
const SelectInput = styled(Select)({
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    background: "white",
    // height: "40px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiSelect-icon": {
        color: "#0E172A",
        right: "5px",
    },
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none",
        },
        "&::before": {
            content: "none",
        },
        "&::after": {
            content: "none",
        },
    },
    "&.MuiInput-underline:before": {
        borderBottom: "0px solid red",
    },
    "&.MuiInput-underline:after": {
        borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):after": {
        borderBottom: "0px solid red",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "white",
    }
});

const ServiceSelect = styled(Select)({
    width: "200px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    background: "white",
    height: "40px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "@media (max-width: 500px)": {
        width: "100%",
        margin: "0px 0px 10px 0px"
    },
    "& .MuiSelect-icon": {
        color: "#0E172A",
        right: "5px",
    },
    "& .MuiInput-root": {
        "&:focus": {
            outline: "none",
        },
        "&::before": {
            content: "none",
        },
        "&::after": {
            content: "none",
        },
    },
    "&.MuiInput-underline:before": {
        borderBottom: "0px solid red",
    },
    "&.MuiInput-underline:after": {
        borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):before": {
        borderBottom: "0px solid red",
    },
    "&:hover:not(.Mui-disabled):after": {
        borderBottom: "0px solid red",
    },
    "& .MuiSelect-select:focus": {
        backgroundColor: "white",
    }
});

const TableBox = styled(Box)({
    marginLeft: "auto",
    width: "calc(100% - 64px)",
    padding: "50px",
    boxSizing: "border-box",
    maxWidth: "100%",
    backgroundColor: "rgb(247, 247, 247)",
    "@media (max-width: 500px)": {
        padding: "20px",
    },
    "& .serivceBox": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        "@media (max-width: 500px)": {
            flexDirection: "column",
        }
    },
    "& .headingTxtTable": {
        fontWeight: 700,
        fontSize: "22px",
        fontFamily: "Plus Jakarta Sans",
        marginBottom: "30px",
        "@media (max-width: 500px)": {
            fontSize: "18px",
            marginBottom: "10px",
        }
    },
    "& .firstHeader": {
        width:"250px"
    },

    "& .tabHeaderCell": {
        fontWeight: 700,
        alignItems: "center",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "13px",
        textAlign: "center"
    },
    "& .studentCol": {
        paddingLeft:"30px"
    },
    "& .tableInnerCell": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        textAlign: "center"
    },
    "& .approvedClass": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        textAlign: "center",
        backgroundColor: "#D1FAE5",
        borderRadius: "30px",
        width: "80px",
        padding: "5px",
        color: "#059669",
        fontWeight: 700
    },
    "& .rejectedClass": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        textAlign: "center",
        backgroundColor: "#FEE2E2",
        borderRadius: "30px",
        width: "80px",
        padding: "5px",
        color: "#DC2626",
        fontWeight: 700
    },
    "& .pendingClass": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "12px",
        textAlign: "center",
        backgroundColor: "#FEF3C7",
        borderRadius: "30px",
        width: "80px",
        padding: "5px",
        color: "#D97706",
        fontWeight: 700

    },
    "& .statusClass": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    "& .firstBox": {
        display: "flex",
        alignItems: "center"
    },
    "& .imageBox": {
        height: "30px",
        width: "30px",
        borderWidth: "50%",
        marginRight: "20px"
    },
    "& .positionStickyLeft": {
        position: "sticky",
        left: 0,
        zIndex: 2,
        backgroundColor: "#fff",
        boxShadow: "5px 0 10px rgba(0,0,0,0.1)"
    },
    "& .positionStickyLeft::before": {
        position: "absolute",
        bottom: "-1px",
        left: 0,
        content: "''",
        width: "102%",
        height: "1px",
        backgroundColor: "rgba(224, 224, 224, 1)",
    },
    "& .positionStickyRight": {
        position: "sticky",
        right: 0,
        zIndex: 2,
        backgroundColor: "#fff",
        boxShadow: "-5px 0 10px rgba(0,0,0,0.1)"
    },
    "& .positionStickyRight::before": {
        position: "absolute",
        bottom: "-1px",
        left: 0,
        content: "''",
        width: "102%",
        height: "1px",
        backgroundColor: "rgba(224, 224, 224, 1)"
    },
    "& .viewAppBtn": {
        backgroundColor: "#FFFFFF",
        color: "#34D399",
        borderRadius: "4px",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 700,
        padding: "5px 8px",
        textTransform: "unset",
        fontSize: "10px",
        border: "1px solid #34D399"
    },
    "& .profileBtn": {
        backgroundColor: "#FFFFFF",
        borderRadius: "4px",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 700,
        textTransform: "unset",
        border: "1px solid #475569",
        padding: "0px",
        height: "100%",
        marginRight: "5px",
        "&.MuiButton-root": {
            minWidth: "0px !important"
        }
    }
});

const PagginationBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    "@media (max-width: 500px)": {
        flexDirection: "column",
    },
    "& .MuiSvgIcon-root": {
        fill: "green"
    },
    "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "unset",
        color: "green",
        borderBottom: "unset !important"
    },
    "& .MuiPaginationItem-page.Mui-selected::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul .MuiButtonBase-root": {
        position: "relative"
    },
    "& .MuiPagination-ul .MuiButtonBase-root::before": {
        content: "''",
        position: "absolute",
        bottom: "8px",
        left: "50%",
        width: "40%",
        height: "1px",
        backgroundColor: "#000",
        transform: "translateX(-50%)"
    },
    "& .MuiPagination-ul li:first-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },
    "& .MuiPagination-ul li:last-child .MuiButtonBase-root::before": {
        backgroundColor: "transparent !important",
    },

    "& .showingTxt": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        color: "#212121"
    }
});

const RequiredTxt = styled("span")({
    color: "red",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "15px"
});
// Customizable Area End