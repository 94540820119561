import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../../packages/framework/src/Utilities";

interface IRequest {
  id: string;
  type: string;
  attributes: {
    sender_id: number;
    status: string;
    rejection_reason: string | null;
    request_text: string;
    created_at: string;
    updated_at: string;
    reviewer_group_id: number;
    sender_full_name: string;
  };
}

interface IGroup {
  id: string;
  type: string;
  attributes: {
    name: string;
  };
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}

interface StudentData {
  student_id?: string;
  first_name?: string;
  passport_number?: string;
}
interface SelectStudentResponseJson {
  data: StudentData[];
}


interface IntakeResponse {
  data:string[];
}
interface AskForExpertError {
  errors: {
      message: string[];
  };
}

interface AskForExpertSuccess {
  message?: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleAddRequestPopup?:() => void;
  isAddRequestPopup?: boolean;
  defaultStudent?:StudentData;
  IsItFromViewProfile?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  sentRequests: IRequest[];
  isSendModalOpen: boolean;
  requestText: string;
  groups: IGroup[];
  selectedGroupId: string;
  selectedRequest: IRequest | null;
  viewRequest: IRequest | null;
  filterKey: string;

  allStudents:StudentData[];
  selectedStudent: string ;
  allCountries: string[]|[];
  selectedCountry: string[];
  allCollege:string[];
  selectedCollege:string[];
  allCourses:string[];
  selectedCourse:string[];
  allIntakes:string[];
  selectedIntake:string[];
  allYears:string[];
  selectedYear:string;
  remarks:string,
  askForExpertResponse:AskForExpertError|AskForExpertSuccess;
  studentError:string;
  countryError:string;
  collegeError:string;
  courseError:string;
  intakeError:string;
  yearError:string;
  isAskForExpertSuccess:boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SentRequestController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  sendRequestCallId: string = "";
  deleteRequestCallId: string = "";
  getGroupsCallId: string = "";
  getAllSentRequestCallId: string = "";
  updateRequestTextCallId: string = "";

  selectStudentApiCallId:string = "";
  preferredCountryApiCallId:string = "";
  preferredCollegeApiCallId:string = "";
  preferredCourseApiCallId:string = "";
  preferredIntakeApiCallId:string = "";
  preferredYearApiCallId:string = "";
  askForExpertApiCallId:string= "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      sentRequests: [],
      groups: [],
      selectedGroupId: "",
      isSendModalOpen: false,
      requestText: "",
      selectedRequest: null,
      viewRequest: null,
      filterKey: '',

      allStudents:[],
      selectedStudent:"",
      allCountries:[],
      selectedCountry:[],
      allCollege:[],
      selectedCollege:[],
      allCourses:[],
      selectedCourse:[],
      allIntakes:[],
      selectedIntake:[],
      allYears:[],
      selectedYear:"",
      remarks:"",
      askForExpertResponse:{},
      studentError:"",
      countryError:"",
      collegeError:"",
      courseError:"",
      intakeError:"",
      yearError:"",
      isAskForExpertSuccess:false
      
      
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  apiCallFunction = async (apiData: APIPayloadType) => {
    const { contentType, method, body, type, endPoint } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageForViewMore = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageForViewMore.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageForViewMore.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessageForViewMore.id, requestMessageForViewMore);

    return requestMessageForViewMore.messageId;
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      runEngine.debugLog("Message Recived", message);

      const token = message.getData(getName(MessageEnum.SessionResponseToken));
      if (token) {
        this.setState({ token: token }, () => {
          this.getAllSentRequest();
          this.getGroups();
        });
      } else {
        this.showAlert("Alert", configJSON.loginAlertMessage);
      }
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {
       this.apiCallFunctions(apiRequestCallId,responseJson);
      }
      if (apiRequestCallId != null) {
        if (
          apiRequestCallId === this.getAllSentRequestCallId &&
          responseJson !== undefined &&
          responseJson.data
        ) {
          this.setState({ sentRequests: responseJson.data });
        } else if (
          apiRequestCallId === this.deleteRequestCallId &&
          responseJson !== undefined &&
          responseJson.data
        ) {
          this.showAlert("Alert", configJSON.deletedMsgText);
          this.getAllSentRequest();
        } else if (
          apiRequestCallId === this.getGroupsCallId &&
          responseJson !== undefined &&
          responseJson.data
        ) {
          this.setState({ groups: responseJson.data });
        } else if (
          apiRequestCallId === this.sendRequestCallId &&
          responseJson !== undefined &&
          responseJson.data
        ) {
          this.showAlert("Alert", configJSON.requestSentSuccessMsg);
          this.getAllSentRequest();
          this.toggleModal();
        } else if (
          apiRequestCallId === this.updateRequestTextCallId &&
          responseJson !== undefined &&
          responseJson.data
        ) {
          this.showAlert("Alert", configJSON.requestUpdateSuccessMsg);
          this.getAllSentRequest();
          this.toggleModal();
        }
      }
    }
  }

  apiCallFunctions = (apiRequestCallId: string, responseJson: SelectStudentResponseJson & { data: string[] } & AskForExpertError & AskForExpertSuccess) => {
    if (apiRequestCallId === this.selectStudentApiCallId) {
      this.selectStudentApiSuccess(responseJson)
    }
    if (apiRequestCallId === this.preferredCountryApiCallId) {
      this.getCountriesSuccess(responseJson)
    }
    if (apiRequestCallId === this.preferredCollegeApiCallId) {
      this.getPreferredCollegeSuccess(responseJson)
    }
    if (apiRequestCallId === this.preferredCourseApiCallId) {
      this.getPrefferedCourseSuccess(responseJson)
    }
    this.apiFunctionSecond(apiRequestCallId,responseJson);
  };

  apiFunctionSecond = (apiRequestCallId: string, responseJson: { data: string[] } & AskForExpertError & AskForExpertSuccess) => {
    if (apiRequestCallId === this.preferredIntakeApiCallId) {
      this.getPrefferedIntakeSuccess(responseJson)
    }
    if (apiRequestCallId === this.preferredYearApiCallId) {
      this.getPrefferedYearSuccess(responseJson)
    }
    if (apiRequestCallId === this.askForExpertApiCallId) {
      this.askForExpertSuccess(responseJson)
    }
  };

  componentDidMount = async () => {
this.selectStudentApiCall()
this.getCountriesApiCall()
this.getPrefferedIntakeApiCall()
this.getPrefferedYearApiCall()
this.getPreferredCourseApiCall()
this.getPreferredCollegeApiCall()

    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  };

  getToken = () => {
    const tokenMsg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(tokenMsg);
  };

  getAllSentRequest = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const getAllSentMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getAllSentRequestCallId = getAllSentMsg.messageId;

    getAllSentMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSentRequestApiEndpoint
    );

    getAllSentMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getAllSentMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSentRequestApiMethod
    );
    runEngine.sendMessage(getAllSentMsg.id, getAllSentMsg);
  };

  sendRequest = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      data: {
        reviewer_group_id: this.state.selectedGroupId,
        request_text: this.state.requestText,
      },
    };

    const senRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.sendRequestCallId = senRequestMsg.messageId;

    senRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createSendRequestApiEndpoint
    );

    senRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    senRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.createSendRequestApiMethod
    );

    senRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(senRequestMsg.id, senRequestMsg);
  };

  deleteRequest = (deleteRequestId: string) => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const deleteRequestMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteRequestCallId = deleteRequestMsg.messageId;

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteRequestApiEndpoint + deleteRequestId
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    deleteRequestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteRequestApiMethod
    );

    runEngine.sendMessage(deleteRequestMsg.id, deleteRequestMsg);
  };

  getGroups = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const getGroupsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getGroupsCallId = getGroupsMsg.messageId;

    getGroupsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getGroupsApiEndpoint
    );

    getGroupsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getGroupsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getGroupsApiMethod
    );

    runEngine.sendMessage(getGroupsMsg.id, getGroupsMsg);
  };

  updateRequestText = () => {
    const headers = {
      "Content-Type": configJSON.requestApiContentType,
      token: this.state.token,
    };

    const httpBody = {
      data: {
        request_text: this.state.requestText,
      },
    };

    const updateRequestTextMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateRequestTextCallId = updateRequestTextMsg.messageId;

    updateRequestTextMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateRequestTextApiEndpoint + this.state.selectedRequest?.id
    );

    updateRequestTextMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    updateRequestTextMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateRequestTextApiMethod
    );

    updateRequestTextMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(updateRequestTextMsg.id, updateRequestTextMsg);
  };

  toggleModal = () => {
    this.setState({
      isSendModalOpen: !this.state.isSendModalOpen,
      requestText: "",
      selectedRequest: null,
    });
  };

  onChangeRequestText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ requestText: event.target.value });
  };

  onChangeTextRequestText = (requestText: string) => {
    this.setState({ requestText });
  };

  onChangeGroupId = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedGroupId: `${event.target.value}` });
  };

  onChangeTextGroupId = (selectedGroupId: string) => {
    this.setState({ selectedGroupId });
  };

  selectRequestHandler = (selectedRequest: IRequest) => {
    this.setState({
      selectedRequest,
      isSendModalOpen: true,
      requestText: selectedRequest.attributes.request_text,
    });
  };

  navigateHandler = () => {
    const navigationMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigationMsg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "RequestManagement"
    );

    navigationMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    
    navigationMsg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);

    this.send(navigationMsg);
  };

  setViewRequest = (viewRequest: IRequest) => {
    this.setState({viewRequest})
  }
  
  closeViewModal = () => {
    this.setState({viewRequest: null})
  }

  onChangeFilterKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ filterKey: event.target.value });
  };

  onChangeTextFilterKey = (filterKey: string) => {
    this.setState({ filterKey });
  };

  selectStudentApiCall = async () => {
    this.selectStudentApiCallId = await this.apiCallFunction({
      contentType: configJSON.requestApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getAllStudentEndPoint
    })
  }

  selectStudentApiSuccess = (responseJson: SelectStudentResponseJson) => {
    const data = responseJson.data.filter((items) => items.student_id !== this.props.defaultStudent?.student_id)
    this.setState({ allStudents: data })
  }


  getCountriesApiCall = async () => {
    this.preferredCountryApiCallId = await this.apiCallFunction({
      contentType: configJSON.requestApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getCountriesEndPoint
    })
  }

  getCountriesSuccess = (responseJson: { data: string[] }) => {
    this.setState({ allCountries: responseJson.data });
  }

  getPreferredCollegeApiCall = async () => {
    this.preferredCollegeApiCallId = await this.apiCallFunction({
      contentType: configJSON.requestApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPreferredCollegeEndPoint
    })
  }

  getPreferredCollegeSuccess = (responseJson: { data: string[] }) => {
    this.setState({ allCollege: responseJson.data });
  }

  getPreferredCourseApiCall = async () => {
    this.preferredCourseApiCallId = await this.apiCallFunction({
      contentType: configJSON.requestApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPreferredCourseEndPoint
    })
  }

  getPrefferedCourseSuccess = (responseJson: { data: string[] }) => {
    this.setState({ allCourses: responseJson.data });
  };

  getPrefferedIntakeApiCall = async () => {
    this.preferredIntakeApiCallId = await this.apiCallFunction({
      contentType: configJSON.requestApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPrefferedIntakeEndPoint
    })
  };
  getPrefferedIntakeSuccess = async (responseJson: IntakeResponse) => {
    this.setState({ allIntakes: responseJson.data})
  };

  getPrefferedYearApiCall = async () => {
    this.preferredYearApiCallId = await this.apiCallFunction({
      contentType: configJSON.requestApiContentType,
      method: configJSON.getApiMethod,
      endPoint: configJSON.getPrefferedYearEndPoint
    })
  }
  getPrefferedYearSuccess = (responseJson: { data: string[] }) => {
    this.setState({ allYears: responseJson.data })
  }

  handleNameChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ selectedStudent: event.target?.value as string,studentError:"" });
  };

  handleCountryChange = (
    event: React.SyntheticEvent, 
    value: string[]
  ) => {
    this.setState({ selectedCountry: value,countryError:"" });
  };

  handleRemoveCountries=(option:string)=>{
    this.setState(prev=>({
      selectedCountry:prev.selectedCountry.filter(item=>item!==option)
    }))
  }
  
  handleCollegeChange=(   event: React.SyntheticEvent, 
    value: string[])=>{
      if (value)
        this.setState({ selectedCollege: value,collegeError:"" });
    }

    handleRemoveCollege=(option:string)=>{
      this.setState(prev=>({
        selectedCollege:prev.selectedCollege.filter(item=>item!==option)
      }))
    }
    handleCourseChange=( event: React.SyntheticEvent, 
      value: string[])=>{
        if (value)
          this.setState({ selectedCourse: value,courseError:"" });
      }
      handleRemoveCourse=(option:string)=>{
        this.setState(prev=>({
          selectedCourse:prev.selectedCourse.filter(item=>item!==option)
        }))
      }

      handleIntakeChange=(event: React.SyntheticEvent, 
        value: string[])=>{
          if (value)
            this.setState({ selectedIntake: value,intakeError:"" });
        }

        handleRemoveIntake=(option:string)=>{
          this.setState(prev=>({
            selectedIntake:prev.selectedIntake.filter(item=>item!==option)
          }))
        }

        handleYearChange=(event: React.ChangeEvent<{ value: unknown }>)=>{
          this.setState({selectedYear:event.target?.value as string,yearError:""})
        }

        handleRemarkChange=(event:React.ChangeEvent<HTMLInputElement>)=>{
          this.setState({remarks:event.target?.value})
        }

  askForExpertApiCall = async(event:React.FormEvent)=>{
    event.preventDefault()
    let isEverythingFilled = true;
    if (!this.props.defaultStudent?.student_id && !this.state.selectedStudent) {
      isEverythingFilled = false

      this.setState({ studentError: "Student selection is required*" });
    }
    
    if(!this.state.selectedCountry.length){
      isEverythingFilled = false

       this.setState({countryError:"Country selection is required*"})
    }
    if(!this.state.selectedCollege.length){
      isEverythingFilled = false

       this.setState({collegeError:"College selection is required*"})
    }
    if(!this.state.selectedCourse.length){
      isEverythingFilled = false

       this.setState({courseError:"Course selection is required*"})
    }
    if(!this.state.selectedIntake.length){
      isEverythingFilled = false

       this.setState({intakeError:"Intake is required*"})
    }
    if(!this.state.selectedYear){
      isEverythingFilled = false

       this.setState({yearError:"Year is required*"})
    }
if(!isEverythingFilled) return;
else{
  const selectedStudentByUser = this.state.allStudents.filter(student=>student.student_id===this.state.selectedStudent)
  const askForExpertBody =  {
      data: {
              student_id: this.props.defaultStudent?.student_id||this.state.selectedStudent,
              student_name: this.props.defaultStudent?.first_name||selectedStudentByUser[0].first_name,
              country: this.state.selectedCountry,
              course: this.state.selectedCourse,
              intake: this.state.selectedIntake,
              year: this.state.selectedYear,
              collage:this.state.selectedCollege,
              remarks:this.state.remarks
      }
  }
  this.askForExpertApiCallId = await this.apiCallFunction({
    contentType: configJSON.requestApiContentType,
    method: configJSON.postApiMethod,
    endPoint: configJSON.postAskForExpertsEndPoint,
    body:askForExpertBody
  })
}
  }
  
  askForExpertSuccess=(responseJson: AskForExpertError & AskForExpertSuccess)=>{
    if ("errors" in responseJson) {
      const errorMessages = Array.isArray(responseJson.errors.message)
        ? responseJson.errors.message 
        : [responseJson.errors.message];
      alert(errorMessages)
    } else {
      if (this.props.IsItFromViewProfile) {
        if (this.props.handleAddRequestPopup) {
          this.props.handleAddRequestPopup()
        }
      }else{
        this.setState({isAskForExpertSuccess:true})
      }
    }
  }
  // Customizable Area End
}
