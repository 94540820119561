// Customizable Area Start
import React from "react";
import { Table, Box, Typography, Button, TableSortLabel, TableCell, TableRow, TableHead, TableBody, Chip, Step, Stepper, TableContainer, Paper, StepLabel, StepConnector, withStyles, makeStyles, StepIconProps, Grid, MenuItem, Select, Dialog, IconButton } from "@material-ui/core";
import { styled } from "@mui/material/styles";

import AllAplicationController from "./ApplicationsController.web";
import AppHeader from "../../../../packages/components/src/AppHeader.web";
import SideMenuBar from "../../../../packages/components/src/SideMenuBar.web";
export const configJSON = require("./config");
import CheckIcon from '@material-ui/icons/Check';
import clsx from "clsx";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import FilterListIcon from '@material-ui/icons/FilterList';
import { KeyboardArrowDown } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';

// Customizable Area End

export default class Applications extends AllAplicationController {
  // Customizable Area Start

  ApplicationsDataRender = () => {
    return (
      <ApplicationsStyled style={{ height: "100%", position: "relative" }} >
        <Box>
          <TableContainer className="TableContainer" data-test-id="TableContainer" component={Paper}
            onScroll={() => {
              this.handleScroll()
            }} ref={this.TableRef}>
            <Table aria-label="simple table" style={{
              position: "relative",
            }}>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2}
                    style={{
                      whiteSpace: "nowrap"
                    }}
                    className="tabHeaderCell positionStickyLeft">
                    <Box style={{ display: "flex", gap: "10px" }}>
                      <TableSortLabel
                        data-test-id="requestSortTestId"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                          {configJSON.snoTxt}
                        </Typography>
                      </TableSortLabel>
                      <TableSortLabel
                        data-test-id="requestSortStudentTestID"
                        active={true}
                      >
                        <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                          App ID
                        </Typography>
                      </TableSortLabel>
                    </Box>
                  </TableCell>
                  <TableCell className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortappTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" align="center" className="ApplicationsHeading">
                        CURRENT STAGE
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="requestSortappTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        STUDENT
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell">
                    <TableSortLabel
                      data-test-id="associateSortTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        Associated Branch
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell RemovePadding">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        CREATED BY
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell RemovePadding">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        UNIVERSITY
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell RemovePadding">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        PROGRAM
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell RemovePadding">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        FEES
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell RemovePadding">
                    <TableSortLabel
                      data-test-id="requestSortCreateTestID"
                      active={true}
                    >
                      <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                        PAYMENT DATE
                      </Typography>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell">
                    <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                      START DATE
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell">
                    <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                      Date of Last Action
                    </Typography>
                  </TableCell>
                  <TableCell align="center" className="tabHeaderCell positionStickyRight">
                    <Typography gutterBottom variant="h6" className="ApplicationsHeading">
                      ACTIONS
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.ApplicationTableData.map((_data, index) => {
                  return (
                    <>
                      <TableRow className="RowContainer">
                        <TableCell colSpan={2} className="tableInnerCell RemoveWHiteSpace positionStickyLeft">
                          <div style={{ display: "flex", gap: "40px" }}>
                            <div>
                              <Typography gutterBottom variant="h6" className="SmallText">
                                1
                              </Typography>
                            </div>
                            <div>
                              <Typography align="center" gutterBottom variant="h6" className="SmallText">
                                73336735
                              </Typography>
                              <div style={{ display: "flex", justifyContent: "center" }} >
                                <Chip label="In Progress" className="InprogressClass" />
                              </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell className="tableInnerCell RemoveWHiteSpace">
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            CAS/LOA Applied
                          </Typography>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                          <Typography align="left" gutterBottom variant="h6" className="SmallText BoldText">
                            Dinesh Demol
                          </Typography>
                          <Typography align="left" gutterBottom variant="h6" className="SmallText">
                            <span className="LightGreyColorText">Student ID</span> - 106407
                          </Typography>
                        </TableCell>
                        <TableCell className="tableInnerCell" >
                          <Typography gutterBottom variant="h6" align="center" className="SmallText">
                            Development
                          </Typography>
                        </TableCell>
                        <TableCell className="tableInnerCell" >
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            Dinesh Anand
                          </Typography>
                        </TableCell>
                        <TableCell className="tableInnerCell" >
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            Algoma University - Brampton
                          </Typography>
                        </TableCell>
                        <TableCell className="tableInnerCell">
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            Graduate Certificate - Human Resources and Business Management
                          </Typography>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            £ 17,065.00
                          </Typography>
                        </TableCell>
                        <TableCell className="tableInnerCell">
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            01-Nov-2023
                          </Typography>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            01-Sep-2024
                          </Typography>
                        </TableCell>
                        <TableCell style={{ whiteSpace: "nowrap" }} className="tableInnerCell">
                          <Typography align="center" gutterBottom variant="h6" className="SmallText">
                            27-Nov-2024
                          </Typography>
                        </TableCell>
                        <TableCell align="right"
                          className={"tableInnerCell positionStickyRight" + `${this.state.ExpendProgress === index ? " RemoveBorder " : ""}`}>
                          <Box className="ViewAppButtonContainer">
                            <Button className="ViewAppButton" data-test-id={"ViewApp" + index} onClick={() => {
                              this.navigationToAnyPage("ViewProfile")
                            }} variant="contained">
                              View App
                            </Button>
                            <Button data-test-id={"ExpendBtn" + index} onClick={() => {
                              this.setState({
                                ExpendProgress: index
                              })
                            }} variant="outlined">
                              <KeyboardArrowDownIcon />
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                      {this.state.ExpendProgress === index &&
                        <TableRow className="RowContainer" style={{ backgroundColor: "#F8FAFC", position: "relative" }}>
                          {
                            this.state.HideFirstCell &&
                            <TableCell className="positionStickyLeft" colSpan={2} />
                          }
                          <TableCell colSpan={10}>
                            <Box>
                              {this.SuccessStepper()}
                            </Box>
                          </TableCell>
                          <TableCell colSpan={2} align="right" className="tableInnerCell positionStickyRight" />
                        </TableRow>
                      }
                    </>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </ApplicationsStyled>
    )
  }

  SuccessStepper = () => {
    return (
      <Box className="PipelineContainer">
        <Stepper
          alternativeLabel
          activeStep={1}
          className="StperClass"
          connector={<SuccessColorlibConnector />}
        >
          {this.StperData().map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={SuccessColorlibStepIcon}>
                <Typography variant="h6" className={index === 0 ? "SteperHeading" : "SteperHeadingBlack"}>
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    )
  }

  TitleAndFilterAndTextFiledRender = () => {
    return (
      <Box className="TitltAndFilterContainer">
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6" className="PageHeading">
              Applications
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
              <Grid item xs={4}>
                <SelectInput
                  displayEmpty
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                  }}
                  name="AssociatedBranch"
                  value={""}
                  IconComponent={KeyboardArrowDown}
                  data-test-id="StudentAssociatedBranch"
                  defaultValue="default"
                  className="SelectModelClass"
                >
                  <MenuItem value="" disabled>
                    In process
                  </MenuItem>
                  {["1"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={4}>
                <SelectInput
                  displayEmpty
                  MenuProps={{
                    getContentAnchorEl: null,
                    disableScrollLock: true,
                    anchorOrigin: {
                      horizontal: "left",
                      vertical: "bottom"
                    },
                  }}
                  name="AssociatedBranch"
                  value={""}
                  IconComponent={KeyboardArrowDown}
                  data-test-id="StudentAssociatedBranch"
                  defaultValue="default"
                  className="SelectModelClass"
                >
                  <MenuItem value="" disabled>
                    Sort by stage
                  </MenuItem>
                  {["1"].map((item) => {
                    return <MenuItem value={item}>{item}</MenuItem>
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={3}>
                <Button data-test-id="FilterButton" onClick={this.FilterOpen} startIcon={<FilterListIcon />} fullWidth variant="contained" className="FilterButton">
                  Filter
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  FilterModelRender = () => {
    const { FilterModelOpen } = this.state
    return (
      <FilterModalStyle
        open={FilterModelOpen}
        maxWidth="sm"
        scroll="paper"
      >
        <Box className="BoxContainer">
          <Box className="CloseIconContainer">
            <IconButton data-test-id="CloseUploadModel" onClick={this.FilterOpen}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography gutterBottom align="center" variant="h6" className="ModalHeadig FilterTitleContainer">
            <FilterListIcon />
            Filter
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={8}>
              <LabelsTypography>
                Student
              </LabelsTypography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                name="Student"
                value={""}
                IconComponent={KeyboardArrowDown}
                data-test-id="Student"
                defaultValue="default"
                className="selectField"
              >
                <MenuItem value="" disabled>
                  Select student (s)
                </MenuItem>
                {["1", "2", "3"].map((items, index2) => (
                  <MenuItem key={index2} value={items}>items</MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={8}>
              <LabelsTypography>
                Associated branch
              </LabelsTypography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                name="Associatedbranch"
                value={""}
                IconComponent={KeyboardArrowDown}
                data-test-id="Associated branch"
                defaultValue="default"
                className="selectField"
              >
                <MenuItem value="" disabled>
                  Select branch
                </MenuItem>
                {["1", "2", "3"].map((items, index2) => (
                  <MenuItem key={index2} value={items}>items</MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={8}>
              <LabelsTypography>
                Created by
              </LabelsTypography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                name="Createdby"
                value={""}
                IconComponent={KeyboardArrowDown}
                data-test-id="Createdby"
                defaultValue="default"
                className="selectField"
              >
                <MenuItem value="" disabled>
                  Select  counsellor (s)
                </MenuItem>
                {["1", "2", "3"].map((items, index2) => (
                  <MenuItem key={index2} value={items}>items</MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={8}>
              <LabelsTypography>
                University
              </LabelsTypography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                name="University"
                value={""}
                IconComponent={KeyboardArrowDown}
                data-test-id="University"
                defaultValue="default"
                className="selectField"
              >
                <MenuItem value="" disabled>
                  Select university
                </MenuItem>
                {["1", "2", "3"].map((items, index2) => (
                  <MenuItem key={index2} value={items}>items</MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={8}>
              <LabelsTypography>
                Program
              </LabelsTypography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  disableScrollLock: true,
                  anchorOrigin: {
                    horizontal: "left",
                    vertical: "bottom"
                  },
                }}
                name="Program"
                value={""}
                IconComponent={KeyboardArrowDown}
                data-test-id="Program"
                defaultValue="default"
                className="selectField"
              >
                <MenuItem value="" disabled>
                  Select program
                </MenuItem>
                {["1", "2", "3"].map((items, index2) => (
                  <MenuItem key={index2} value={items}>items</MenuItem>
                ))}
              </SelectInput>
            </Grid>
            <Grid item xs={8}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" className="ResetFilterBtn">
                    Reset Filter
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" className="ApplyFilterBtn">
                    Apply Filter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

      </FilterModalStyle>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ApplicationsStyle>
        <LeadBox>
          <AppHeader isLogin={false}
            data-test-id="appHeaderTestId"
            handleToggle={this.handleToggle}
            toggleData={this.state.toggleDrawer}
            onHomeClick={this.onHomeClick}
            isUserLogin={true}
            userName={this.state.userDetails?.attributes?.first_name}
            role={this.state.userDetails?.attributes?.role}
            profileImg={this.state.userDetails?.attributes?.image}
            renderButtonClick={() => { }}
            showScool={""}
          />
          <SideMenuBar data-test-id="sidebarNavigationBtn"
            navigationToAnyPage={this.navigationToAnyPage} activeComponent="Applications" />

          <Box className="mainBar">
            {this.TitleAndFilterAndTextFiledRender()}
            {this.ApplicationsDataRender()}
            {this.FilterModelRender()}
          </Box>
        </LeadBox>
      </ApplicationsStyle>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const FilterModalStyle = styled(Dialog)({
  position: "relative",
  padding: "20px",
  "& .CloseIconContainer": {
    display: "flex",
    justifyContent: 'flex-end',
    width: "100%",
    minHeight: "40px"
  },
  "& .HeadingContainer": {
    display: "flex",
    justifyContent: "center"
  },
  "& .MuiDialogContent-root": {
    padding: "0px 40px",
    minWidth: "400px"
  },
  "& .ModalHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: "capitalize",
    marginBottom: "20px",
    textAlign: "center",
  },
  "& .ModalSubHeadig": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: "30px"
  },
  "& .BoxContainer": {
    overflowX: "hidden",
    width: "480px",
    paddingBottom: "20px",
    "& .ResetFilterBtn": {
      color: "#34D399",
      whiteSpace: "noWrap",
      border: "1px solid #34D399",
      borderRadius: "6px",
      textTransform: "capitalize",
      backgroundColor: "#D6F6EB",
      boxShadow: "none",
    },
    "& .ApplyFilterBtn": {
      border: '1px solid #34D399',
      textTransform: 'capitalize',
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: '700',
      textAlign: 'center',
      padding: "5px 8px",
      borderRadius: "6px",
      color: 'white',
      boxShadow: "none",
      backgroundColor: "#34D399"
    },
    "& .FilterTitleContainer": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px"
    },
    "& .selectField": {
      height: "50px",
      "& .MuiSelect-select.Mui-disabled": {
        color: "rgba(0, 0, 0, 0.38)"
      },
      "& :hover": {
        borderBottom: "0px solid red"
      }
    },
  }
})

const ApplicationsStyle = styled(Box)({
  "& .TitltAndFilterContainer": {
    width: "100%",
    margin: "20px 0px",
    "& .SelectModelClass": {
      maxHeight: "40px",
      minHeight: "40px"
    },
    "& .PageHeading": {
      fontFamily: "Plus Jakarta Sans",
      fontSize: "22px !important",
      fontWeight: '700 !important'
    },
    "& .FilterButton": {
      backgroundColor: "#373335",
      color: 'white',
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 700,
      textTransform: "capitalize",
      borderRadius: "6px",
    }
  },
  "& .CardContainer": {
    "@media only screen and (max-width: 425px) ": {
      padding: "0 5px"
    },
  },
})

const LeadBox = styled(Box)({
  backgroundColor: "#EEEEEE",
  fontFamily: "Plus Jakarta Sans",
  "& .mainBar": {
    marginLeft: "64px",
    padding: "28px 50px",
    "@media (max-width: 600px)": {
      padding: "20px"
    },
    "& .datePickers": {
      display: "flex",
      gap: "10px"
    }
  },
  "& .defaultTxtBold": {
    fontFamily: "Plus Jakarta Sans",
    fontSize: "16px",
    fontWeight: 700
  }
});

const ApplicationsStyled = styled(Box)({
  "& .TableContainer": {
    overflowY: "hidden",
    position: "relative",
    "&::-webkit-scrollbar": {
      marginTop: "10px",
      height: '10px',
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: 'none',
      borderRadius: '2px',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: '#CBD5E1',
      borderRadius: '2px',
    },
  },
  "& .RemovePadding": {
    // padding: "0"
  },
  "& .RemoveBorder": {
    border: "none"
  },
  "& .positionStickyLeft": {
    position: "sticky",
    left: "-1px",
    zIndex: 10,
    backgroundColor: "#fff",
    boxShadow: '-2px 0px 8px 0px #00000014'
  },
  "& .RemoveWHiteSpace": {
    whiteSpace: "nowrap"
  },
  "& .positionStickyLeft::before": {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    content: "''",
    width: "102%",
    height: "1px",
  },
  "& .positionStickyRight": {
    position: "sticky",
    right: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    boxShadow: "-5px 0 10px rgba(0,0,0,0.1)"
  },
  "& .positionStickyRight::before": {
    position: "absolute",
    bottom: "-1px",
    left: 0,
    content: "''",
    width: "102%",
    height: "1px",
  },
  "& .ApplicationsHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: '700',
    textTransform: "capitalize",
  },
  "& .ApplicationsSubHeading": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: '400',
    textTransform: "capitalize",
  },
  "& .NoDataAvailable": {
    textAlign: "center"
  },
  "& .RowContainer": {
    position: "relative",
    "& .SmallText": {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: '400',
      color: "#212121"
    },
    "& .BoldText": {
      fontWight: "700"
    },
    "& .LightGreyColorText": {
      color: "#64748B"
    },
    "& .InprogressClass": {
      backgroundColor: "#FEF3C7",
      color: "#D97706"
    },
    "& .ViewAppButtonContainer": {
      display: "flex",
      gap: "5px"
    },
    "& .ViewAppButton": {
      color: "#34D399",
      whiteSpace: "noWrap",
      border: "1px solid #34D399",
      borderRadius: "6px",
      textTransform: "capitalize",
      backgroundColor: "white",
      boxShadow: "none",
      marginRight: "5px",
    },
    "& .PipelineContainer": {
      backgroundColor: "#F8FAFC",
      "& .StperClass": {
        padding: "0px !important",
        backgroundColor: "transparent",
      },
      "& .RefundStperClass": {
        width: "fit-content"
      },
      "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
        marginTop: "4px"
      },
      "& .SteperHeading": {
        color: "#34D399",
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'center',
      },
      "& .MuiStepConnector-alternativeLabel": {
        left: "calc(-50% + 0px) !important",
        right: "calc(50% + 0px) !important"
      },
      "& .SteperHeadingBlack": {
        color: "#64748B",
        fontFamily: 'Inter',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'center',
      },
      "& .RefundSteperHeading": {
        color: "#0042EA",
      }
    }
  }
})

const SuccessColorlibConnector = withStyles({
  alternativeLabel: { top: 10, },
  active: {
    '& $line': { backgroundColor: '#34D399', },
  },
  completed: {
    '& $line': { backgroundColor: '#34D399', },
  },
  line: {
    height: 7,
    border: 0,
    backgroundColor: '#D9D9D9',
    borderRadius: 1,
  },
})(StepConnector);

function SuccessColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const {
    active,
    completed
  } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {completed || active ? (
        <CheckIcon />
      ) : (
        <div style={{ width: "10px", height: "10px", borderRadius: "50%" }} />
      )}
    </div>
  );
}



const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: "#fff",
    width: 24,
    height: 24,
    display: "flex",
    backgroundColor: "#fff",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #34D399",
  },
  active: {
    backgroundColor: "#34D399"
  },
  completed: {
    backgroundColor: "#34D399"
  },
  "& .MuiStepLabel-alternativeLabel": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
});

const SelectInput = styled(Select)({
  justifyContent: "center",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  background: "white",
  width: "100%",
  height: "100%",
  display: "flex",
  margin: "5px 0px",
  boxSizing: "border-box",
  padding: "0px 10px",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": { outline: "none" },
    "&::before": { content: "none" },
    "&::after": { content: "none" }
  },
  "&.MuiInput-underline:before": { borderBottom: "0px solid red !important" },
  "&.MuiInput-underline:after": { borderBottom: "0px solid red !important" },
  "&:hover:not(.Mui-disabled):before": { borderBottom: "0px solid red !important", },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red !important"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  "& .MuiInputBase-input": {
    color: '#64748B',
    fontWeight: 700,
  },

});

const LabelsTypography = styled(Typography)({
  "&.MuiTypography-root": {
    fontWeight: '700',
  },
  fontSize: "16px",
  fontFamily: "Plus Jakarta Sans",
})

// Customizable Area End