import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconLogout extends Component<IconPropsType> {
      
    render() {
        return (
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 0H16C17.1 0 18 0.9 18 2V16C18 17.1 17.1 18 16 18H2C0.9 18 0 17.1 0 16V13C0 12.45 0.45 12 1 12C1.55 12 2 12.45 2 13V15C2 15.55 2.45 16 3 16H15C15.55 16 16 15.55 16 15V3C16 2.45 15.55 2 15 2H3C2.45 2 2 2.45 2 3V5C2 5.55 1.55 6 1 6C0.45 6 0 5.55 0 5V2C0 0.9 0.89 0 2 0ZM12.79 9.7L9.2 13.29C8.81 13.68 8.18 13.68 7.79 13.29C7.41 12.91 7.4 12.27 7.79 11.88L9.67 10H1C0.45 10 0 9.55 0 9C0 8.45 0.45 8 1 8H9.67L7.79 6.11C7.4 5.72 7.4 5.09 7.79 4.7C7.97683 4.51275 8.23048 4.40751 8.495 4.40751C8.75952 4.40751 9.01317 4.51275 9.2 4.7L12.79 8.29C13.18 8.68 13.18 9.31 12.79 9.7Z" fill="#292D32"/>
</svg>

        )
    }
}



