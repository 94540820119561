Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "TextDetector";
exports.labelBodyText = "TextDetector Body";

exports.btnExampleTitle = "CLICK ME";

exports.requestApiContentType = "application/json";
exports.getReceivedRequestApiEndpoint = "request_management/requests/received";
exports.getReceivedRequestApiMethod = "GET";

exports.getSendRequestApiEndpoint = "request_management/requests/sent";
exports.getSendRequestApiMethod = "GET";

exports.getAllReceivedRequestApiEndpoint = "request_management/requests";
exports.getAllReceivedRequestApiMethod = "GET";

exports.createSendRequestApiEndpoint = "request_management/requests";
exports.createSendRequestApiMethod = "POST";

exports.emptyGroupIdAlert = "Please select group id";
exports.emptyRejectReasonAlert = "Please enter reason";
exports.acceptedMsgText = "Request has been accepted.";
exports.rejectedMsgText = "Request has been rejected.";
exports.deletedMsgText = "Request has been deleted.";
exports.requestSentSuccessMsg = "Request has been sent."
exports.requestUpdateSuccessMsg = "Request has been updated."

exports.updateRequestApiEndpointStart = "request_management/requests/";
exports.updateRequestApiEndpointEnd = "/review";
exports.updateRequestApiMethod = "PUT";

exports.deleteRequestApiEndpoint = "request_management/requests/";
exports.deleteRequestApiMethod = "DELETE";

exports.getGroupsApiEndpoint = "account_groups/groups";
exports.getGroupsApiMethod = "GET";

exports.getSentRequestApiEndpoint = "request_management/requests/sent";
exports.getSentRequestApiMethod = "GET";

exports.updateRequestTextApiEndpoint = "request_management/requests/";
exports.updateRequestTextApiMethod = "PUT";


exports.titleText = "Request Management";
exports.sendRequestBtnLabel = "Send Request";
exports.receiveRequestText = "Received Requests";
exports.nameLabelText = "Name";
exports.requestLabelText = "Request Text";
exports.rejectReasonLabelText = "Reject Reason";
exports.statusLabelText = "Status";
exports.actionLabelText = "Action";
exports.acceptBtnLabel = "Accept";
exports.rejectBtnLabel = "Reject";
exports.deleteBtnLabel = "Delete";
exports.sendRequestsText = "Sent Requests";
exports.updateBtnLabel = "Update";
exports.viewBtnLabel = "View";
exports.requestIdLabel = "Request ID";
exports.sendRequestDialogTitle = "Send Request";
exports.updateRequestDialogTitle = "Update Request";
exports.rejectRequestDialogTitle = "Request reject";
exports.cancelBtnLabel = "Cancel";
exports.sendBtnLabel = "Send";
exports.rejectReasonInputLabel = "Please enter reason";
exports.requestTextInputLabel = "Please enter request text";
exports.selectGroupPlaceholder = "Please select group";
exports.rejectTextFieldIsRequired = "Please enter reject reason";
exports.loginAlertMessage = "Please login first.";
exports.filterInputLabel = "Enter request id to filter"
exports.addApplicationTxt = "Add Applications";
exports.starTxt = "*";
exports.employeesTxt= "Employees";
exports.myPreferredTxt= "My Preferred";
exports.expertTxt= "Expert to Advise";
exports.whereStudentTxt = "Where student wants to go?";
exports.selectCountryPlaceholderTxt = "Select Country";
exports.preferCollegeTxt = "Prefer College";
exports.preferCollegeplaceHolder = "Select preferred College";
exports.preferCourseTxt = "Prefer Course";
exports.preferCoursePlaceHolder = "Select preferred Course";
exports.intakeTxt = "Intake";
exports.yearTxt = "Year";
exports.selectIntakeTxt = "Select Intake";
exports.createdByTxt = "Created By";
exports.createdByNameTxt = "Dinesh Anand";
exports.saveTxt = "Save Changes";
exports.cancelTxt = "Cancel";
exports.remarkTxt = "Remark";
exports.remarkPlaceHolder = "Write something here...";
exports.regaxText = /^[A-Za-z]+$/;
exports.selectYear = "Year";

exports.getAllStudentEndPoint = "/bx_block_request_management/requests/select_student";
exports.getCountriesEndPoint = "/bx_block_catalogue/catalogues/university_country";
exports.getPreferredCollegeEndPoint = "/bx_block_request_management/requests/prefer_collage";
exports.getPreferredCourseEndPoint = "/bx_block_request_management/requests/prefer_course";
exports.getPrefferedIntakeEndPoint = "/bx_block_request_management/requests/prefer_intake";
exports.getPrefferedYearEndPoint = "/bx_block_request_management/requests/prefer_year"
exports.postAskForExpertsEndPoint = "/bx_block_request_management/requests"
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.requestSuccessHeader = "Your request for Expert was sent!";
exports.requestSuccessText = "You request will be processed by admin";
exports.okTxt = "Ok"
// Customizable Area End