import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Typography,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  Modal,
  Grid,
  ListItemText,
  Checkbox,
  Chip,
  SvgIcon
} from "@material-ui/core";

import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import CloseIcon from '@mui/icons-material/Close';
import { successTick, tickimage } from './assets';
import { KeyboardArrowDown } from "@material-ui/icons";
import Autocomplete from '@mui/material/Autocomplete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const theme = createTheme({
  palette: {
    primary: {
      main: "#6200ee",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SentRequestController, {
  Props,
  configJSON,
} from "./SentRequestController";

export default class SentRequest extends SentRequestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  renderExpertRequestModal = () => {

    return (
      <MainModal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={true}
        onClose={this.props.handleAddRequestPopup}
        className="modal"
        data-test-id="mainModalTestID"
      >
        {
          this.state.isAskForExpertSuccess && !this.props.IsItFromViewProfile? 
          <RenderSuccessBox className="paper">
            <div>
            <BoxPopupClose data-test-id="boxPopupCloseTestID">
            <CloseIcon data-test-id="closeIconTestID" onClick={this.props.handleAddRequestPopup} />
          </BoxPopupClose>
            </div>
          <img
              src={successTick}
              alt={"Tick Image"}
            />
          <Typography className="header">{configJSON.requestSuccessHeader}</Typography>
          <Typography className="text">{configJSON.requestSuccessText}</Typography>
          <Button
              type="submit"
              className="addBtn"
              onClick={this.props.handleAddRequestPopup}
            >
             {configJSON.okTxt}
            </Button>
        </RenderSuccessBox> : 
          <Paper className="paper" data-test-id="paperTestID">
          <BoxPopupClose data-test-id="boxPopupCloseTestID">
            <CloseIcon data-test-id="closeIconTestID" onClick={this.props.handleAddRequestPopup} />
          </BoxPopupClose>
          
          
         

          <Box>
            <BoxPopUpTitle className="filterTitle">
              <img
                src={tickimage}
                alt={"Tick Image"}
              />
              <Typography className="heading" data-test-id="headingTestID">
                Ask for the Expert
              </Typography>
            </BoxPopUpTitle>
          </Box>
          
          <form data-test-id="branchSubmitTestID" onSubmit={this.askForExpertApiCall}>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
                Select Student <span className="starTxt">*</span>
              </Typography>
              <SelectInput
                displayEmpty
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                }}
                disableUnderline
                error={false}
                data-test-id="selectStudent"
                value={this.props.defaultStudent?.student_id||this.state.selectedStudent}
                onChange={this.handleNameChange}
                IconComponent={KeyboardArrowDown}
                defaultValue="default"
              >
                {this.props.defaultStudent?.student_id?<MenuItem key={this.props.defaultStudent.student_id} value={this.props.defaultStudent.student_id}>
                <div>
                    <div>
                      <p style={{ margin: 0 }}>{this.props.defaultStudent.first_name}</p>
                    </div>
                    <div>
                      <strong>Student ID:</strong> {this.props.defaultStudent.student_id}, <strong>Passport No:</strong> {this.props.defaultStudent.passport_number}</div>
                  </div>
                </MenuItem>:<MenuItem key={10} value={""}>{`Select Student`}</MenuItem>}
                {this.state.allStudents.map((student)=><MenuItem key={student.student_id} value={student.student_id}>
                  <div>
                    <div>
                      <p style={{ margin: 0 }}>{student.first_name}</p>
                    </div>
                    <div>
                      <strong>Student ID:</strong> {student.student_id}, <strong>Passport No:</strong> {student.passport_number}</div>
                  </div>
                </MenuItem>)}
              </SelectInput>
              {this.state.studentError&&<p className="error">{this.state.studentError}</p>}

            </Box>

            <Box className="countryBox formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
                Where student wants to go <span className="starTxt">*</span>
              </Typography>
              <Autocomplete
                multiple
                value={this.state.selectedCountry}
                data-test-id="selectCountries"
                className="selectInput autocompleteField"
                options={this.state.allCountries}
                getOptionLabel={(country: string) => country}
                disableCloseOnSelect
                onChange={this.handleCountryChange}
                renderOption={(props, country: string, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={country} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText primary={country} />
                    </Box>
                  );
                }}
                renderInput={(params) =>(<TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Country"}
                    InputLabelProps={{ shrink: true, ...params?.InputProps }}
                    
                  />
    )}
                renderTags={()=>null}
              />
        <Box mt={2}>
                {this.state.selectedCountry.map((option,index) => (
                  <StyledChip
                    data-test-id={"removedSelectedCountries"+index}
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveCountries(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} className="closeIcon" />}
                  />
                ))}
                </Box>
                {this.state.countryError&&<p className="error">{this.state.countryError}</p>}
            </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
              Prefer College <span className="starTxt">*</span>
              </Typography>
              <Autocomplete
                multiple
                value={this.state.selectedCollege}
                data-test-id="selectCollege"
                className="selectInput autocompleteField"
                options={this.state.allCollege}
                getOptionLabel={(college: string) => college}
                disableCloseOnSelect
                onChange={this.handleCollegeChange}
                renderOption={(props, college: string, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={college} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText primary={college} />
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select College"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={()=>null}
              />
               <Box mt={2}>
                {this.state.selectedCollege.map((option,index) => (
                  <StyledChip
                    data-test-id={"removedSelectedCollege"+index}
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveCollege(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} className="closeIcon" />}
                  />
                ))}
                </Box>
                {this.state.collegeError&&<p className="error">{this.state.collegeError}</p>}
                   </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
              Prefer Course <span className="starTxt">*</span>
              </Typography>
              <Autocomplete
                multiple
                value={this.state.selectedCourse}
                data-test-id="selectCourses"
                className="selectInput autocompleteField"
                options={this.state.allCourses}
                getOptionLabel={(course: string) => course}
                disableCloseOnSelect
                onChange={this.handleCourseChange}
                renderOption={(props, course: string, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={course} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText primary={course} />
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Course"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={()=>null}
                
              />
                <Box mt={2}>
                {this.state.selectedCourse.map((option,index) => (
                  <StyledChip
                    data-test-id={"removedSelectedCourse"+index}
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveCourse(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} className="closeIcon" />}
                  />
                ))}
                </Box>
                {this.state.courseError&&<p className="error">{this.state.courseError}</p>}

            </Box>

            <Box className="formFieldBox">
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="fieldText" data-test-id="currencyCode">
                    Intake <span className="starTxt">*</span>
                  </Typography>
                  <Autocomplete
                multiple
                value={this.state.selectedIntake}
                data-test-id="selectIntake"
                className="selectInput autocompleteField"
                options={this.state.allIntakes}
                getOptionLabel={(intake: string) => intake}
                disableCloseOnSelect
                onChange={this.handleIntakeChange}
                renderOption={(props, intake: string, { selected }) => {
                  const { key, ...otherProps } = props;
                  return (
                    <Box component="li" key={intake} {...otherProps}>
                      <StyledCheckBox checked={selected} style={{ marginRight: 8 }} />
                      <ListItemText primary={intake} />
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="intakePlaceholder"
                    variant="standard"
                    placeholder={"Select Intake"}
                    InputLabelProps={{ shrink: true, ...params.InputProps }}
                  />
                )}
                renderTags={()=>null}
              />
               {this.state.intakeError&&<p className="error">{this.state.intakeError}</p>}

                </Grid>
                <Grid item xs={6}>
                  <Typography className="fieldText" data-test-id="currencyCode">
                    Year <span className="starTxt">*</span>
                  </Typography>
                  <SelectInput
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        horizontal: "left",
                        vertical: "bottom"
                      },
                    }}
                    IconComponent={KeyboardArrowDown}
                    value={this.state.selectedYear}
                    data-test-id="yearSelect"
                    defaultValue="default"
                    displayEmpty
                    disableUnderline
                    onChange={this.handleYearChange}
                    error={false}
                  >
                    <MenuItem key={0} value="">{`Select year`}</MenuItem>
                   {this.state.allYears.map((year)=><MenuItem key={year} value={year}>{year}</MenuItem>
)}
                  </SelectInput>
                  {this.state.yearError&&<p className="error">{this.state.yearError}</p>}

                </Grid>
              </Grid>
              <Box mt={2}>
                {this.state.selectedIntake.map((option,index) => (
                  <StyledChip
                    data-test-id={"removedSelectedIntake"+index}
                    key={option}
                    label={option}
                    onDelete={() => this.handleRemoveIntake(option)}
                    deleteIcon={<SvgIcon component={CancelOutlinedIcon} className="closeIcon" />}
                  />
                ))}
                </Box>
            </Box>

            <Box className="formFieldBox">
              <Typography className="fieldText" data-test-id="currencyCode">
                Remark
              </Typography>
              <InputFieldMultiline
                multiline
                InputProps={{
                  "disableUnderline":true
                }}
                value={this.state.remarks}
                className="MultineTextFiled"
                onChange={this.handleRemarkChange}
                placeholder={"Write something here..."}
                data-test-id="messageTestId"
                inputProps={{ maxLength: 1000, className:"darkPlaceholder" }}
              />
            </Box>

            <Box className="buttonBoxBranch" data-test-id="buttonBoxBranchTestID">
              <Button
                type="submit"
                className="addBtn"
                data-test-id="addBranchButtonTestID"
              >
                Ask for the Expert
              </Button>
            </Box>
          </form> 
        </Paper>
        }
        
      </MainModal>
    )
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <ThemeProvider theme={theme}>
        <Container>
            <h1 data-test-id="sentRequest">Sent Request</h1>
            {this.props.isAddRequestPopup && this.renderExpertRequestModal()}
        </Container>
        <Dialog
          open={this.state.isSendModalOpen}
          onClose={this.toggleModal}
          maxWidth={"md"}
        >
          <DialogTitle>
            {this.state.selectedRequest
              ? configJSON.updateRequestDialogTitle
              : configJSON.sendRequestDialogTitle}
          </DialogTitle>
          <DialogContent>
            <Box>
              {!this.state.selectedRequest && (
                <Select
                  value={this.state.selectedGroupId}
                  onChange={this.onChangeGroupId}
                  fullWidth
                  variant="outlined"
                  placeholder={configJSON.selectGroupPlaceholder}
                >
                  {this.state.groups.map((group) => {
                    return (
                      <MenuItem key={group.id} value={group.id}>
                        {group.attributes.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
            </Box>
            <TextField
              value={this.state.requestText}
              onChange={this.onChangeRequestText}
              variant="outlined"
              label={configJSON.requestTextInputLabel}
              style={webStyle.inputStyle}
              data-testid="requestTextField"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.toggleModal} color="primary">
              {configJSON.cancelBtnLabel}
            </Button>
            <Button
              onClick={() =>
                this.state.selectedRequest
                  ? this.updateRequestText()
                  : this.sendRequest()
              }
              color="primary"
              variant="contained"
              data-testid="sendRequestSubmitBtn"
            >
              {configJSON.sendBtnLabel}
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.viewRequest && (
          <Dialog
            open={!!this.state.viewRequest}
            onClose={this.closeViewModal}
            maxWidth={"md"}
          >
            <DialogTitle data-testid="viewRequestId">
              {configJSON.requestIdLabel}: {this.state.viewRequest.id}
            </DialogTitle>
            <DialogContent>
              <Typography>
                {configJSON.nameLabelText}:{" "}
                {this.state.viewRequest.attributes.sender_full_name}
              </Typography>
              <Typography>
                {configJSON.requestLabelText}:{" "}
                {this.state.viewRequest.attributes.request_text}
              </Typography>
              <Typography>
                {configJSON.statusLabelText}:{" "}
                <Box
                  component={"span"}
                  style={{
                    color:
                      this.state.viewRequest.attributes.status === "accepted"
                        ? "green"
                        : this.state.viewRequest.attributes.status ===
                          "rejected"
                        ? "red"
                        : "black",
                  }}
                >
                  {this.state.viewRequest.attributes.status}
                </Box>
              </Typography>
              {this.state.viewRequest.attributes.rejection_reason && (
                <Typography>
                  {configJSON.rejectReasonLabelText}:{" "}
                  {this.state.viewRequest.attributes.rejection_reason}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.closeViewModal}
                color="primary"
                data-testid="cancelViewModalBtn"
              >
                {configJSON.cancelBtnLabel}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  btnRightMargin: {
    marginRight: 10,
  },
  tableTitleStyle: {
    alignSelf: "flex-start",
    marginTop: 30,
  },
  tableBtnStyle: {
    marginRight: 10,
  },
  inputStyle: {
    marginTop: 15,
    width: 350,
    maxWidth: "100%",
  },
  topWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
};

const MainModal = styled(Modal)({
  display: "flex",
  justifyContent: "center",
  padding: "20px",
  "& .error":{
    color:"#ff3e3d",
    marginLeft:"10px",
    fontSize:"13px",
  },
  "& .paper": {
    overflowY: "auto",
    width: "520px",
    borderRadius: "10px",
    display: "flex",
    flexDirection: "column",
    padding: "30px 35px",
    gap: "20px",
    position:"relative",
    height:"auto"
  },
  "& .MangeStaff-paper":{
    overflowY: "scroll",
    borderRadius: "10px",
    display: "flex",
    padding: "30px 35px",
    gap: "20px",
    width: "480px",
    position:"relative",
    height:"auto"
  },
  "& .heading": {
    fontWeight: 700,
    fontSize: "20px",
    fontFamily: "Plus Jakarta Sans !important",
    color: "#000000",
    textAlign: "center",
    lineHeight:"24px"
  },

  "& .renderedSelectedData":{
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '8px',
  },
  "& .formFieldBox": {
    width: "100%",
    marginBottom:"34px",
    "& .selectField":{
      "& .MuiSelect-select.Mui-disabled":{
        color:"rgba(0, 0, 0, 0.38)"
      }
    }
  },
  "& .selectInput": {
    justifyContent: "center",
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    margin: "5px 0px",
    height: "50px",
    display: "flex",
    padding: "10px 10px",
    boxSizing: "border-box",
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&::before": {
        content: "none"
      },
      "&:focus": {
        outline: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red"
    },
    "&:hover:not(.Mui-disabled):after": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:before": {
      borderBottom: "0px solid red"
    },
    "&.MuiInput-underline:after": {
      borderBottom: "0px solid red"
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent"
    }
  },
  "& .inputFieldBox": {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginBottom:"10px"
  },
  "& .inputField": {
    width: "100%",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    height: "50px",
    margin: "5px 0px",
    display: "flex",
    justifyContent: "center",
    padding: "0px 10px",
    boxSizing: "border-box",
    "& .MuiInput-root": {
      "&:focus": {
        outline: "none"
      },
      "&::before": {
        content: "none"
      },
      "&::after": {
        content: "none"
      }
    }
  },
  "& .fieldText": {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    fontFamily: "Plus Jakarta Sans",
    color: "#334155"
  },
  "& .errorText": {
    color: "red"
  },
  "& .starTxt": {
    color: "red",
    fontFamily: "Plus Jakarta Sans !important"
  },
  "& .buttonBox": {
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    paddingTop:"20px"
  },
  "& .buttonBoxBranch": {
    display:"flex",
    justifyContent: "center",
    paddingTop: "34px",
  }, 
  "& .cancelBtn": {
    color: "rgba(52, 211, 153, 1)",
    background: "rgba(214, 246, 235, 1)",
    borderRadius: "8px",
    width: "45%",
    border: "1px solid rgba(52, 211, 153, 1)",
    textTransform: "none",
    "&.MuiButton-root:hover": {
      background: "rgba(214, 246, 235, 1)",
    }
  },
  "& .addBtn": {
    background: "rgba(52, 211, 153, 1)",
    borderRadius: "8px",
    width: "45%",
    textTransform: "none",
    color: "white",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    "&.MuiButton-root:hover": {
      background: "rgba(52, 211, 153, 1)"
    }
  },
  "& .MuiInput-underline-779:before": {
    borderBottom: "none"
  },
  "& .intakePlaceholder":{
    borderBottom: "none",
    "& :before":{
      borderBottom:"none"
    },
    "& :after":{
      borderBottom:"none"
    },
    "& :hover": {
      borderBottom: "none"
    },
    "& :hover:not(.Mui-disabled):before": {
      borderBottom: "0px solid red !important",
    },
    "& .MuiSelect-icon": {
      color: "#0E172A",
      right: "5px"
    },
    "& .MuiInput-root": {
      "&::before": {
        content: "none"
      },
      "&:focus": {
        outline: "none"
      },
      "&::after": {
        content: "none"
      }
    },
    "& .MuiInput-underline:before": {
      borderBottom: "0px solid red !important"
    },
  "& .MuiInput-underline:after": {
    borderBottom: "0px solid red !important"
  },
  "& :hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red !important"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  },
  },
  "& .intakePlaceholder input::placeholder": {
    color: "rgba(0, 0, 0, 0.87)",
    opacity:1,
    "& .MuiAutocomplete-inputRoot": {
      "& :hover": {
        borderBottom: "none"
      }
  },
  // "& .MuiInput-underline-779:hover:not(.Mui-disabled):before":{
  //   borderBottom:"none"
  // }
  }
});

const BoxPopupClose = styled(Box)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "10px",
  position: "absolute",
  right: "16px",
  top: "16px",
  "@media (min-width: 600px)": {
    right: "14px",
    top: "14px"
  },
  "@media (min-width: 960px)": {
    right: "12px",
    top: "12px"
  },
});

const SelectInput = styled(Select)({
  "@media (max-width: 600px)": {
    fontSize: "15px"
  },
  backgroundColor: "#fff",
  width: "100%",
  border: "1px solid #CBD5E1",
  borderRadius: "8px",
  height: "50px",
  margin: "5px 0px",
  display: "flex",
  justifyContent: "center",
  padding: "0px 10px",
  boxSizing: "border-box",
  "& .MuiSelect-icon": {
    color: "#0E172A",
    right: "5px"
  },
  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&::before": {
    borderBottom: "none"
  },
  "&.MuiInput-underline:before": {
    borderBottom: "0px solid red"
  },
  "&.MuiInput-underline:after": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):before": {
    borderBottom: "0px solid red"
  },
  "&:hover:not(.Mui-disabled):after": {
    borderBottom: "0px solid red"
  },
  "& .MuiSelect-select:focus": {
    backgroundColor: "transparent"
  }
});

const InputFieldMultiline = styled(TextField)({
  width: "100%", border: "1px solid #CBD5E1",
  borderRadius: "8px", minHeight: "145px", margin: "5px 0px",
  padding: "0px 10px", boxSizing: "border-box",
  backgroundColor:"white",
  "& .darkPlaceholder::placeholder": {
    color: "rgba(0, 0, 0, 0.87)",
    opacity: 1
  },

  "& .MuiInput-root": {
    "&:focus": {
      outline: "none"
    },
    "&::before": {
      content: "none"
    },
    "&::after": {
      content: "none"
    }
  },
  "&::after": {
    borderBottom: "none"
  },
  "&::before": {
    borderBottom: "none"
  },
  "& .MuiInput-underline:before": { borderBottom: "0px solid red" },
  "& .MuiInput-underline:after": { borderBottom: "0px solid red" },
  "&:hover:not(.Mui-disabled):before": { borderBottom: "0px solid red" },
  "&:hover:not(.Mui-disabled):after": { borderBottom: "0px solid red" },
  "& .MuiSelect-select:focus": { backgroundColor: "transparent" }
});

const BoxPopUpTitle = styled(Box)({
  display: "flex",
  gap: "12px",
  justifyContent:"center",
  alignContent:"center",
  alignItems:"center"
});

const StyledCheckBox = styled(Checkbox)({
  borderRadius: "6px",
  width: "20px",
  height: "20px",
  "@media (min-width: 600px)": {
    width: "18px",
    height: "18px"
  },
  "@media (min-width: 960px)": {
    width: "16px",
    height: "16px"
  }
});
const StyledChip = styled(Chip)({
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "4px",
  backgroundColor: "white !important",
  "& .MuiChip-label": {
    color: "var(--Neutrals-Cool-gray-600, #475569)",
    fontFamily: "Plus Jakarta Sans",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "22px",
    "@media (min-width: 600px)": {
      fontSize: "13px",
      lineHeight: "20px"
    },
    "@media (min-width: 960px)": {
      fontSize: "12px",
      lineHeight: "18px"
    }
  },
  "& .closeIcon": {
    color: "red"
  }
});

const RenderSuccessBox = styled(Box)({
display:"flex",
gap:"20px",
justifyContent:"center",
alignItems:"center",
overflow: "hidden",
flexDirection:"column",
height:"100%",
backgroundColor:"white",
maxHeight: "300px",
padding: "20px",
borderRadius: "10px",
margin:"auto",
  "& .header": {
    fontWeight: "800",
    fontSize: "25px"
  },
  "& .text": {
    fontWeight: "400",
    fontSize: "15px"
  },
  "& .addBtn": {
    background: "rgba(52, 211, 153, 1)",
    borderRadius: "8px",
    width: "45%",
    textTransform: "none",
    color: "white",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    "&.MuiButton-root:hover": {
      background: "rgba(52, 211, 153, 1)"
    }
  },
})
// Customizable Area End
