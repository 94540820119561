import React, { Component } from 'react'

interface IconPropsType {
    color:string;
}
export default class IconMenu extends Component<IconPropsType> {
      
    render() {
        return (
<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.125 14.5C12.989 14.5 14.5 12.989 14.5 11.125C14.5 9.26104 12.989 7.75 11.125 7.75C9.26104 7.75 7.75 9.26104 7.75 11.125C7.75 12.989 9.26104 14.5 11.125 14.5Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.875 14.5C22.739 14.5 24.25 12.989 24.25 11.125C24.25 9.26104 22.739 7.75 20.875 7.75C19.011 7.75 17.5 9.26104 17.5 11.125C17.5 12.989 19.011 14.5 20.875 14.5Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.125 24.25C12.989 24.25 14.5 22.739 14.5 20.875C14.5 19.011 12.989 17.5 11.125 17.5C9.26104 17.5 7.75 19.011 7.75 20.875C7.75 22.739 9.26104 24.25 11.125 24.25Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.875 24.25C22.739 24.25 24.25 22.739 24.25 20.875C24.25 19.011 22.739 17.5 20.875 17.5C19.011 17.5 17.5 19.011 17.5 20.875C17.5 22.739 19.011 24.25 20.875 24.25Z" stroke="#292D32" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
        )
    }
}



