import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, setStorageData } from "../../../../packages/framework/src/Utilities";
import { toast } from "react-toastify";

interface University {
  id: number;
  university_name: string;
  location: string;
  province: string;
  country: string;
  associated_tags: string;
  offer_tat: string;
  year: number;
  logo: {
    id: number;
    filename: string;
    url: string;
    type: string;
  };
}

interface UserDetails {
  attributes: {
    first_name: string;
    role: string;
    image: {
      url: string;
    };
  };
}
interface UserDetailsResponse {
  data: UserDetails;
  meta: {
    message: string;
  };
  error?: string[];
  success: string[]
}


interface StudentData {
  id: string;
  firstName: string;
  lastName: string;
  image: string;
  Studentid: string;
  passport_number: string
}

interface Session {
  intake_label: string;
  months: string[];
  availability: string;
}

interface ProgramAttributes {
  program_name: string;
  program_level: string;
  duration: string;
  fees: string;
  fees_after_scholarship: string | null;
  cash_deposit: string | null;
  program_link: string;
  discipline: string;
  is_shortlisted: boolean;
  university: University;
  sessions: Session[];
  offer_tat: string;
}

export interface ProgramData {
  id: string;
  type: string;
  attributes: ProgramAttributes;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  userDetails: {
    attributes: {
      first_name: string;
      role: string;
      image: {
        url: string;
      },
    },
  },
  toggleDrawer: boolean;
  catalogueList: any;
  searchTerm: string;
  searchResults: ProgramData[];
  isFilterPopUpOpen:boolean
  countries:string[];
  programLevel:string[];
  discipline:string[];
  selectedCountries:string[];
  selectedProgramLevel:string[];
  selectedDiscipline:string[];
  MangeShortListModel: boolean;
  shortListData: any,
  getshortListData:[],
  selectedPrograms:[],
  studentList:any,
  selectedStudent:any,
  isStudentSelected:boolean,
  
  intakes: string[];
  durations: string[];
  selectedIntakes: string[];
  selectedDurations: string[];
  filteredCount:number,
  courseFinderData: StudentData | null;
  isShortlistData:boolean | null;
  isAskForExpertVisible:boolean

  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  fetchAllCatalogueId: string = "";
  fetchAllStudentsId: string="";
  uploadCourseId: string="";
  GetShortListCallId:string ="";
  fetchAllCountriesAPICallID: string = ""
  fetchAllProgramLevelAPICallID: string = ""
  fetchAllDisciplineAPICallID: string = ""
  fetchAllIntakeAPICallID: string = ""
  fetchAllDuratiionsAPICallID: string = ""
  fetchFilterAPICallID: string = ""
  deleteShortlistId:string = ''
  getProfileAPICallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      catalogueList: [],
      toggleDrawer: false,
      searchTerm: "",
      searchResults: [],
      isFilterPopUpOpen:false,
      countries:[],
      programLevel:[],
      discipline:[],
      selectedCountries:[],
      selectedProgramLevel:[],
      selectedDiscipline:[],
      studentList:[],
      getshortListData:[],
      MangeShortListModel: false,
      shortListData:{
        attributes: '',
        university_name:'',
        program_name:'',
        location:'',
      },
      selectedPrograms: [],
      selectedStudent:[],
      isStudentSelected: true, 
      intakes: [],
      durations: [],
      selectedIntakes: [],
      selectedDurations: [],
      filteredCount:0,
      courseFinderData: null,
      isShortlistData:null,
      isAskForExpertVisible:false,
      userDetails: {
        attributes: {
          first_name: '',
          role: '',
          image: {
            url: '',
          },
        },
      },
          // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const courseFinderDataString = localStorage.getItem("CourseFinderData");
    if (courseFinderDataString) {
      const courseFinderData = JSON.parse(courseFinderDataString);
      this.setState({ courseFinderData });
    }
        await this.getFormApprovalProfile()
        await this.fetchAllCatalogue();
        await this.fetchAllStudents();
        await this.GetSortListData();
        await this.fetchAllCountries();
        await this.fetchAllProgramLevel()
        await this.fetchAllDiscipline()
        await this.fetchAllIntakes()
        await this.fetchAllDurations()
    // Customizable Area End
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    let responseJson;


    const messageId = message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
  
    if (getName(MessageEnum.SessionResponseMessage) === messageId) {
      this.handleSessionResponse(message);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === messageId) {
      if (this.fetchAllCatalogueId === responseData) {
        this.handleCatalogueResponse(message);
      } else if (this.fetchAllStudentsId === responseData) {
        this.handleStudentResponse(message);
      } else if(this.GetShortListCallId === responseData){
        this.handleGetShortlist(message);
      } else if(this.uploadCourseId === responseData){
        this.handleCourseList(message);
      } else if(this.deleteShortlistId === responseData){
        this.handleDeleteCourse(message);
      } else if (this.getProfileAPICallId === responseData){
        this.getProfileFormSuccessCallBack(message)
      }
   
   
    }
this.handleResponse(message)
   
    // Customizable Area End
  }

  // Customizable Area Start

  handleShortlistLocalstore = async()=>{
    const { selectedPrograms, shortListData } = this.state;
      
      // Convert course IDs to a stringified array
      const courseId = selectedPrograms.length > 0 
        ? JSON.stringify(selectedPrograms.map((program: any) => Number(program.id))) // Convert to numbers and then stringify
        : JSON.stringify([Number(shortListData.id)]); // Handle single ID in shortListData as a stringified array

        const storedData = JSON.parse(localStorage.getItem('CourseFinderData') || '{}');
      
        const studentId = storedData?.id;
        const id = storedData?.Studentid; // Get id (270)
      
      const payload = {
        id: id, // Ensure id is a string
        course_id: courseId, // course_id as a stringified array
        student_id: studentId, // Ensure student_id is a string
      };
    
      this.uploadCourseId = await this.apiCall({
        contentType: "application/json",
        method: 'POST',
        endPoint: configJSON.uploadStudent,
        body: payload
      });
  }

  handleSessionResponse(message: Message) {
    const token = message.getData(getName(MessageEnum.SessionResponseToken));
    this.setState({ token });
    this.getListRequest(token);
  }


// Helper function to handle catalogue response
handleCatalogueResponse(message: Message) {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (responseJson && responseJson.data) {
    this.setState({ catalogueList: responseJson.data });
}
}

// Helper function to handle student response
handleStudentResponse(message: Message) {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (responseJson && responseJson.data) {
    this.setState({ studentList: responseJson.data });
  }
}

handleCourseList(message: Message) {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (responseJson && responseJson.message) {
    toast.success("Shortlisted Succesfully");
    this.GetSortListData();
    this.fetchAllCatalogue();
  }
  else {
    toast.error(responseJson.error)
  }
}
handleDeleteCourse(message: Message) {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  if (responseJson && responseJson.message) {
    toast.success("Deleted Succesfully")
    this.GetSortListData();
    this.fetchAllCatalogue();
  }
  else {
    toast.error(responseJson.error)
  }
}

handleGetShortlist(message: Message) {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

  if (responseJson && responseJson.data) {
    this.setState({ getshortListData: responseJson.data });
  }
}

getProfileFormSuccessCallBack = (message: Message) => {
  const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
  this.setState({ userDetails: responseJson.data });
};

getFormApprovalProfile = async () => {
  let userDetails = await getStorageData("userDetails")
  this.getProfileAPICallId = await this.apiCall({
    contentType: "application/json",
    method: 'GET',
    endPoint: configJSON.getProfileEndPoint+userDetails
  });
}

  setResponseData = (message: Message, callBackFun: any) => {
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (responseJson && responseJson.data) {
      callBackFun(responseJson)
    }
  }

  handleResponse = (message: Message) => {
    const handleAPIResponse = <K extends keyof S>(
      apiCallID: string | null,
      stateKey: K,
      message: Message
    ) => {
      if (
        apiCallID != null &&
        apiCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
      ) {
        this.setResponseData(message, (responseData: any) => {
          this.setState({ [stateKey]: responseData.data } as Pick<S, K>);
        });
      }
    };
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      handleAPIResponse(this.fetchAllCountriesAPICallID, "countries", message);
      handleAPIResponse(this.fetchAllProgramLevelAPICallID, "programLevel", message);
      handleAPIResponse(this.fetchAllDisciplineAPICallID, "discipline", message);
      handleAPIResponse(this.fetchAllIntakeAPICallID, "intakes", message);
      handleAPIResponse(this.fetchAllDuratiionsAPICallID, "durations", message);
      handleAPIResponse(this.fetchFilterAPICallID, "catalogueList", message)
    }
  };

  apiCall = async (apiData: any) => {
    const { contentType, method, endPoint, body, type } = apiData;

    let token = await getStorageData("token");

    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body && type !== "formData"
      ? requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body)
        )
      : requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          body
        );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return requestMessage.messageId;
  };

  handleToggle = () => {
    this.setState({
      toggleDrawer: !this.state.toggleDrawer
    });
  };

  onHomeClick = (pageName: string) => {
    setStorageData("LandingPageActive", pageName)
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "LandingPage"
    );
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  navigationToAnyPage = (pageName: string) => {
    const navigation = new Message(getName(MessageEnum.NavigationMessage));
    navigation.addData(getName(MessageEnum.NavigationTargetMessage), pageName);
    navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigation);
  };

  fetchAllCatalogue = async () => {
    this.fetchAllCatalogueId = await this.apiCall({
      contentType: "application/json",
      method: "GET",
      endPoint: configJSON.catalogueList,
    });
  };

  fetchAllStudents = async () => {
    this.fetchAllStudentsId = await this.apiCall({
            contentType: "application/json",
            method: 'GET',
            endPoint: configJSON.selectStudent
    });
    };

    GetSortListData = async () => {
      // Retrieve and parse data from localStorage
      const storedData = JSON.parse(localStorage.getItem('CourseFinderData') || '{}');
      
      const studentId = storedData?.id;
      const id = storedData?.Studentid; // Get id (270)
    
        this.GetShortListCallId = await this.apiCall({
          contentType: "application/json",
          method: "GET",
          endPoint: `${configJSON.getSHortlistData}?student_id=${studentId}&id=${id}`
        });
    };
    

    handleChoose = async () => {
      if (this.state.selectedStudent.length === 0) {
        this.setState({ isStudentSelected: false });
      } else {
      this.uploadShortlist();
      }
    };

    uploadShortlist = async () => {
      const { selectedStudent, selectedPrograms, shortListData } = this.state;
      
      // Convert course IDs to a stringified array
      const courseId = selectedPrograms.length > 0 
        ? JSON.stringify(selectedPrograms.map((program: any) => Number(program.id))) // Convert to numbers and then stringify
        : JSON.stringify([Number(shortListData.id)]); // Handle single ID in shortListData as a stringified array
      
      const payload = {
        id: String(selectedStudent?.id || ""), // Ensure id is a string
        course_id: courseId, // course_id as a stringified array
        student_id: String(selectedStudent?.student_id || "") // Ensure student_id is a string
      };
    
      this.uploadCourseId = await this.apiCall({
        contentType: "application/json",
        method: 'POST',
        endPoint: configJSON.uploadStudent,
        body: payload
      });
    };

  shortListModal = (programs: any[]) => {
    this.setState({
      MangeShortListModel: true,
      shortListData: programs,
    });
  };

  handleCheckboxChange = (program:any) => {
    this.setState((prevState:any) => {
      const isSelected = prevState.selectedPrograms.find(
        (p:any) => p.id === program.id
      );
      if (isSelected) {
        return {
          selectedPrograms: prevState.selectedPrograms.filter(
            (p:any) => p.id !== program.id
          ),
        };
      } else {
        return {
          selectedPrograms: [...prevState.selectedPrograms, program],
        };
      }
    });
  };

  OpenCloseMangeStaff = () =>{
    this.setState({
        MangeShortListModel: !this.state.MangeShortListModel,
    })
}


  handleShortlistAll = () => {
    const { selectedPrograms } = this.state;
  
    if (selectedPrograms.length > 0) {
      // Trigger modal for the selected programs
      this.shortListModal(selectedPrograms);
    }
  };
  handleStudentChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedStudentId = event.target.value as string;
    const selectedStudent = this.state.studentList.find(
      (student: any) => student.id === selectedStudentId
    );
    
    this.setState({ selectedStudent ,  isStudentSelected: true });
  };

  handleSearchTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { catalogueList } = this.state;
    const { value } = event.target;
    this.setState({ searchTerm: event.target.value });
    const searchResults = catalogueList.filter((item: ProgramData) => {
      const programName = item.attributes.program_name.toLowerCase();
      const universityName =
        item.attributes.university.university_name.toLowerCase();
      const location = item.attributes.university.location.toLowerCase();
      const programLevel = item.attributes.program_level.toLowerCase();
      const discipline = item.attributes.discipline.toLowerCase();
      const country = item.attributes.university.country.toLowerCase();

      return (
        programName.includes(value.toLowerCase()) ||
        universityName.includes(value.toLowerCase()) ||
        location.includes(value.toLowerCase()) ||
        programLevel.includes(value.toLowerCase()) ||
        discipline.includes(value.toLowerCase()) ||
        country.includes(value.toLowerCase())
      );
    });
    this.setState({ searchResults: searchResults });
  };

  handleFilterPopUp = () => {
    this.setState({ isFilterPopUpOpen: !this.state.isFilterPopUpOpen });

    if(this.state.filteredCount === 0) {
      this.handleReset(); 
    }
  }

  fetchAllCountries = async () => {
    this.fetchAllCountriesAPICallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.fetchAllCountries
    });
  };

  fetchAllProgramLevel = async () => {
    this.fetchAllProgramLevelAPICallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.fetchAllProgramLevel
    });
  };

  fetchAllDiscipline = async () => {
    this.fetchAllDisciplineAPICallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.fetchAllDiscipline
    });
  };

  handleCountriesChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedCountries:newValue})
  };

  handleRemoveCountries = (item:string) => {
    this.setState((prevState) => ({
      selectedCountries: prevState.selectedCountries.filter(
        (option) => option !== item
      ),
    }));
  }

  handleProgramLevelChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedProgramLevel:newValue})
  };

  handleRemoveProgram = (item:string) => {
    this.setState((prevState) => ({
      selectedProgramLevel: prevState.selectedProgramLevel.filter(
        (option) => option !== item
      ),
    }));
  }

  handleDisciplineChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedDiscipline:newValue})
  };

  handleRemoveDiscipline = (item:string) => {
    this.setState((prevState) => ({
      selectedDiscipline: prevState.selectedDiscipline.filter(
        (option) => option !== item
      ),
    }));
  }

  fetchAllIntakes = async () => {
    this.fetchAllIntakeAPICallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.fetchAllIntake
    });
  };
  
  fetchAllDurations = async () => {
    this.fetchAllDuratiionsAPICallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.fetchAllDurations
    });
  };
  handleDeleteUser = () => {
    localStorage.removeItem('CourseFinderData');
    this.setState({ courseFinderData: null });
    this.setState({ getshortListData: [] });
    this.fetchAllCatalogue();
  }

  handleDeleteSHortlist= async(program:any)=>{
    const payload = {
      id: this.state.courseFinderData?.Studentid,
      course_id: JSON.stringify(Number(program.id)) , // Convert program.id to a string and wrap in array
      student_id: this.state.courseFinderData?.id, // Ensure student_id is a string
    };
    
    this.deleteShortlistId = await this.apiCall({
      contentType: "application/json",
      method: 'Delete',
      endPoint: configJSON.deleteSHortlistData,
      body: payload,
    });
  }
  
  handleIntakesChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedIntakes:newValue})
  };
  
  handleRemoveIntakes = (item:string) => {
    this.setState((prevState) => ({
      selectedIntakes: prevState.selectedIntakes.filter(
        (option) => option !== item
      ),
    }));
  }
  
  addShortlistBtn = async (program: any) => {
    const payload = {
      id: this.state.courseFinderData?.Studentid,
      course_id: JSON.stringify([Number(program.id)]) , // Convert program.id to a string and wrap in array
      student_id: this.state.courseFinderData?.id, // Ensure student_id is a string
    };
    
    this.uploadCourseId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: configJSON.uploadStudent,
      body: payload,
    });
  }
  

  handleDurationsChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    this.setState({selectedDurations:newValue})
  };
  
  handleRemoveDurations = (item:string) => {
    this.setState((prevState) => ({
      selectedDurations: prevState.selectedDurations.filter(
        (option) => option !== item
      ),
    }));
  }

  handleReset = () => {
    this.setState({
      selectedIntakes: [],
      selectedDurations: [],
      selectedCountries:[],
      selectedProgramLevel:[],
      selectedDiscipline:[],
      filteredCount: 0,
    }, () => {
      this.fetchAllCatalogue()
    })
  }

  collectFilterData = async () => {
    const { selectedCountries, selectedDiscipline,selectedDurations, selectedIntakes, selectedProgramLevel } = this.state;
  
    const filters = {
      selectedCountries, selectedDiscipline,selectedDurations, selectedIntakes, selectedProgramLevel
    };
  
    const activeFilters = Object.values(filters).filter(val => val && val.length !== 0).length;
  
    this.setState({ filteredCount: activeFilters }, () => {
      this.handleFilterPopUp();
    });

    this.callFilterApi()
  };

  callFilterApi = async () => {
    const { selectedCountries, selectedDiscipline,selectedDurations, selectedIntakes, selectedProgramLevel } = this.state;

    const filterBody = {
      country: selectedCountries,
      program_level: selectedProgramLevel,
      discipline: selectedDiscipline,
      intake: selectedIntakes,
      duration: selectedDurations
    }

    this.fetchFilterAPICallID = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.fetchAllFilter,
      body: filterBody
    });
  }

  navigationToInstitute = (instituteID: number, instituteName: string) => {
    this.props.navigation.navigate('Institution', { instituteID: instituteID, instituteName:instituteName }) 
  }
  handleShowAskForExpert = () => {
    this.setState({ isAskForExpertVisible: true});
  };

  handleHideAskForExpert = () => {
    this.setState({isAskForExpertVisible: false});
  };
  // Customizable Area End
}
